<template>
    <div>
        <a @click="ExportFile" class="btn ripple btn-block text-white text-icon" style="background-color: #bfa826" ref="descargarApp">Descargar Atenea <i class="fab fa-android"></i></a>
    </div>
</template>

<script>
export default {
    name: "downloadapp",
    data(){
        return{

        }
    },
    mounted() {

    },
    methods:{
        ExportFile(){let $this = this;
            this.$swal({
                title: "",
                text: "Preparando apk ATENEA México, por favor espere.",
                imageUrl: "/theme/img/loads/lavaload.gif",
                showConfirmButton: false
            });
            return axios({
                method: 'post',
                url: '/api/Download/appMovil',
                responseType: 'arraybuffer',
            }).then(r => {
                    let fileName = r.headers["content-disposition"].split("filename=")[1];
                    let blob = new Blob([r.data], { type: 'application/apk' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    $this.$swal.close()
                    $this.$toastMessage('success','App app descargada!',3000,'center');
                },
                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
            );
        },
    }
}
</script>

<style scoped>

</style>
