<script>
export default {
    name: "AdministracionPop",
    data(){
        return {
            showModal: false,
            imgvisible: false,
            loadPdf: false,
            dataPop: {
                nombre: "",
                comentario: "",
                fechaInicio: "",
                fechaFin: "",
                cantidad: 0,
                imagen: ""
            },
            tableDataPOP: [],
            columnsPOP: ["Id",'Nombre',"FechaInicio","FechaTermino","Comentario","Image","Cantidad","Pdf"],
            optionsPOP: {
                async requestFunction(data) {
                    //console.log(data)
                    //data.typeUsers = 1
                    //console.log(data)
                    return await axios.post('/api/POP/getData', {
                        params: data,
                    })
                        .then(function (data) {
                            //console.log(data)
                            return data
                        })
                        .catch(function (e) {
                            console.log(e)
                        })
                },
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Nombre','Comentario','Cantidad'],
                sortable: ['FechaInicio','FechaTermino'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    FechaInicio: 'Fecha Inicio',
                    FechaTermino: 'Fecha Termino',
                    Image: 'Imagen',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Pdf: 'text-center',
                    Fecha: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    methods:{
        handleImage(e){let $this = this;
            const selectedImage = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function(event) {
                $this.dataPop.imagen = event.target.result;
            };
            reader.onerror = function(error) {
                alert(error);
            };
            reader.readAsDataURL(selectedImage);
        },
        async savePOP(){
            if (this.dataPop.fechaInicio === ''){
                this.$toastMessage('error','Ingrese fecha inicio de vigencia',4000,'center');
            }
            else if (this.dataPop.fechaInicio === ''){
                this.$toastMessage('error','Ingrese fecha del termino de la vigencia',4000,'center');
            }
            else if (this.dataPop.nombre.length <= 8){
                this.$toastMessage('error','Ingrese un nombre mas descriptivo para el material pop',4000,'center');
            }
            else if (this.dataPop.cantidad === 0){
                this.$toastMessage('error','Ingrese una cantidad mayor a 0',4000,'center');
            }
            else if (this.dataPop.imagen === ''){
                this.$toastMessage('error','Seleccione una imagen del material POP',4000,'center');
            }
            else{
                var fechauno = new Date(this.dataPop.fechaInicio);
                var fechados = new Date(this.dataPop.fechaFin);
                if (fechauno.getTime() >= fechados.getTime()){
                    this.$toastMessage('error','La fecha inicio, no puede ser mayor a la fecha de termino',4000,'center');
                }else{
                    let a = await this.$peticionesApi('/api/POP/savePop', this.dataPop );
                    if (a != null){
                        if (a.Success){
                            this.$toastMessage("success", a.Mensaje,4000,'center');
                            this.dataPop.nombre = ""
                            this.dataPop.cantidad = 0
                            this.dataPop.imagen = ""
                            this.dataPop.fechaFin = ""
                            this.dataPop.fechaInicio = ""
                            this.dataPop.comentario = ""
                            this.$refs.tablematerialpop.getData();
                        }
                        else{
                            this.$toastMessage("error", a.Mensaje,4000,'center');
                        }
                    }
                }
            }
        },
        async createPdf(cve, tipo){ let $this = this
            if (tipo){
                this.loadPdf = true
                await axios({
                    method: 'post',
                    url: '/api/POP/downloadFile',
                    responseType: 'arraybuffer',
                    data: { id: cve } }).then(r => {
                        let blob = new Blob([r.data], { type: 'application/pdf' })
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = 'Etiquetas.pdf'
                        link.click()
                        $this.loadPdf = false
                    },
                    error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        $this.loadPdf = false
                    }
                );
            }
            else{
                this.loadPdf = true;
                await axios.post('/api/POP/createEtiquetas',{ id: cve })
                    .then(function (r){
                        if (r.data.Success){
                            $this.$refs.tablematerialpop.getData();
                        }
                        else{

                        }
                        $this.loadPdf = false;
                    }).catch((error)=>{
                        $this.$toastMessage("error", error.toString(),4000,'center');
                        $this.loadPdf = false;
                    });
            }
        }
    }
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4 text-center">
                <button class="btn btn-success modal-effect" type="button"
                        data-effect="effect-just-me" data-toggle="modal"
                        href="#myModal8">
                    <i class="fas fa-cogs"></i><b>Crear Nuevo Registro</b>
                </button>
            </div>
            <div class="col-md-4"></div>
        </div>
        <br/>
        <div class="row">
            <div class="col-md-12">
                <v-server-table :data="tableDataPOP" :columns="columnsPOP" :options="optionsPOP" class="table-responsive" ref="tablematerialpop">
                    <div slot="Image" slot-scope="props">
                        <img class="img-fluid img-thumbnail" :src="'/storage/pop/'+props.row.Image" alt="imagen producto" />
                    </div>
                    <div slot="Pdf" slot-scope="props">
                        <div v-if="loadPdf">
                            <img alt="loading img" class="img-fluid img-thumbnail" src="/theme/img/loads/loadfile.gif" width="90px"/>
                        </div>
                        <div v-else>
                            <span v-if="props.row.Pdf === 'no generado'" class="badge badge-danger ripple" @click="createPdf(props.row.Id,false)">GENERAR ETIQUETAS</span>
                            <span v-else class="badge badge-success ripple" @click="createPdf(props.row.Id,true)">Descargar Etiquetas</span>
                        </div>
                    </div>
                </v-server-table>
            </div>
        </div>
        <div class="modal fade effect-slide-in-bottom modal-backdrop" tabindex="-1" id="myModal8" data-backdrop="false" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content animated">
                    <div class="modal-header">
                        <i class="fa modal-icon fas fa-truck-loading" style="color: darkblue"></i>
                        <h4 class="modal-title"><span>Crear Material POP</span></h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-8">
                                <label for="input-placeholder" class="form-label fw-medium">Nombre Material POP</label>
                                <input type="text" class="form-control tx-uppercase" id="input-placeholder" placeholder="Ingresa el nombre" maxlength="250" v-model="dataPop.nombre">
                                <small class="text-info">Longitud: {{dataPop.nombre.length}}</small>
                            </div>
                            <div class="col-md-4">
                                <label for="input-number" class="form-label fw-medium">Cantidad</label>
                                <input min="0" step="1" type="number" class="form-control" id="input-number" placeholder="Ingresa la cantidad" v-model="dataPop.cantidad">
                            </div>
                            <div class="col-md-6">
                                <label for="input-comentario" class="form-label fw-medium">Comentario</label>
                                <textarea rows="4" placeholder="Ingresa el comentario" class="form-control tx-uppercase" v-model="dataPop.comentario" maxlength="250"></textarea>
                                <small class="text-info">Longitud: {{dataPop.comentario.length}}</small>
                            </div>
                            <div class="col-md-6 text-center">
                                <label>Fecha de inicio:</label><br>
                                <date-picker v-model="dataPop.fechaInicio" valueType="YYYY-MM-DD"></date-picker>
                                <br><label>Fecha de termino:</label><br>
                                <date-picker v-model="dataPop.fechaFin" valueType="YYYY-MM-DD"></date-picker>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-8">
                                        <label>Imagen material POP:</label>
                                        <input type="file" name="myImage" accept="image/*"  class="form-control" @change="handleImage"/>
                                    </div>
                                    <div class="col-md-4">
                                        <i class="fa" :class="{'fa-eye':imgvisible,'fa-eye-slash':!imgvisible}" style="color: darkred;cursor:pointer" @click="imgvisible = !imgvisible">
                                            {{ imgvisible ? 'Imagen lista':'Ocultar imagen'}}
                                        </i><br />
                                        <img :src="dataPop.imagen" class="img img-responsive img-fluid" alt="ImagenEjemplo" v-show="imgvisible"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button  type="button" class="btn btn-success" data-dismiss="modal" @click="savePOP">Guardar Registro</button>
                        <button  type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
