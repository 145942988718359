<script>
import selectAnio from './../../../components/generales/anios.vue'
export default {
    name: "dataReport",
    components: {
        selectAnio
    },
    data(){
        return {
            anio: null,
            dataAI: [],
            dataIM: []
        }
    },
    methods: {
        setAnio(){
            this.anio = this.$refs.selectAnio.selectedAnios
            console.log(this.$refs.selectAnio.selectedAnios)
        },
        formatNumber(value) {
            console.log(typeof value)
            if (typeof value !== 'number') return value;
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);
        },
        async getReport(tipo){
            let $this = this
            if(tipo === 1){
                $this.dataAI = []
            }
            else{
                $this.dataIM = []
            }
            this.anio = this.$refs.selectAnio.selectedAnios
            if (this.$refs.selectAnio.selectedAnios === null){
                this.$toastMessage('error', 'Selecciona el año', 4000, 'center')
                return
            }
            await axios.post(`/api/Dashboard/dataAnualExhibiciones`, {
                anio: this.$refs.selectAnio.selectedAnios,
                tipo: tipo,
            })
                .then(({ data }) => {
                    if(tipo === 1){
                        $this.dataAI = data.data
                    }
                    else{
                        $this.dataIM = data.data
                    }
                })
                .catch(error => {
                    $this.$toastMessage('error', 'Error al generar el reporte: ' + error, 4000, 'center')
                })
        },
        async generateReport(){
            await this.getReport(1)
            await this.getReport(2)
        },
        calculatePercentageDifference(anterior, actual) {
            if (anterior === 0 || anterior === null) return actual === null || actual === 0 ? 0 : 100; // Evitar divisiones por 0
            const difference = ((actual - anterior) / anterior) * 100;
            return difference.toFixed(2); // Limitar a 2 decimales
        }

    },
    async mounted() {
        await this.generateReport()
    }
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col-md-2">
                <select-anio ref="selectAnio" @cambioAnio="setAnio"></select-anio>
                <br>
                <button class="btn btn-primary btn-block" @click="generateReport">Generar Reporte</button>
            </div>
            <div class="col-md-10">

                <div class="row">
                    <div class="col-md-4">
                        <table class="table table-striped table-bordered table-responsive" v-if="dataAI.length > 0">

                            <thead>
                                <tr>
                                    <td colspan="10" class="text-center tx-bold">ALTO IMPACTO</td>
                                </tr>
                                <tr>
                                    <td rowspan="2">Categoria</td>
                                    <td colspan="3" class="text-center tx-bold"> AUTOSERVICIO</td>
                                    <td colspan="3" class="text-center tx-bold"> MAYOREO</td>
                                    <td colspan="3" class="text-center tx-bold"> FARMACIAS</td>
                                </tr>
                                <tr>
                                    <td>{{anio.value - 1 }}</td>
                                    <td>{{anio.value}}</td>
                                    <td>Diferencia</td>
                                    <td>{{anio.value - 1 }}</td>
                                    <td>{{anio.value}}</td>
                                    <td>Diferencia</td>
                                    <td>{{anio.value - 1 }}</td>
                                    <td>{{anio.value}}</td>
                                    <td>Diferencia</td>
                                </tr>
                            </thead>
                            <tbody>
                            <template v-for="item in dataAI">
                                <tr>
                                    <td
                                        class="tx-bold"
                                        :class="{'bg-info text-white': item.header}"
                                    >
                                        {{ item.Categoria }}
                                    </td>
                                    <template v-for="item2 in item.Data">
                                        <td class="tx-bold text-center">{{ formatNumber(item2.Anterior) }}</td>
                                        <td class="tx-bold text-center">{{ formatNumber(item2.Actual) }}</td>
                                        <td
                                            class="tx-bold text-center"
                                            :class="{
                                                'bg-success text-white': calculatePercentageDifference(item2.Anterior, item2.Actual) > 0,
                                                'bg-danger text-white': calculatePercentageDifference(item2.Anterior, item2.Actual) < 0
                                            }">
                                            {{ calculatePercentageDifference(item2.Anterior, item2.Actual) }}%
                                        </td>

                                    </template>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-4">
                        <table class="table table-striped table-bordered table-responsive" v-if="dataIM.length > 0">

                            <thead>
                            <tr>
                                <td colspan="10" class="text-center tx-bold">IMPULSO</td>
                            </tr>
                            <tr>
                                <td rowspan="2">Categoria</td>
                                <td colspan="3" class="text-center tx-bold"> AUTOSERVICIO</td>
                                <td colspan="3" class="text-center tx-bold"> MAYOREO</td>
                                <td colspan="3" class="text-center tx-bold"> FARMACIAS</td>
                            </tr>
                            <tr>
                                <td>{{anio.value - 1 }}</td>
                                <td>{{anio.value}}</td>
                                <td>Diferencia</td>
                                <td>{{anio.value - 1 }}</td>
                                <td>{{anio.value}}</td>
                                <td>Diferencia</td>
                                <td>{{anio.value - 1 }}</td>
                                <td>{{anio.value}}</td>
                                <td>Diferencia</td>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="item in dataIM">
                                <tr>
                                    <td
                                        class="tx-bold"
                                        :class="{'bg-info text-white': item.header}"
                                    >
                                        {{ item.Categoria }}
                                    </td>
                                    <template v-for="item2 in item.Data">
                                        <td class="tx-bold text-center">{{ formatNumber(item2.Anterior) }}</td>
                                        <td class="tx-bold text-center">{{ formatNumber(item2.Actual) }}</td>
                                        <td
                                            class="tx-bold text-center"
                                            :class="{
                                                'bg-success text-white': calculatePercentageDifference(item2.Anterior, item2.Actual) > 0,
                                                'bg-danger text-white': calculatePercentageDifference(item2.Anterior, item2.Actual) < 0
                                            }">
                                            {{ calculatePercentageDifference(item2.Anterior, item2.Actual) }}%
                                        </td>

                                    </template>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
