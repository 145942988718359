<template>
    <div>
        <div class="row">
            <div class="col-md-3 text-left">
                <h4>
                    <span class="badge badge-success" style="cursor: pointer" @click="applyFilter(2,'')">
                        VIGENTES: <b>{{ vigentes }}</b>
                    </span>
                </h4>
                <ol>
                    <li v-for="item in responsables" style="cursor: pointer" @click="applyFilter(2,item.Nombre+'')">
                        {{item.Nombre}} - <b>{{ item.Total }}</b>
                    </li>
                </ol>
            </div>
            <div class="col-md-3 text-left">
                <h4>
                    <span class="badge badge-info" style="cursor: pointer" @click="applyFilter(3,'')">
                        SEGMENTACIÓN: <b>{{ vigentes }}</b>
                    </span>
                </h4>
                <ol>
                    <li v-for="item in tiposEncuestas" style="cursor: pointer" @click="applyFilter(3,item.Segmentacion+'')"><b>{{item.Segmentacion}}</b> - {{ item.Total }}</li>
                </ol>
            </div>
            <div class="col-md-3 text-left">
                <h4><span class="badge badge-primary" style="cursor: pointer" @click="applyFilter(4,'')">TIPO MEDICIÓN: <b> {{ vigentes }} </b></span></h4>
                <ol>
                    <li v-for="item in tipoMedicion" style="cursor: pointer" @click="applyFilter(4,item.Seguimiento+'')"><b> {{item.Seguimiento}} </b> - {{ item.Total }} </li>
                </ol>
            </div>

            <div class="col-md-3" >
                <div v-if="loadfileEncuestas">
                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                    <p>Preparando archivo, espere...</p>
                </div>
                <button v-if="!loadfileEncuestas"  class="btn btn-success" type="button" @click="ExportFileEncuestas"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-4">
            </div>
            <div class="col-md-4 text-center">
                <div v-if="showLoad" class="text-center">
                    <img :src="'/theme/img/loads/spiralload.gif'" alt="carga reporte" height="40">
                    <p>Consultando base de datos, por favor espere...</p>
                </div>
            </div>
            <div class="col-md-4">

            </div>
        </div><br>
        <div class="row">
            <div class="col-md-12">
                <v-client-table :data="tableData" :columns="columns" :options="options" class="table-responsive">
                    <div slot="Responsable" slot-scope="props">
                        <p class="text-justify font-weight-bold">{{props.row.Responsable}}</p>
                    </div>
                    <div slot="Estatus" slot-scope="props">
                        <span class="badge badge-danger" v-if="props.row.Estatus === 'Vencida'">Vencida</span>
                        <span class="badge badge-success" v-else-if="props.row.Estatus === 'Vigente'">Vigente</span>
                        <span class="badge badge-warning" v-else>Pendiente</span>

                    </div>
                    <div slot="Medicion" slot-scope="props">

                    </div>
                    <div slot="Nomentprd" slot-scope="props">
                        <p class="text-justify">{{props.row.Nomentprd}}</p>
                        <div class="text-center" v-if="props.row.Estatus === 'Vigente'">
                            <i class="fa fa-edit" data-effect="effect-just-me" data-toggle="modal"
                               href="#myModal8" style="cursor: pointer" @click="editNombre(props.row)"></i>
                        </div>
                    </div>
                    <div slot="Actividad" slot-scope="props">
                        <span class="badge badge-primary" data-effect="effect-just-me" data-toggle="modal"
                              href="#myModal5" style="cursor: pointer"
                              @click="namep=props.row.Nomentprd;act=props.row.Actividad;idx=props.row.Id">
                            {{props.row.Actividad}}</span>
                    </div>
                    <div slot="Medicion" slot-scope="props">
                        <span class="badge badge-success" v-if="props.row.Medicion === 'EJECUCIÓN'" data-effect="effect-just-me" data-toggle="modal"
                              href="#myModal6" style="cursor: pointer" @click="namep=props.row.Nomentprd;medicion=props.row.Medicion;idx=props.row.Id">
                            EJECUCIÓN
                        </span>
                        <span class="badge badge-warning" v-else data-effect="effect-just-me" data-toggle="modal"
                              href="#myModal6" style="cursor: pointer" @click="namep=props.row.Nomentprd;medicion=props.row.Medicion;idx=props.row.Id">
                            SEGUIMIENTO
                        </span>
                    </div>
                    <div slot="Imagen" slot-scope="props">
                        <i class="fa fa-file-image-o" style="color: #0a6aa1;cursor:pointer" v-if="props.row.Imagen!==''" @click="showModalImg(props.row.Imagen)"
                           data-effect="effect-just-me" data-toggle="modal" href="#myModal4"> Ver</i>
                        <i class="fa fa-times" style="color: darkred" v-else></i>
                    </div>
                    <div slot="Areas" slot-scope="props">
                        <span v-html="props.row.Areas"></span>
                    </div>
                    <div slot="Agencias" slot-scope="props">
                        <span v-html="props.row.Agencias"></span>
                    </div>
                    <div slot="Estados" slot-scope="props">
                        <span v-html="props.row.Estados"></span>
                    </div>
                    <div slot="Canales" slot-scope="props">
                        <span v-html="props.row.Canales"></span>
                    </div>
                    <div slot="Cadenas" slot-scope="props">
                        <span v-html="props.row.Cadenas"></span>
                    </div>
                    <div slot="Formatos" slot-scope="props">
                        <span v-html="props.row.Formatos"></span>
                    </div>
                    <div slot="Motivos" slot-scope="props">
                        <div v-if="props.row.EditMotivos === 0">
                            <b> - </b>
                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <i class="fa fa-edit" style="color: darkblue; cursor: pointer;" v-if="!props.row.EditMotivos" @click="editSelecMotivos(props.row.Id,true)"></i> &nbsp;&nbsp;
                                    <i class="fa fa-save" style="color: darkgreen; cursor: pointer;" v-if="props.row.EditMotivos" @click="saveMotivos(props.row.Id)"></i> &nbsp;&nbsp;
                                    <i class="fa fa-times" style="color: darkred; cursor: pointer;" v-if="props.row.EditMotivos" @click="editSelecMotivos(props.row.Id,false)"></i>
                                </div>
                            </div>

                            <v-select multiple v-model="selected" :options="props.row.Motivos" v-if="props.row.EditMotivos"/>
                            <br>
                            <ol v-if="!props.row.EditMotivos">
                                <li v-for="item in props.row.Motivos">
                                    <span class="text-info">{{item}}</span>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div slot="Departamento" slot-scope="props">
                        <span v-html="props.row.Departamento"></span>
                    </div>
                    <div class="text-center" slot="TotalTiendas" slot-scope="props">
                        <span class="badge badge-info">{{props.row.TotalTiendas}}</span>
                    </div>
                    <div slot="Categoria" slot-scope="props">
                        <span v-html="props.row.Categoria"></span>
                    </div>
                    <div slot="NoEjecucion" slot-scope="props">
                        <span v-html="props.row.NoEjecucion" class="text-danger"></span>
                        <ol>
                            <li v-for="item in props.row.MotivosNE">
                                <span class="text-info">{{item.Falla}}:</span> <b class="text-danger">{{item.Evaluacion}}</b>
                            </li>
                        </ol>
                    </div>
                    <div slot="Productos" slot-scope="props">
                        <small v-html="props.row.Productos"></small>
                    </div>
                    <div slot="Fchter" slot-scope="props">
                        <small v-html="props.row.Fchter"></small>
                        <input type="date" v-model="props.row.Fchter" v-if="validaUsuario" :ref="'newTer'+props.row.Id" @change="saveDate(props.row.Id,1)">
                    </div>
                    <div slot="Fchini" slot-scope="props">
                        <small v-html="props.row.Fchini"></small>
                        <input type="date" v-model="props.row.Fchini" v-if="validaUsuario" :ref="'newIni'+props.row.Id" @change="saveDate(props.row.Id,0)">
                    </div>
                </v-client-table>
            </div>
        </div>
        <div class="modal inmodal" :class="{'show':showModal}" id="myModal4" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content animated fadeIn">
                    <div class="modal-header">
                        <i class="fa modal-icon" :class="iconModal" style="color: darkred"></i>
                        <h4 class="modal-title"><span>{{titleModal}}</span></h4>
                    </div>
                    <div class="modal-body">
                        <div v-show="rechazar">
                            <label><b style="color: darkred">Ingrese motivo de rechazo:</b></label>
                            <textarea rows="4" maxlength="200" class="form-control" v-model="motivoRechazo" placeholder="Motivo de rechazo"></textarea>
                        </div>
                        <div v-show="!rechazar">
                            <img :src="imgSrc" alt="Image" class="img img-responsive">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-show="rechazar" type="button" class="btn btn-success" data-dismiss="modal" @click="closeModal">Guardar Cambios</button>
                        <button v-show="rechazar" type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalF">Cancelar</button>
                        <button v-show="!rechazar" type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalImg">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal inmodal" :class="{'show':showModal}" id="myModal5" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content animated fadeIn">
                    <div class="modal-header">
                        <i class="fa modal-icon fa-book-open" style="color: darkred"></i>
                        <h4 class="modal-title"><span>EDITAR ACTIVIDAD ENCUESTA</span></h4>
                    </div>
                    <div class="modal-body">
                        <div v-if="tableData.length>0">
                            <p>{{namep}}</p>
                            <span class="badge badge-primary">{{act}}</span>
                        </div><br>
                        <label>Seleccione la nueva actividad:</label>
                        <v-select :multiple="false" :options="optionsActividades" :placeholder="'Selecciona Actividad'" v-model="actividad"></v-select>
                    </div>
                    <div class="modal-footer">
                        <button  type="button" class="btn btn-success" data-dismiss="modal" @click="saveActividad">Guardar Cambios</button>
                        <button  type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>

                    </div>
                </div>
            </div>
        </div>
        <div class="modal inmodal" :class="{'show':showModal}" id="myModal6" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content animated fadeIn">
                    <div class="modal-header">
                        <i class="fa modal-icon fa-book-open" style="color: darkred"></i>
                        <h4 class="modal-title"><span>EDITAR TIPO MEDICIÓN ENCUESTA</span></h4>
                    </div>
                    <div class="modal-body">
                        <div v-if="tableData.length>0">
                            <p>{{namep}}</p>
                            <span class="badge badge-secondary">{{medicion}}</span>
                        </div><br>
                        <label>Seleccione el tipo de medición:</label>
                        <select-seguimiento :placeholderm="'TIPO MEDICIÓN'" :todaOpciones="false" :disablE="false"
                                            ref="tipoSeguimientoSelect" v-on:cambioTipoEncuestaSeguimiento=""></select-seguimiento>
                    </div>
                    <div class="modal-footer">
                        <button  type="button" class="btn btn-success" data-dismiss="modal" @click="saveMedicion">Guardar Cambios</button>
                        <button  type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>

                    </div>
                </div>
            </div>
        </div>
        <div class="modal inmodal" :class="{'show':showModal}" id="myModal8" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content animated fadeIn">
                    <div class="modal-header">
                        <i class="fa modal-icon fa-book-open" style="color: darkred"></i>
                        <h4 class="modal-title"><span>EDITAR NOMBRE ENCUESTA</span></h4>
                    </div>
                    <div class="modal-body">
                        <div class="text-justify">
                            <b>{{ nameEdit }}</b>
                            <textarea rows="4" class="text ui-widget-content form-control" v-model="nombreEdit.Nomentprd" maxlenght="255" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button  type="button" class="btn btn-success" data-dismiss="modal" @click="saveNameEdit">Guardar Cambios</button>
                        <button  type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import VueTables from "vue-tables-2";
import selectSeguimiento from "../../components/filters/tipoEncuestaSeguimiento.vue";

export default {
    name: "verEncuesstas",
    components:{
        'select-seguimiento': selectSeguimiento,
    },
    props:{
        idUsers:{type:Number,required:true,default:0}
    },
    computed:{
        validaUsuario(){
            return this.users.includes(this.idUsers);
        }
    },
    data() {
        return{
            selected:[],
            responsables: [],
            actividades: [],
            tipoMedicion: [],
            tiposEncuestas: [],
            nameEdit: '',
            nombreEdit: {},
            editSelected: false,
            vigentes: 0,
            loadfileEncuestas: false,
            users: [32182,2,30001,34437],
            showLoad: false,
            newFIni: null,
            newFTer: null,
            Id: 0,
            idx: 0,
            imgSrc: '',
            titleModal: '',
            iconModal: '',
            namep: '',
            act: '',
            medicion: '',
            motivoRechazo: '',
            rechazar: true,
            showModal: false,
            columns: ['Id','Estatus','Responsable' ,'Fchini','Fchter','Nomentprd','Medicion','Tipo','Evaluacion','TotalTiendas','NoEjecucion','Motivos','Actividad','Tpo','Imagen','CreatedAt','Areas','Agencias','Estados','Canales','Cadenas','Formatos','Departamento','Categoria'],
            tableData: [],
            actividad: null,
            optionsActividades: [],
            optionsTipoMedicion: [],
            options: {
                customFilters: [{
                        name: 'Estatus',
                        callback: function(row, query) {
                            return row.Estatus === query
                        }
                    }, {
                        name: 'Responsable',
                        callback: function(row, query) {
                            if (query === ''){
                                return row.Responsable !== query
                            }
                            else{
                                if (row.Responsable.toUpperCase().includes(query.toUpperCase())){
                                    return row.Responsable
                                }
                            }

                        }
                    }, {
                        name: 'Tipo',
                        callback: function(row, query) {
                            if (query === ''){
                                return row.Tipo !== query
                            }
                            else{
                                return row.Tipo === query
                            }

                        }
                    }, {
                        name: 'Medicion',
                        callback: function(row, query) {
                            if (query === ''){
                                return row.Medicion !== query
                            }
                            else{
                                return row.Medicion === query
                            }

                        }
                    }
                ],
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:false },
                perPage:15,
                perPageValues: [15,25,45,50],
                filterable: ['Id','Estatus','Responsable','Medicion','Nomentprd','Fchini','Fchter','Actividad','Tpo','Tipo'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                sortable: ['Id','Responsable','Evaluacion','TotalTiendas','Estatus','Medicion'],
                //orderBy: { column: 'Estatus',ascending:false },
                listColumns: {
                    Estatus: [{
                        id: 'Vigente',
                        text: 'Vigente'
                    }, {
                        id: 'Vencida',
                        text: 'Vencida'
                    }, {
                        id: 'Pendiente',
                        text: 'Pendiente'
                    }
                    ],
                },
                columnsDropdown: true,
                visibleColumns:['Id','Estatus','Responsable' ,'Fchini','Fchter','Nomentprd','Medicion','TotalTiendas','Tipo','Evaluacion','NoEjecucion','Actividad','Tpo','Imagen','CreatedAt','Areas','Agencias','Departamento','Categoria'],
                headings: {
                    Id: '#',
                    Nomentprd: '   Encuesta   ',
                    Medicion: 'Tipo Medición',
                    Evaluacion: 'Evaluación',
                    TotalTiendas: 'T Tiendas',
                    NoEjecucion: 'No Ejecucion',
                    Fchini: 'Fecha Inicio',
                    Fchter: 'Fecha Termino',
                    Tpo: 'Tipo',
                    Tipo: 'Segmentación',
                    CreatedAt: 'Creada el',
                },
                columnsClasses: {
                    Id: 'text-center font-weight-bold',
                    Nomentprd: 'text-justify',
                    Respuesta: 'text-justify',
                    Fchini: 'text-center',
                    Medicion: 'text-center',
                    NoEjecucion: 'text-center font-weight-bold',
                    Evaluacion: 'text-center text-info font-weight-bold',
                    Fchter: 'text-center',
                    Promocion: 'text-center',
                    Tpo: 'text-center',
                    Tipo: 'text-center font-weight-bold',
                    Imagen: 'text-center',
                    CreatedAt: 'text-center',
                    Areas: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    mounted () {
        this.GetData(1);
        this.getActividades();
        this.getMedicion();
    },
    methods:{
        applyFilter(tipo,letter) {
            const Event = VueTables.Event
            if (tipo === 1){
                Event.$emit('vue-tables.filter::Estatus', letter);
            } else if (tipo === 2){
                Event.$emit('vue-tables.filter::Responsable', letter);
                this.tiposEncuestas.forEach((value,key)=>{
                    if (letter !== ''){
                        let real = 0;
                        value.Nombre.forEach((v,k)=>{if (v === letter) real++})
                        value.Total = real
                    }else{
                        value.Total = value.TotalReal
                    }
                })
            } else if (tipo === 4){
                Event.$emit('vue-tables.filter::Medicion', letter);
                this.tipoMedicion.forEach((value,key)=>{
                    if (letter !== ''){
                        let real = 0;
                        value.Nombre.forEach((v,k)=>{if (v === letter) real++})
                        value.Total = real
                    }else{
                        value.Total = value.TotalReal
                    }
                })
            }
            else{
                Event.$emit('vue-tables.filter::Tipo', letter);
                this.responsables.forEach((value,key)=>{
                    if (letter !== ''){
                        let real = 0;
                        value.Segmentacion.forEach((v,k)=>{if (v === letter) real++})
                        value.Total = real
                    }else{
                        value.Total = value.TotalReal
                    }
                })
            }
        },
        editNombre(row){
            this.nombreEdit = row
            this.nameEdit = row.Nomentprd
        },
        saveMotivos(cve){
            if (this.selected.length === 0){
                this.$toastMessage('error','Error: por favor selecciona un motivo de rechazo para poder guardar los cambios!',5000,'center');
            }
            else{
                let idx = this.findIdx(cve); let $this = this;
                axios
                    .post('/api/Encuestas/saveMotivos',{motivos: $this.selected, encuesta: cve})
                    .then(function (r){
                        $this.tableData[idx].Motivos = $this.selected;
                        $this.selected = [];
                        $this.editSelected = false;
                        $this.tableData[idx].EditMotivos = false;
                        $this.$toastMessage('success',r.data.Mensaje,5000,'center');
                    });

            }
        },
        editSelecMotivos(cve,type){
            let idx = this.findIdx(cve);
            if (!type){
                this.editSelected = type;
                this.tableData[idx].EditMotivos = type;
                return;
            }
            if (this.editSelected){
                this.$toastMessage('error','Error: edición en curso, por favor finalice la acción en curso!',5000,'center');
            }
            else{
                this.editSelected = true;
                this.tableData[idx].EditMotivos = type;
            }
        },
        ExportFileEncuestas(){let $this = this;
            this.loadfileEncuestas = true;
            axios({
                method: 'post',
                url: '/api/Encuestas/exportLayoutEvaluacion',
                responseType: 'arraybuffer',
                data: {data: this.tableData}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'EncuestasEvaluacion.xlsx'
                    link.click()
                    $this.loadfileEncuestas=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfileEncuestas=false;
                }
            );
        },
        saveDate(cve,type){ let $this = this;
            let inxx = this.findIdx(cve)
            if (type === 0){
                axios.post('/api/Encuestas/PeticionesNuevas',{tipo:7,metodo:type,cve:cve,date:this.$refs['newIni'+cve].value})
                    .then(response => {
                        $this.$toastMessage("success",'Fecha inicial actualizada!',3000,'center');
                        $this.tableData[inxx].Fchini = $this.$refs['newIni'+cve].value;
                    });
            }else{
                axios.post('/api/Encuestas/PeticionesNuevas',{tipo:7,metodo:type,cve:cve,date:this.$refs['newTer'+cve].value})
                    .then(response => {
                        $this.$toastMessage("success",'Fecha termino actualizada!',3000,'center');
                        $this.tableData[inxx].Fchter = $this.$refs['newTer'+cve].value;
                    });
            }
        },
        async saveNameEdit(){ let $this = this;
            let inxx = this.findIdx(this.nombreEdit.Id)
            await axios.post('/api/Encuestas/PeticionesNuevas',
                {
                    tipo: 8,
                    metodo: 0,
                    id: this.nombreEdit.Id,
                    nuevo: this.nombreEdit.Nomentprd
                })
                .then(response => {
                    $this.$toastMessage("success",'Nombre actualizado!',3000,'center')
                    $this.tableData[inxx].Nomentprd = this.nombreEdit.Nomentprd
                });
        },
        findIdx(cve){
            var a = 0;
            for (var z = 0; z < this.tableData.length;z++)
            {
                if (this.tableData[z].Id === cve){
                    a = z;
                }
            }
            return a;
        },
        async saveActividad(){ let $this = this;
            if(this.actividad != null){
                let inxx = this.findIdx(this.idx)
                await axios.post('/api/Encuestas/Peticiones',{combo:41,act:this.actividad.value,id:this.idx})
                    .then(response => ($this.tableData[inxx].Actividad = $this.actividad.label));
                this.GetData(1);
            }else{
                this.$toastMessage("error",'Seleccione la nueva ACTIVIDAD por favor!',3000,'center');
            }

        },
        async saveMedicion(){ let $this = this;
            if(this.$refs.tipoSeguimientoSelect.selectedTipoSeguimiento.value != null){
                let inxx = this.findIdx(this.idx)
                await axios.post('/api/Encuestas/Peticiones',{
                    combo:44,
                    seguimiento: this.$refs.tipoSeguimientoSelect.selectedTipoSeguimiento.value,
                    id:this.idx
                })
                    .then(response => ($this.tableData[inxx].Medicion = $this.$refs.tipoSeguimientoSelect.selectedTipoSeguimiento.label));
                this.GetData(1);
            }else{
                this.$toastMessage("error",'Seleccione la nueva ACTIVIDAD por favor!',3000,'center');
            }

        },
        async getActividades(){
            this.optionsActividades = [];
            await axios.post('/api/Encuestas/Peticiones',{combo:38})
                .then(response => (this.optionsActividades = response.data));
        },
        async getMedicion(){
            this.optionsTipoMedicion = [];
            await axios.post('/api/Encuestas/Peticiones',{combo:43})
                .then(response => (this.optionsTipoMedicion = response.data));
        },
        TDate(dateU) {
            var UserDate = dateU+' 23:59:59';
            var ToDate = new Date();

            if (new Date(UserDate).getTime() <= ToDate.getTime()) {
                //alert("The Date must be Bigger or Equal to today date");
                return true;
            }
            return false;
        },
        GetData(estatus){let $this = this;
            this.tableData = []
            this.showLoad = true
            this.responsables = []
            axios
                .post('/api/Encuestas/Peticiones',{combo:40,estatus:estatus})
                .then(function (r){
                    $this.tableData = r.data.Data
                    $this.vigentes = r.data.Vigentes
                    $this.responsables = r.data.Responsables
                    $this.actividades = r.data.Actividades
                    $this.tiposEncuestas = r.data.Segmentacion
                    $this.tipoMedicion = r.data.tipoMedicion
                    $this.showLoad = false
                    $this.applyFilter(1,'Vigente')
                });
        },
        showModalImg(src){
            this.titleModal='Imagen Prioridad';
            this.iconModal='fa-file-image-o';
            this.rechazar=false;
            this.imgSrc = src;
            this.showModal = true;
        },
        closeModal(){
            if (this.motivoRechazo===''){
                $this.$toastMessage("error",'Ingrese un motivo de rechazo por favor!',3000,'center');
            }else{let $this = this;
                axios
                    .post('/api/Encuestas/Peticiones',{combo:17,estatus:3,motivo:this.motivoRechazo,Id:this.Id})
                    .then(function (r){
                        $this.$toastMessage("success",r.data.Mensaje,3000,'center');
                        var idx = $this.getId(1);
                        $this.tableData[idx].Estatus = 3;
                        axios
                            .post('/api/Encuestas/Peticiones',{combo:22,Id:$this.Id})/// envio de email a los usuarios!
                            .then(function (r){
                                $this.$swal.fire(
                                    'Exito!',
                                    'Correo enviado, espera la notificación de modificación.',
                                    'success'
                                );
                            });
                    });
                this.showModal = false;
                this.motivoRechazo = '';

            }
        },
        closeModalImg(){
            this.showModal = false;
        },
        Aprobar(clave){let $this = this;
            this.Id = clave;
            axios
                .post('/api/Encuestas/Peticiones',{combo:20,estatus:2,motivo:this.motivoRechazo,Id:this.Id,update:0})
                .then(function (r){
                    $this.$toastMessage("success",r.data.Mensaje,3000,'center');
                    var idx = $this.getId(this.Id);
                    $this.tableData[idx].Estatus = 2;

                    axios.post('/api/Encuestas/Peticiones',{combo:23,Id:$this.Id})/// envio de email a los usuarios!
                        .then(function (r){
                            $this.$swal.fire(
                                'Exito!',
                                'Correo enviado para notificar la aprobación.',
                                'success'
                            );
                        });
                });

        },
        AprobarUpdate(clave){let $this = this;
            this.Id = clave;
            axios
                .post('/api/Encuestas/Peticiones',{combo:20,estatus:2,motivo:this.motivoRechazo,Id:this.Id,update:1})
                .then(function (r){
                    $this.$toastMessage("success",r.data.Mensaje,3000,'center');
                    var idx = $this.getId(this.Id);
                    $this.tableData[idx].Estatus = 2;

                    axios.post('/api/Encuestas/Peticiones',{combo:23,Id:$this.Id})/// envio de email a los usuarios!
                        .then(function (r){
                            $this.$swal.fire(
                                'Exito!',
                                'Correo enviado para notificar la aprobación.',
                                'success'
                            );
                        });
                });

        },
        Rechazar(clave){
            this.rechazar=true;
            this.Id = clave;
            this.titleModal='Rechazar Encuesta';
            this.iconModal='fa-times';
            this.showModal=true;
        },
        getId(clave){let $this = this;
            var idn = 0;
            var a = 0;
            for(a;a<$this.tableData.length;a++){
                if ($this.tableData[a].Id === clave){
                    idn = a;
                }
            }
            return idn;
        },
        closeModalF(){
            this.showModal=false;
        }

    }
}
</script>

<style scoped>

</style>
