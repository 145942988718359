<script>
import dataReport from './components/dataReport.vue'
export default {
    name: "lideresReporte",
    components: {
        dataReport
    }
}
</script>

<template>
    <div>
        <data-report></data-report>
    </div>
</template>

<style scoped>

</style>
