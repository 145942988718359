<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h5 class="text-center">{{nameEnct}}</h5>
                <h5 class="text-center">{{vigencia}}</h5>
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">

                        <v-select :multiple="false" :options="optionsSemanas" placeholder="Selecciona la semana"
                                  v-model="selectedSemana" @input="setCambio"></v-select>
                    </div>
                    <div class="col-md-4"></div>
                </div>
                <div class="justify-content-center text-center mt-3 d-flex">
                    <a href="#" class="btn ripple btn-info btn-icon mr-3" @click="CambiarPagina()">
                        <i class="fas fa-arrow-left"></i>
                    </a>
                </div>
                <div v-if="loadingGetData">
                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                    <p>Preparando reporte, espere...</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-center tx-bold">
                    DETALLE REGIÓN
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered mg-b-0 table-striped">
                        <thead>
                        <tr>
                            <th>REGIÓN</th>
                            <th>UNIVERSO</th>
                            <th>EJECUTADO</th>
                            <th>% EJECUTADO</th>
                        </tr>
                        </thead>
                        <tbody v-if="dataRegion.length>0">
                            <tr v-for="items in dataRegion">
                                <th scope="row">{{items.Region}}</th>
                                <td>{{items.Total}}</td>
                                <td>{{items.Avance}}</td>
                                <td>{{items.Porcentaje}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br>
                <div class="text-center tx-bold">
                    DETALLE ÁREA
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered mg-b-0 table-striped">
                        <thead>
                        <tr>
                            <th>ÁREA</th>
                            <th>UNIVERSO</th>
                            <th>EJECUTADO</th>
                            <th>% EJECUTADO</th>
                        </tr>
                        </thead>
                        <tbody v-if="dataArea.length>0">
                        <tr v-for="items in dataArea">
                            <th scope="row">{{items.Area}}</th>
                            <td>{{items.Total}}</td>
                            <td>{{items.Avance}}</td>
                            <td>{{items.Porcentaje}}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <br>
                <div class="text-center tx-bold">
                    DETALLE CANAL
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered mg-b-0 table-striped">
                        <thead>
                        <tr>
                            <th>CANAL</th>
                            <th>UNIVERSO</th>
                            <th>EJECUTADO</th>
                            <th>% EJECUTADO</th>
                        </tr>
                        </thead>
                        <tbody v-if="dataCanal.length>0">
                        <tr v-for="items in dataCanal">
                            <th scope="row">{{items.Canal}}</th>
                            <td>{{items.Total}}</td>
                            <td>{{items.Avance}}</td>
                            <td>{{items.Porcentaje}}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <br>

                <div class="text-center tx-bold">
                    DETALLE LIDER
                    <button class="btn ripple btn-info btn-icon text-center" @click="showLider = !showLider"><i class="fas fa-eye"></i></button>
                </div>
                <div class="table-responsive" v-show="showLider">
                    <table class="table table-bordered mg-b-0 table-striped">
                        <thead>
                        <tr>
                            <th>LIDER</th>
                            <th>UNIVERSO</th>
                            <th>EJECUTADO</th>
                            <th>% EJECUTADO</th>
                        </tr>
                        </thead>
                        <tbody v-if="dataLider.length>0" v-for="items in dataLider">

                            <tr  v-for="itm in items">
                                <td><b v-html="itm.Lider"></b></td>
                                <td>{{itm.Total}}</td>
                                <td>{{itm.Avance}}</td>
                                <td>{{itm.Porcentaje}}</td>

                            </tr>



                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-center tx-bold">
                    CAUSAS NO EJECUCIÓN
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered mg-b-0 table-striped">
                        <thead>
                        <tr>
                            <th>CAUSA</th>
                            <th>TOTAL</th>
                        </tr>
                        </thead>
                        <tbody v-if="dataNegativas.length>0">
                        <tr v-for="items in dataNegativas">
                            <th scope="row">{{items.name}}</th>
                            <td>{{items.y}}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <br>
                <highcharts :options="chartOptions"></highcharts>
            </div>
            <div class="col-md-2">
                <button class="btn btn-info" type="button" @click="CambiarPaginaGaleria()"><i class="fas fa-images"></i><b> Total Imagenes: {{TotalImagen}}</b></button>
                <br>
                <img alt="SIN IMAGEN DE ENCUESTA" class="img-fluid img-thumbnail" :src="Imagen" v-if="Imagen !== ''">
                <img alt="SIN IMAGEN DE ENCUESTA" class="img-fluid img-thumbnail" src="/theme/img/pngs/1.png" v-else>
                <br>
                <button class="btn btn-success" type="button" @click="ExportFile"><i class="fas fa-file-excel"></i><b> Reporte</b></button>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from "highcharts";

function createChartRespuestas(vm){
    return{
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{point.nombre}: {point.percentage:.2f} % <br><b>{point.y}</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.2f} %<br> <b style="color: darkorange">{point.y}</b>',
                    style: {
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                    }
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        series: [{
            name: 'Respuestas Negativas',
            data: []
        }]
    }
}
export default {
    name: "detalleEncuestas",
    data(){
        return{
            nameEnct:'',
            vigencia:'',
            showLider:false,
            loadingGetData:false,
            dataRegion:[],
            dataArea:[],
            dataCanal:[],
            dataLider:[],
            dataNegativas:[],
            Imagen:'',
            TotalImagen:0,
            chartOptions: createChartRespuestas(this),
            selectedSemana:{label:"GENERALES",value:null,ini:null,selected:false},
            optionsSemanas:[],
            totales:{
                tregion:0,
                tregiontiendas:0,
                tregionporcentaje:0,
            },
            cveSem:0
        }
    },
    mounted() {

    },
    methods:{
        setCambio(){
            this.getData(this.cveSem);
        },
        ExportFile(){let $this = this;
            axios({
                method: 'post',
                url: '/api/Dashboard/exportLayoutToExcel',
                responseType: 'arraybuffer',
                data: {
                    Region: this.dataRegion,
                    Area: this.dataArea,
                    Negativas: this.dataNegativas,
                    Canal: this.dataCanal,
                    Lider: this.dataLider,
                    Vigencia: this.vigencia,
                    Pregunta: this.nameEnct,
                }}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'NIVELDEEJECUCIONDEACTIVIDADES.xlsx'
                    link.click()

                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                }
            );
        },
        CambiarPagina(){
            this.$emit('cambioPagina');
        },
        CambiarPaginaGaleria(){
            this.$emit('cambioGaleria');
        },
        async getWeeks(cve){ let $this = this;
            await axios.post('/api/AdminCatalogos/dataEncuestasRegionGetWeek',{cve:cve,semanaFin:this.selectedSemana})
                .then(r => {
                        $this.loadingGetData = false;
                        if (r.data.Success){
                            $this.optionsSemanas = r.data.Semanas;
                            $this.chartOptions.series[0].data = r.data.Negativas;
                            $this.optionsSemanas.forEach((value,key)=>{
                                if (value.selected){
                                    $this.selectedSemana={label:value.label,value:value.value,ini:value.ini,selected:true}
                                }
                            });
                        }
                    },
                    error => {
                        $this.$toastMessage('error', 'Error: ' + error + ' Por favor envia este error al área de soporte!', 9000, 'center');
                        $this.loadingGetData = false;
                    }
                );
            this.getData(cve);
        },
        getData(cve){let $this = this;
            this.cveSem = cve;
            this.dataRegion = [];
            this.dataArea = [];
            this.dataNegativas = [];
            this.dataCanal = [];
            this.dataLider = [];
            this.loadingGetData = true;
            this.chartOptions.series[0].data = [];
            axios.post('/api/AdminCatalogos/dataEncuestasRegion',{cve:cve,semanaFin:this.selectedSemana})
                .then(r => {
                        $this.loadingGetData = false;
                        if (r.data.Success){
                          $this.dataRegion = r.data.DataRegion;
                          $this.dataArea = r.data.DataAreas;
                          $this.dataNegativas = r.data.Tabla;
                          $this.dataCanal = r.data.DataCanal;
                          $this.dataLider = r.data.DataLideres;
                          $this.Imagen = r.data.Imagen;
                          $this.TotalImagen = r.data.TotalImagenes;
                          $this.chartOptions.series[0].data = r.data.Negativas;
                        }
                    },
                    error => {
                        $this.$toastMessage('error', 'Error: ' + error + ' Por favor envia este error al área de soporte!', 9000, 'center');
                        $this.loadingGetData = false;
                    }
                );
        }
    }
}
</script>

<style scoped>

</style>
