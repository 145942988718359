<template>
    <div>
        <!-- Modal effects -->
        <div class="modal" :id="'modalShowData'+tipoGaleria" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Detalle Imagen <b>{{tipoGaleria}}</b> <i class="fas fa-file-image"></i></h6>
                        <button ref="closeModal" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                       <div class="row" v-if="dataGaleria.length>0">
                           <div class="col-md-6">
                               <div class="text-center">
                                   zoomed: {{ zoomed }}
                                   <button @click="$refs.zoomer.zoomIn()">+</button>
                                   <button @click="$refs.zoomer.zoomOut()">-</button>
                               </div>
                               <v-zoomer ref="zoomer"
                                         style=" border: solid 1px silver;width: 350px; height: 500px;"
                                         :max-scale="10"
                                         :zooming-elastic="false"
                                         :zoomed.sync="zoomed">
                                   <img
                                       :src="'/storage'+dataGaleria[idxGlobalSelected].Arch"
                                       style="object-fit: contain; width: 100%; height: 100%;"
                                       alt="imgZoom"
                                   >
                               </v-zoomer>
                           </div>
                           <div class="col-md-6">
                               <label class="tx-bold">KCMID</label>
                               <p class="tx-bold">{{dataGaleria[idxGlobalSelected].Kcmid}}</p>
                               <label class="tx-bold">Código</label>
                               <p class="tx-bold">{{dataGaleria[idxGlobalSelected].Cdgentpto}}</p>
                               <label class="tx-bold">Producto</label>
                               <p class="tx-12">{{dataGaleria[idxGlobalSelected].Producto}}</p>
                               <label class="tx-bold">Fecha Registro</label>
                               <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Fchcrt}}</p>
                               <label class="tx-bold">Canal</label>
                               <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Canal}}</p>
                               <label class="tx-bold">Cadena</label>
                               <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Cadena}}</p>
                               <label class="tx-bold">Formato</label>
                               <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Formato}}</p>
                               <label class="tx-bold">Tienda</label>
                               <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Tienda}}</p>
                               <label class="tx-bold">Estado</label>
                               <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Estado}}</p>
                               <label class="tx-bold">Ciudad</label>
                               <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Ciudad}}</p>
                               <label class="tx-bold">Usuario</label>
                               <p class="tx-12 tx-bold text-info" v-html="dataGaleria[idxGlobalSelected].Usuario"></p>
                           </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal effects-->
        <div class="row">
           <div class="col-md-2">
               <h5><b class="text-success">{{tipoGaleria}}</b></h5>
           </div>
           <div class="col-md-2">
               <h5>Total Imagenes:<br>
               <b class="text-success">{{totalImg}}</b>
               </h5>
           </div>
           <div class="col-md-8">
               <button class="btn ripple btn-primary " @click="buttonOnClickReport" ><i class="fas fa-cogs"></i> Generar Reporte</button>
               <button v-show="selectAllBtn && tipoGaleria !== 'KPISAMS'" class="btn ripple btn-success" @click="selectAll(selectAllBtn)"><i class="fe fe-check-square"></i> Marcar Todo</button>
               <button v-show="!selectAllBtn && tipoGaleria !== 'KPISAMS'" class="btn ripple btn-danger " @click="selectAll(selectAllBtn)"><i class="fe fe-check-square"></i> Deseleccionar Todo</button>
               <button v-if="!loadfileZip && tipoGaleria !== 'KPISAMS'" class="btn ripple btn-indigo " @click="ExportFile" ><i class="fe fe-download-cloud"></i> Zip</button>
               <button v-if="!loadFilePptx && tipoGaleria !== 'KPISAMS'" class="btn ripple btn-secondary "  @click="ExportFilePptx(false)"><i class="fas fa-file-powerpoint"></i> PPTX</button>
               <button v-if="!loadFilePptx" class="btn ripple btn-warning "  @click="ExportFilePptx(true)"><i class="fas fa-file-powerpoint"></i> PPTX TODO</button>
           </div>
        </div>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div v-if="loadfileZip || loadFilePptx || loadDataPost">
                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                    <p>Preparando informacion, espere...</p>
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        <br>
        <div v-if="tipoGaleria !== 'KPISAMS'">
            <div id="global" class="row" v-if="dataGaleria.length>0">
                <div  class="col-md-4" v-for="items in dataGaleria">
                    <div class="row" v-if="items.ShowT">
                        <div class="col-md-1">
                            <label class="ckbox" v-if="items.Disponible === 1">
                                <input
                                    type="checkbox"
                                    v-model="items.Checked"
                                    :true-value="true"
                                    :false-value="false"
                                >
                                <span> </span>
                            </label><br>
                        </div>
                        <div class="col-md-6">
                            <img v-show="items.Mostrar === 1" class="img-thumbnail" :src="'/storage'+items.Arch" alt="imagenFaltantes" @load="onImgLoad(items.Id)" @error="setFail(items.Id)"/>
                            <img v-if="items.Mostrar === 0" alt="loading img" class="img-thumbnail" src="/theme/img/loads/lavaload.gif"/>
                            <br />
                            <i class="fas fa-trash" style="cursor: pointer; color: darkred;" @click="deleteImage(items.Id)" v-if="validaUsuarioAdmin && tipoGaleria === 'ENCUESTAS'"></i>
                        </div>
                        <div class="col-md-5">
                            <p class="tx-bold" v-if="items.Kcmid!==''">{{items.Kcmid}}</p>
                            <p class="tx-bold" v-if="items.Pop!==''">{{items.Pop}}</p>
                            <p class="tx-bold" v-if="items.Cdgentpto!==''">{{items.Cdgentpto}}</p>
                            <p class="tx-12" v-if="items.Producto!==''">{{items.Producto}}</p>
                            <p class="tx-12 tx-bold text-danger" v-if="items.Fchcrt!==''">{{items.Fchcrt}}</p>
                            <p class="tx-12 tx-bold text-danger" v-if="items.Fchreg!==''">{{items.Fchreg}}</p>
                            <p class="tx-12 tx-bold text-info" v-if="items.Rol!==''">{{items.Rol}}</p>
                            <p class="tx-12 tx-bold text-danger" v-if="items.Usuario!==''">{{items.Usuario}}</p>
                            <p class="tx-12 tx-bold text-primary" v-if="tipoGaleria === 'PROMOCIONES'"><b>{{items.Promo}}</b></p>
                            <p class="tx-12 tx-bold text-info" v-if="tipoGaleria === 'EXHIBICIONES'"><b>{{items.TipoEx}}</b></p>
                            <p class="tx-12 tx-bold text-info" v-if="tipoGaleria === 'EXHIBICIONES'">Adc: <b>{{items.Exhadc === 0 ? 'NO' : items.Exhadc === 1 ? 'FERIA DE LA FAMILIA KIMBERLY CLARK' : 'SERVICIOS DE EXHIBICIÓN EVENFLO' }}</b></p>
                            <p class="tx-12 tx-bold text-danger" v-if="items.Pregunta!==''">{{items.Pregunta}}</p>
                            <p class="tx-12 tx-bold text-success" v-if="items.Comentario!==''">{{items.Comentario}}</p>
                            <p class="tx-12 tx-bold text-success" v-if="items.Respuesta!=='' && items.Respuesta === '0'">NO</p>
                            <p class="tx-12 tx-bold text-danger" v-if="items.Respuesta!=='' && items.Respuesta === '1'">SI</p>
                            <p class="tx-12 tx-bold" v-if="items.CategoriaReal!==''"><b>{{ items.CategoriaReal }}</b></p>
                            <p class="tx-12 tx-bold text-danger" v-if="tipoGaleria === 'PMAYOREO'">U: {{items.Unidad}} C: {{items.Caja}} M: {{items.Mayoreo}}</p>
                            <br />
                            <button @click="loadData(items.Id)" v-if="items.Disponible === 1" class="btn ripple btn-outline-info" data-effect="effect-super-scaled" data-toggle="modal" :href="'#modalShowData'+tipoGaleria"><i class="fas fa-eye"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="text-center">
                        <button class="btn ripple btn-info btn-with-icon text-center" @click="mostrarVeintemas"><i class="fas fa-question"></i> Mostrar siguientes 20</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div id="global" class="row" v-if="dataGaleria.length>0">
                <div class="col-md-12" v-for="(items, index) in dataGaleria">
                <div class="row" v-if="items.ShowT">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col">
                                        <img v-show="items.MostrarU === 1" class="img-thumbnail" :src="'/storage'+items.Arch" alt="imagenFaltantes" @load="onImgLoad(items.Id,1)" @error="setFail(items.Id,1)"/>
                                        <img v-if="items.MostrarU === 0" alt="loading img" class="img-thumbnail" src="/theme/img/loads/lavaload.gif"/>
                                        <br />
                                        <p class="tx-12 tx-bold">¿ESTE PRODUCTO SE ENCUENTRA EN EL RACK?</p>
                                        <p class="tx-12 tx-bold text-danger" v-if="items.RUno === '0'">NO</p>
                                        <p class="tx-12 tx-bold text-success" v-else>SI</p>
                                        <br />
                                    </div>
                                    <div class="col">
                                        <img v-show="items.MostrarD === 1" class="img-thumbnail" :src="'/storage'+items.Archd" alt="imagenFaltantesd" @load="onImgLoad(items.Id,2)" @error="setFail(items.Id,2)"/>
                                        <img v-if="items.MostrarD === 0" alt="loading img" class="img-thumbnail" src="/theme/img/loads/lavaload.gif"/>
                                        <br />
                                        <p class="tx-12 tx-bold">¿ESTE PRODUCTO TIENE INVENTARIO?</p>
                                        <p class="tx-12 tx-bold text-danger" v-if="items.RDos === '0'">NO</p>
                                        <p class="tx-12 tx-bold text-success" v-else>SI</p>
                                        <br />
                                    </div>
                                    <div class="col">
                                        <img v-show="items.MostrarT === 1" class="img-thumbnail" :src="'/storage'+items.Archt" alt="imagenFaltantesdd" @load="onImgLoad(items.Id,3)" @error="setFail(items.Id,3)"/>
                                        <img v-if="items.MostrarT === 0" alt="loading img" class="img-thumbnail" src="/theme/img/loads/lavaload.gif"/>
                                        <br />
                                        <p class="tx-12 tx-bold">¿EL PRECIO ES CORRECTO?</p>
                                        <p class="tx-12 tx-bold text-danger" v-if="items.RTres === '0'">NO</p>
                                        <p class="tx-12 tx-bold text-success" v-else>SI</p>
                                        <br />
                                    </div>
                                    <div class="col">
                                        <img v-show="items.MostrarC === 1" class="img-thumbnail" :src="'/storage'+items.Archc" alt="imagenFaltantesddd" @load="onImgLoad(items.Id,4)" @error="setFail(items.Id,4)"/>
                                        <img v-if="items.MostrarC === 0" alt="loading img" class="img-thumbnail" src="/theme/img/loads/lavaload.gif"/>
                                        <br />
                                        <p class="tx-12 tx-bold">¿CUENTA CON EXHIBICION ADICIONAL?</p>
                                        <p class="tx-12 tx-bold text-danger" v-if="items.RCuatro === '0'">NO</p>
                                        <p class="tx-12 tx-bold text-success" v-else>SI</p>
                                        <br />

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <p class="tx-bold">{{items.Kcmid}}</p>
                                <p class="tx-bold text-info">{{items.Tienda}}</p>
                                <p class="tx-bold">{{items.Kcmid}}</p>
                                <p class="tx-bold">{{items.Codigo}}</p>
                                <p class="tx-12">{{items.Producto}}</p>
                                <p class="tx-12 tx-bold text-danger">{{items.FechaR}}</p>
                                <p class="tx-12 tx-bold text-info">{{items.Rol}}</p>
                                <p class="tx-12 tx-bold text-danger">{{items.Usuario}}</p>
                                <p class="tx-12 tx-bold">{{items.Categoria}}</p>
                                <p class="tx-12 tx-bold">{{items.Departamento}}</p>
                                <p class="tx-12 tx-bold text-primary">{{items.Estado}}</p>
                                <p class="tx-12 tx-bold text-success">{{items.Ciudad}}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div class="col-md-12">
                    <div class="text-center">
                        <button class="btn ripple btn-info btn-with-icon text-center" @click="mostrarVeintemas">
                            <i class="fas fa-question"></i> Mostrar siguientes 20
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "tipoGaleria",
    props:{
        tipoGaleria: String,
        deleteImg: { type: Boolean, default: false},
        idUserss: { type: Number, required: true, default: 0 }
    },
    data(){
      return{
          loadfileZip:false,
          loadDataPost:false,
          filtros:{},
          dataGaleria:[],
          selectAllBtn:true,
          isLoaded: false,
          totalImg:0,
          imgToZoom:'/theme/img/impg.png',
          idxGlobalSelected:0,
          zoomed:false,
          loadFilePptx:false,
          imagesS:40,
          usersAdmins:[34437,32182,2,30001,34642,34642,34437],
        }
    },
    mounted() {
    },
    computed:{
        validaUsuarioAdmin(){
            return this.usersAdmins.includes(this.idUserss);
        }
    },
    methods:{
         deleteImage(cve){
            this.$swal.fire({
                title: '¿Eliminar Imagen?',
                text: 'Al aceptar, la información se eliminara permanentemente',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    var resp = await this.$peticionesApi('/api/ReporteGrafico/deleteImage', {galeria: 'encuestas', cve: cve});
                    if (resp !== null){
                         this.$swal.fire('Exito!', 'Acción realizada satisfactoriamente.', 'success');
                         this.getData();
                    }
                }
            });
        },
        mostrarVeintemas(){ let $this = this;
            var a = 0;
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                if (a<=$this.imagesS){
                    $this.dataGaleria[z].ShowT = true;
                }
                a++;
            }
            this.imagesS = this.imagesS + 20;
        },
        loadData(cve){
            this.idxGlobalSelected = this.findIdx(cve);
        },
        setFail(cve,V) {
            var idx = this.findIdx(cve);
            if (V === 1){
                this.dataGaleria[idx].MostrarU = 1;
                this.dataGaleria[idx].Arch = '/theme/img/img.png';
                this.dataGaleria[idx].DisponibleU = 0;
            } else if (V === 2){
                this.dataGaleria[idx].MostrarD = 1;
                this.dataGaleria[idx].Archd = '/theme/img/img.png';
                this.dataGaleria[idx].DisponibleD = 0;
            }else if (V === 3){
                this.dataGaleria[idx].MostrarT = 1;
                this.dataGaleria[idx].Archt = '/theme/img/img.png';
                this.dataGaleria[idx].DisponibleT = 0;
            }else if (V === 4){
                this.dataGaleria[idx].MostrarC = 1;
                this.dataGaleria[idx].Archc = '/theme/img/img.png';
                this.dataGaleria[idx].DisponibleC = 0;
            }else {
                this.dataGaleria[idx].Mostrar = 1;
                this.dataGaleria[idx].Archc = '/theme/img/img.png';
                this.dataGaleria[idx].Disponible = 0;
            }
        },
        onImgLoad(cve,V) {
            var idx = this.findIdx(cve);
            if (V === 1){
                this.dataGaleria[idx].MostrarU = 1
            } else if (V === 2){
                this.dataGaleria[idx].MostrarD = 1
            }else if (V === 3){
                this.dataGaleria[idx].MostrarT = 1
            }else if (V === 4){
                this.dataGaleria[idx].MostrarC = 1
            }
            else{
                this.dataGaleria[idx].Mostrar = 1
            }

        },
        buttonOnClickReport(){
            this.$emit("getReportButton");
        },
        async getData() {
             let $this = this;
             this.dataGaleria=[];
             this.loadDataPost=true;
             await axios.post('/api/ReporteGrafico/getDataImg',
                 {
                    data: this.filtros,
                    type: this.tipoGaleria
                })
                .then(r => {
                    $this.dataGaleria = r.data.Data;
                    $this.totalImg = r.data.Total;
                    $this.selectAll(false);
                    $this.selectAllBtn=true;
                    $this.$toastMessage('success',r.data.Mensaje,2000,'top-right');
                    var a = 0;
                    for (var z = 0; z < this.dataGaleria.length;z++)
                    {
                        if (a<=20){
                            $this.dataGaleria[z].ShowT = true;
                        }else{
                            $this.dataGaleria[z].ShowT = false;
                        }
                        a++;
                    }
                    //console.log($this.dataGaleria)
                    $this.loadDataPost=false;

                    },
                    error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center')
                }
             )
        },
        selectAll(type){
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                if (this.dataGaleria[z].ShowT){
                    this.dataGaleria[z].Checked = type;
                }
            }
            this.selectAllBtn = !this.selectAllBtn;
        },
        findIdx(cve){
            var a = 0;
            for (var z = 0; z < this.dataGaleria.length; z++)
            {
                if (this.dataGaleria[z].Id === cve){
                    a = z;
                }
            }
            return a;
        },
        closeModal(){
            this.$refs.closeModal.click();
        },
        ExportFile(){let $this = this;
            let dataImg = []
            dataImg = this.dataGaleria.filter(x => x.Checked === true && x.Disponible === 1)
            if (this.totalImg === 0){
                $this.$toastMessage('error','Error: Por favor genere el reporte, para poder descargar imagenes!',7000,'center');
                return;
            }
            this.loadfileZip = true
            this.$swal({
                title: "Espere",
                text: "Preparando zip " + this.tipoGaleria + " ATENEA México, por favor espere.",
                imageUrl: "/theme/img/loads/lavaload.gif",
                showConfirmButton: false
            });
            return axios({
                method: 'post',
                url: '/api/Download/Zip',
                responseType: 'arraybuffer',
                data: {
                    data: dataImg,
                    galeria: this.tipoGaleria
                }
            }).then(r => {
                    let fileName = r.headers["content-disposition"].split("filename=")[1];
                    let blob = new Blob([r.data], { type: 'application/zip' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    $this.$swal.close();
                    $this.loadfileZip = false;
                    $this.$toastMessage('success','Zip descargado!',3000,'center');
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfileZip = false;
                }
            );
        },
        ExportFilePptx(all){
             let $this = this
            let dataImg = []
            if (this.totalImg === 0){
                $this.$toastMessage('error','Error: Por favor genere el reporte, para poder descargar imagenes!',7000,'center');
                return;
            }
             if (all) {
                 dataImg = this.dataGaleria.slice(0, 1500)
                 if (this.totalImg > 1500){
                     $this.$toastMessage('error','Error: Por favor elija menos fotos, es decir, disminuya con ul filtro adicional, el limite es de 1500 fotos!',7000,'center');
                     return;
                 }
             }
             else{
                 dataImg = this.dataGaleria.filter(x => x.Checked === true && x.Disponible === 1)
             }
            if (dataImg.length === 0){
                $this.$toastMessage('error','Error: Seleccione por favor las fotos a descargar!',7000,'center');
                return;
            }
            this.loadFilePptx = true;
            this.$swal({
                title: "Espere",
                text: "Preparando presentación " + this.tipoGaleria + " ATENEA México, por favor espere.",
                imageUrl: "/theme/img/loads/lavaload.gif",
                showConfirmButton: false
            });

            return axios({
                method: 'post',
                url: '/api/Download/Pptx',
                responseType: 'arraybuffer',
                data: {
                    data: dataImg,
                    galeria: this.tipoGaleria,
                    all: all
                }
            }).then(r => {
                    let fileName = r.headers["content-disposition"].split("filename=")[1];
                    let blob = new Blob([r.data], { type: 'application/pptx' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    $this.$swal.close();
                    $this.loadFilePptx = false;
                    $this.$toastMessage('success','Presentación descargada!',3000,'center');
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadFilePptx = false;
                }
            );
        },
    }
}
</script>

<style scoped>
#global {
    height: 75%;
    width: 100%;
    border: 1px solid #ddd;
    overflow-y: scroll;
}
</style>
