<template>
    <div>
        <vue-tabs active-tab-color="#c3a900"
                  active-text-color="white"
                  type="pills"
                  :start-index="1">

            <v-tab title="Crear Encuesta">
                <br>
                <tabscrear-encuestas></tabscrear-encuestas>
            </v-tab>

            <v-tab title="Importar Layout">
                <br>
                <tabsload-encuestas></tabsload-encuestas>
            </v-tab>

            <v-tab title="Crear Concurso">
                <br>
                <tabscrear-concurso></tabscrear-concurso>
            </v-tab>

        </vue-tabs>
    </div>
</template>

<script>
import crearEncuesta from '../pasos/crearEncuesta'
import loadEncuestas from '../pasos/loadEncuestas'
import loadConcurso from '../Concursos/crearConcurso'
export default {
    name: "encuestasTabs",
    components:{
        "tabscrear-encuestas":crearEncuesta,
        "tabsload-encuestas":loadEncuestas,
        "tabscrear-concurso":loadConcurso
    }
}
</script>

<style scoped>

</style>
