<template>
    <div>
        <v-select :multiple="true" :options="optionsCanales" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedCanales" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "canal",
    props:{
        placeholderm:String,
        mayo:{default:true}
    },
    data(){
        return{
            selectedCanales:[],
            optionsCanales:[]
        }
    },
    mounted() {
        this.getGestores();
    },
    methods:{
        getGestores(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/canalesSelect')
                .then(function (r){
                    $this.optionsCanales = r.data;
                    if (!$this.mayo){
                       let a =  $this.optionsCanales.findIndex((r) => {
                            return r.label === "MAYOREO"
                        })
                        $this.optionsCanales.splice(a,1);
                    }
                });
        },
        setCambio(){
            this.$emit('cambioCanal');
        }

    }
}
</script>

<style scoped>

</style>
