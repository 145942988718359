<script>
import Highcharts from 'highcharts'
import weekSelectKpi from '../../components/generales/weekSelectKpi.vue'
import chartSemiPie from '../../components/generales/chartSemiPie.vue'

function createChartEstadistica(vm){
    return{
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'Respuestas KPI',
            align: 'center'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<span style="font-size: 1.2em"><b>{point.name}</b>' +
                        '</span><br>' +
                        '<span style="opacity: 0.6">{point.y} ' +
                        '</span>',
                    connectorColor: 'rgba(128,128,128,0.5)'
                }
            }
        },
        series: [{
            name: 'Evaluaciones',
            colorByPoint: true,
            data: [{
                name: '¿ESTE PRODUCTO SE ENCUENTRA EN EL RACK?',
                y: 23.9
            }, {
                name: '¿ESTE PRODUCTO TIENE INVENTARIO?',
                y: 12.6
            }, {
                name: '¿EL PRECIO ES CORRECTO?',
                y: 37.0
            }, {
                name: '¿CUENTA CON EXHIBICION ADICIONAL?',
                y: 26.4
            }]
        }],
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
    }
}

export default {
    name: "dashKpiSams",
    components: {
        'week-select-kpi': weekSelectKpi,
        'chart-semi-pie': chartSemiPie,
    },
    data(){
        return{
            Ttda: 0,
            TtdaEnRuta: 0,
            Ttdavisitadas: 0,
            chartOptions: createChartEstadistica(this),
            tableDataTienda: [],
            tableDataTiendaNo: [],
            columnsDataTienda: ['Tienda','Kcmid','Productos'],
            optionsTiendaSi: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Tienda','Kcmid'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Tienda: 'Tienda',
                    Kcmid: 'Kcmid',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Tienda: 'text-center font-bold text-info',
                    Kcmid: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsDataTiendaNo: ['Tienda','Kcmid'],
            optionsTiendaNo: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Tienda','Kcmid'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Tienda: 'Tienda',
                    Kcmid: 'Kcmid',
                },
                columnsClasses: {
                    Tienda: 'text-center font-bold text-danger',
                    Kcmid: 'text-center text-danger',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            TVu: 0,
            TVd: 0,
            TVt: 0,
            TVc: 0,
            TVci: 0,
            TVs: 0,
        }
    },
    methods: {
        async getReport() {
            let $this = this
            let week = this.$refs.weekSelect.selectedWeeks
            await axios
                .post('/api/WebServices/getTiendasSamsKpi', {
                    fechas: week
                })
                .then(function ({data}){
                    console.log(data)
                    $this.Ttda = data.Total
                    $this.TtdaEnRuta = data.EnRuta
                    $this.Ttdavisitadas = data.Visitadas
                    $this.chartOptions.series[0].data[0].y = data.UnoSi
                    $this.chartOptions.series[0].data[1].y = data.DosSi
                    $this.chartOptions.series[0].data[2].y = data.TresSi
                    $this.chartOptions.series[0].data[3].y = data.CuatroSi
                    $this.tableDataTienda = data.TablaV
                    $this.tableDataTiendaNo = data.TablaN
                    // HIGIENICOS
                    $this.$refs.HigDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Higienicos[0].Tu) === 0 ? 0 : (parseInt(data.HigienicosF[0].Tu) / parseInt(data.Higienicos[0].Tu)) * 100
                    $this.$refs.HigDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Higienicos[0].Tu) === 0 ? 0 :  100 - ((parseInt(data.HigienicosF[0].Tu) / parseInt(data.Higienicos[0].Tu)) * 100)
                    $this.$refs.HigDisponiblidad.chartOptions.title.text = parseInt(data.Higienicos[0].Tu) === 0 ? '0 %' : Math.round(((parseInt(data.HigienicosF[0].Tu) / parseInt(data.Higienicos[0].Tu)) * 100)) + " %"
                    $this.$refs.HigInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Higienicos[0].Td) === 0 ? 0 : (parseInt(data.HigienicosF[0].Td) / parseInt(data.Higienicos[0].Td)) * 100
                    $this.$refs.HigInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Higienicos[0].Td) === 0 ? 0 :  100 - ((parseInt(data.HigienicosF[0].Td) / parseInt(data.Higienicos[0].Td)) * 100)
                    $this.$refs.HigInventarioExh.chartOptions.title.text = parseInt(data.Higienicos[0].Td) === 0 ? '0 %' : Math.round(((parseInt(data.HigienicosF[0].Td) / parseInt(data.Higienicos[0].Td)) * 100)) + " %"
                    $this.$refs.HigPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Higienicos[0].Tt) === 0 ? 0 : (parseInt(data.HigienicosF[0].Tt) / parseInt(data.Higienicos[0].Tt)) * 100
                    $this.$refs.HigPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Higienicos[0].Tt) === 0 ? 0 :  100 - ((parseInt(data.HigienicosF[0].Tt) / parseInt(data.Higienicos[0].Tt)) * 100)
                    $this.$refs.HigPrecio.chartOptions.title.text = parseInt(data.Higienicos[0].Tt) === 0 ? '0 %' : Math.round(((parseInt(data.HigienicosF[0].Tt) / parseInt(data.Higienicos[0].Tt)) * 100)) + " %"
                    $this.$refs.HigExhibiAdc.chartOptions.series[0].data[0][1] = parseInt(data.Higienicos[0].Tc) === 0 ? 0 : (parseInt(data.HigienicosF[0].Tc) / parseInt(data.Higienicos[0].Tc)) * 100
                    $this.$refs.HigExhibiAdc.chartOptions.series[0].data[1][1] = parseInt(data.Higienicos[0].Tc) === 0 ? 0 :  100 - ((parseInt(data.HigienicosF[0].Tc) / parseInt(data.Higienicos[0].Tc)) * 100)
                    $this.$refs.HigExhibiAdc.chartOptions.title.text = parseInt(data.Higienicos[0].Tc) === 0 ? '0 %' : Math.round(((parseInt(data.HigienicosF[0].Tc) / parseInt(data.Higienicos[0].Tc)) * 100)) + " %"
                    // Servilletas
                    $this.$refs.SerDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Servilletas[0].Tu) === 0 ? 0 : (parseInt(data.ServilletasF[0].Tu) / parseInt(data.Servilletas[0].Tu)) * 100
                    $this.$refs.SerDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Servilletas[0].Tu) === 0 ? 0 :  100 - ((parseInt(data.ServilletasF[0].Tu) / parseInt(data.Servilletas[0].Tu)) * 100)
                    $this.$refs.SerDisponiblidad.chartOptions.title.text = parseInt(data.Servilletas[0].Tu) === 0 ? '0 %' : Math.round(((parseInt(data.ServilletasF[0].Tu) / parseInt(data.Servilletas[0].Tu)) * 100)) + " %"
                    $this.$refs.SerInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Servilletas[0].Td) === 0 ? 0 : (parseInt(data.ServilletasF[0].Td) / parseInt(data.Servilletas[0].Td)) * 100
                    $this.$refs.SerInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Servilletas[0].Td) === 0 ? 0 :  100 - ((parseInt(data.ServilletasF[0].Td) / parseInt(data.Servilletas[0].Td)) * 100)
                    $this.$refs.SerInventarioExh.chartOptions.title.text = parseInt(data.Servilletas[0].Td) === 0 ? '0 %' : Math.round(((parseInt(data.ServilletasF[0].Td) / parseInt(data.Servilletas[0].Td)) * 100)) + " %"
                    $this.$refs.SerPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Servilletas[0].Tt) === 0 ? 0 : (parseInt(data.ServilletasF[0].Tt) / parseInt(data.Servilletas[0].Tt)) * 100
                    $this.$refs.SerPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Servilletas[0].Tt) === 0 ? 0 :  100 - ((parseInt(data.ServilletasF[0].Tt) / parseInt(data.Servilletas[0].Tt)) * 100)
                    $this.$refs.SerPrecio.chartOptions.title.text = parseInt(data.Servilletas[0].Tt) === 0 ? '0 %' : Math.round(((parseInt(data.ServilletasF[0].Tt) / parseInt(data.Servilletas[0].Tt)) * 100)) + " %"
                    $this.$refs.SerExhibiAdc.chartOptions.series[0].data[0][1] = parseInt(data.Servilletas[0].Tc) === 0 ? 0 : (parseInt(data.ServilletasF[0].Tc) / parseInt(data.Servilletas[0].Tc)) * 100
                    $this.$refs.SerExhibiAdc.chartOptions.series[0].data[1][1] = parseInt(data.Servilletas[0].Tc) === 0 ? 0 :  100 - ((parseInt(data.ServilletasF[0].Tc) / parseInt(data.Servilletas[0].Tc)) * 100)
                    $this.$refs.SerExhibiAdc.chartOptions.title.text = parseInt(data.Servilletas[0].Tc) === 0 ? '0 %' : Math.round(((parseInt(data.ServilletasF[0].Tc) / parseInt(data.Servilletas[0].Tc)) * 100)) + " %"
                    // ServiToallas
                    $this.$refs.SerTDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.ServilletasT[0].Tu) === 0 ? 0 : (parseInt(data.ServilletasTF[0].Tu) / parseInt(data.ServilletasT[0].Tu)) * 100
                    $this.$refs.SerTDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.ServilletasT[0].Tu) === 0 ? 0 :  100 - ((parseInt(data.ServilletasTF[0].Tu) / parseInt(data.ServilletasT[0].Tu)) * 100)
                    $this.$refs.SerTDisponiblidad.chartOptions.title.text = parseInt(data.ServilletasT[0].Tu) === 0 ? '0 %' : Math.round(((parseInt(data.ServilletasTF[0].Tu) / parseInt(data.ServilletasT[0].Tu)) * 100)) + " %"
                    $this.$refs.SerTInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.ServilletasT[0].Td) === 0 ? 0 : (parseInt(data.ServilletasTF[0].Td) / parseInt(data.ServilletasT[0].Td)) * 100
                    $this.$refs.SerTInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.ServilletasT[0].Td) === 0 ? 0 :  100 - ((parseInt(data.ServilletasTF[0].Td) / parseInt(data.ServilletasT[0].Td)) * 100)
                    $this.$refs.SerTInventarioExh.chartOptions.title.text = parseInt(data.ServilletasT[0].Td) === 0 ? '0 %' : Math.round(((parseInt(data.ServilletasTF[0].Td) / parseInt(data.ServilletasT[0].Td)) * 100)) + " %"
                    $this.$refs.SerTPrecio.chartOptions.series[0].data[0][1] = parseInt(data.ServilletasT[0].Tt) === 0 ? 0 : (parseInt(data.ServilletasTF[0].Tt) / parseInt(data.ServilletasT[0].Tt)) * 100
                    $this.$refs.SerTPrecio.chartOptions.series[0].data[1][1] = parseInt(data.ServilletasT[0].Tt) === 0 ? 0 :  100 - ((parseInt(data.ServilletasTF[0].Tt) / parseInt(data.ServilletasT[0].Tt)) * 100)
                    $this.$refs.SerTPrecio.chartOptions.title.text = parseInt(data.ServilletasT[0].Tt) === 0 ? '0 %' : Math.round(((parseInt(data.ServilletasTF[0].Tt) / parseInt(data.ServilletasT[0].Tt)) * 100)) + " %"
                    $this.$refs.SerTExhibiAdc.chartOptions.series[0].data[0][1] = parseInt(data.ServilletasT[0].Tc) === 0 ? 0 : (parseInt(data.ServilletasTF[0].Tc) / parseInt(data.ServilletasT[0].Tc)) * 100
                    $this.$refs.SerTExhibiAdc.chartOptions.series[0].data[1][1] = parseInt(data.ServilletasT[0].Tc) === 0 ? 0 :  100 - ((parseInt(data.ServilletasTF[0].Tc) / parseInt(data.ServilletasT[0].Tc)) * 100)
                    $this.$refs.SerTExhibiAdc.chartOptions.title.text = parseInt(data.ServilletasT[0].Tc) === 0 ? '0 %' : Math.round(((parseInt(data.ServilletasTF[0].Tc) / parseInt(data.ServilletasT[0].Tc)) * 100)) + " %"
                    // Faciales
                    $this.$refs.FacialDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Faciales[0].Tu) === 0 ? 0 : (parseInt(data.FacialesF[0].Tu) / parseInt(data.Faciales[0].Tu)) * 100
                    $this.$refs.FacialDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Faciales[0].Tu) === 0 ? 0 :  100 - ((parseInt(data.FacialesF[0].Tu) / parseInt(data.Faciales[0].Tu)) * 100)
                    $this.$refs.FacialDisponiblidad.chartOptions.title.text = parseInt(data.Faciales[0].Tu) === 0 ? '0 %' : Math.round(((parseInt(data.FacialesF[0].Tu) / parseInt(data.Faciales[0].Tu)) * 100)) + " %"
                    $this.$refs.FacialInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Faciales[0].Td) === 0 ? 0 : (parseInt(data.FacialesF[0].Td) / parseInt(data.Faciales[0].Td)) * 100
                    $this.$refs.FacialInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Faciales[0].Td) === 0 ? 0 :  100 - ((parseInt(data.FacialesF[0].Td) / parseInt(data.Faciales[0].Td)) * 100)
                    $this.$refs.FacialInventarioExh.chartOptions.title.text = parseInt(data.Faciales[0].Td) === 0 ? '0 %' : Math.round(((parseInt(data.FacialesF[0].Td) / parseInt(data.Faciales[0].Td)) * 100)) + " %"
                    $this.$refs.FacialPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Faciales[0].Tt) === 0 ? 0 : (parseInt(data.FacialesF[0].Tt) / parseInt(data.Faciales[0].Tt)) * 100
                    $this.$refs.FacialPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Faciales[0].Tt) === 0 ? 0 :  100 - ((parseInt(data.FacialesF[0].Tt) / parseInt(data.Faciales[0].Tt)) * 100)
                    $this.$refs.FacialPrecio.chartOptions.title.text = parseInt(data.Faciales[0].Tt) === 0 ? '0 %' : Math.round(((parseInt(data.FacialesF[0].Tt) / parseInt(data.Faciales[0].Tt)) * 100)) + " %"
                    $this.$refs.FacialExhibiAdc.chartOptions.series[0].data[0][1] = parseInt(data.Faciales[0].Tc) === 0 ? 0 : (parseInt(data.FacialesF[0].Tc) / parseInt(data.Faciales[0].Tc)) * 100
                    $this.$refs.FacialExhibiAdc.chartOptions.series[0].data[1][1] = parseInt(data.Faciales[0].Tc) === 0 ? 0 :  100 - ((parseInt(data.FacialesF[0].Tc) / parseInt(data.Faciales[0].Tc)) * 100)
                    $this.$refs.FacialExhibiAdc.chartOptions.title.text = parseInt(data.Faciales[0].Tc) === 0 ? '0 %' : Math.round(((parseInt(data.FacialesF[0].Tc) / parseInt(data.Faciales[0].Tc)) * 100)) + " %"
                    // Pañales
                    $this.$refs.PanalDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Panal[0].Tu) === 0 ? 0 : (parseInt(data.PanalTF[0].Tu) / parseInt(data.Panal[0].Tu)) * 100
                    $this.$refs.PanalDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Panal[0].Tu) === 0 ? 0 :  100 - ((parseInt(data.PanalTF[0].Tu) / parseInt(data.Panal[0].Tu)) * 100)
                    $this.$refs.PanalDisponiblidad.chartOptions.title.text = parseInt(data.Panal[0].Tu) === 0 ? '0 %' : Math.round(((parseInt(data.PanalTF[0].Tu) / parseInt(data.Panal[0].Tu)) * 100)) + " %"
                    $this.$refs.PanalInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Panal[0].Td) === 0 ? 0 : (parseInt(data.PanalTF[0].Td) / parseInt(data.Panal[0].Td)) * 100
                    $this.$refs.PanalInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Panal[0].Td) === 0 ? 0 :  100 - ((parseInt(data.PanalTF[0].Td) / parseInt(data.Panal[0].Td)) * 100)
                    $this.$refs.PanalInventarioExh.chartOptions.title.text = parseInt(data.Panal[0].Td) === 0 ? '0 %' : Math.round(((parseInt(data.PanalTF[0].Td) / parseInt(data.Panal[0].Td)) * 100)) + " %"
                    $this.$refs.PanalPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Panal[0].Tt) === 0 ? 0 : (parseInt(data.PanalTF[0].Tt) / parseInt(data.Panal[0].Tt)) * 100
                    $this.$refs.PanalPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Panal[0].Tt) === 0 ? 0 :  100 - ((parseInt(data.PanalTF[0].Tt) / parseInt(data.Panal[0].Tt)) * 100)
                    $this.$refs.PanalPrecio.chartOptions.title.text = parseInt(data.Panal[0].Tt) === 0 ? '0 %' : Math.round(((parseInt(data.PanalTF[0].Tt) / parseInt(data.Panal[0].Tt)) * 100)) + " %"
                    $this.$refs.PanalExhibiAdc.chartOptions.series[0].data[0][1] = parseInt(data.Panal[0].Tc) === 0 ? 0 : (parseInt(data.PanalTF[0].Tc) / parseInt(data.Panal[0].Tc)) * 100
                    $this.$refs.PanalExhibiAdc.chartOptions.series[0].data[1][1] = parseInt(data.Panal[0].Tc) === 0 ? 0 :  100 - ((parseInt(data.PanalTF[0].Tc) / parseInt(data.Panal[0].Tc)) * 100)
                    $this.$refs.PanalExhibiAdc.chartOptions.title.text = parseInt(data.Panal[0].Tc) === 0 ? '0 %' : Math.round(((parseInt(data.PanalTF[0].Tc) / parseInt(data.Panal[0].Tc)) * 100)) + " %"
                    // Toallas Húmedas
                    $this.$refs.ThDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Th[0].Tu) === 0 ? 0 : (parseInt(data.ThTF[0].Tu) / parseInt(data.Th[0].Tu)) * 100
                    $this.$refs.ThDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Th[0].Tu) === 0 ? 0 :  100 - ((parseInt(data.ThTF[0].Tu) / parseInt(data.Th[0].Tu)) * 100)
                    $this.$refs.ThDisponiblidad.chartOptions.title.text = parseInt(data.Th[0].Tu) === 0 ? '0 %' : Math.round(((parseInt(data.ThTF[0].Tu) / parseInt(data.Th[0].Tu)) * 100)) + " %"
                    $this.$refs.ThInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Th[0].Td) === 0 ? 0 : (parseInt(data.ThTF[0].Td) / parseInt(data.Th[0].Td)) * 100
                    $this.$refs.ThInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Th[0].Td) === 0 ? 0 :  100 - ((parseInt(data.ThTF[0].Td) / parseInt(data.Th[0].Td)) * 100)
                    $this.$refs.ThInventarioExh.chartOptions.title.text = parseInt(data.Th[0].Td) === 0 ? '0 %' : Math.round(((parseInt(data.ThTF[0].Td) / parseInt(data.Th[0].Td)) * 100)) + " %"
                    $this.$refs.ThPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Th[0].Tt) === 0 ? 0 : (parseInt(data.ThTF[0].Tt) / parseInt(data.Th[0].Tt)) * 100
                    $this.$refs.ThPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Th[0].Tt) === 0 ? 0 :  100 - ((parseInt(data.ThTF[0].Tt) / parseInt(data.Th[0].Tt)) * 100)
                    $this.$refs.ThPrecio.chartOptions.title.text = parseInt(data.Th[0].Tt) === 0 ? '0 %' : Math.round(((parseInt(data.ThTF[0].Tt) / parseInt(data.Th[0].Tt)) * 100)) + " %"
                    $this.$refs.ThExhibiAdc.chartOptions.series[0].data[0][1] = parseInt(data.Th[0].Tc) === 0 ? 0 : (parseInt(data.ThTF[0].Tc) / parseInt(data.Th[0].Tc)) * 100
                    $this.$refs.ThExhibiAdc.chartOptions.series[0].data[1][1] = parseInt(data.Th[0].Tc) === 0 ? 0 :  100 - ((parseInt(data.ThTF[0].Tc) / parseInt(data.Th[0].Tc)) * 100)
                    $this.$refs.ThExhibiAdc.chartOptions.title.text = parseInt(data.Th[0].Tc) === 0 ? '0 %' : Math.round(((parseInt(data.ThTF[0].Tc) / parseInt(data.Th[0].Tc)) * 100)) + " %"
                    // Jabones
                    $this.$refs.JabonesDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Jabones[0].Tu) === 0 ? 0 : (parseInt(data.JabonesF[0].Tu) / parseInt(data.Jabones[0].Tu)) * 100
                    $this.$refs.JabonesDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Jabones[0].Tu) === 0 ? 0 :  100 - ((parseInt(data.JabonesF[0].Tu) / parseInt(data.Jabones[0].Tu)) * 100)
                    $this.$refs.JabonesDisponiblidad.chartOptions.title.text = parseInt(data.Jabones[0].Tu) === 0 ? '0 %' : Math.round(((parseInt(data.JabonesF[0].Tu) / parseInt(data.Jabones[0].Tu)) * 100)) + " %"
                    $this.$refs.JabonesInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Jabones[0].Td) === 0 ? 0 : (parseInt(data.JabonesF[0].Td) / parseInt(data.Jabones[0].Td)) * 100
                    $this.$refs.JabonesInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Jabones[0].Td) === 0 ? 0 :  100 - ((parseInt(data.JabonesF[0].Td) / parseInt(data.Jabones[0].Td)) * 100)
                    $this.$refs.JabonesInventarioExh.chartOptions.title.text = parseInt(data.Jabones[0].Td) === 0 ? '0 %' : Math.round(((parseInt(data.JabonesF[0].Td) / parseInt(data.Jabones[0].Td)) * 100)) + " %"
                    $this.$refs.JabonesPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Jabones[0].Tt) === 0 ? 0 : (parseInt(data.JabonesF[0].Tt) / parseInt(data.Jabones[0].Tt)) * 100
                    $this.$refs.JabonesPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Jabones[0].Tt) === 0 ? 0 :  100 - ((parseInt(data.JabonesF[0].Tt) / parseInt(data.Jabones[0].Tt)) * 100)
                    $this.$refs.JabonesPrecio.chartOptions.title.text = parseInt(data.Jabones[0].Tt) === 0 ? '0 %' : Math.round(((parseInt(data.JabonesF[0].Tt) / parseInt(data.Jabones[0].Tt)) * 100)) + " %"
                    $this.$refs.JabonesExhibiAdc.chartOptions.series[0].data[0][1] = parseInt(data.Jabones[0].Tc) === 0 ? 0 : (parseInt(data.JabonesF[0].Tc) / parseInt(data.Jabones[0].Tc)) * 100
                    $this.$refs.JabonesExhibiAdc.chartOptions.series[0].data[1][1] = parseInt(data.Jabones[0].Tc) === 0 ? 0 :  100 - ((parseInt(data.JabonesF[0].Tc) / parseInt(data.Jabones[0].Tc)) * 100)
                    $this.$refs.JabonesExhibiAdc.chartOptions.title.text = parseInt(data.Jabones[0].Tc) === 0 ? '0 %' : Math.round(((parseInt(data.JabonesF[0].Tc) / parseInt(data.Jabones[0].Tc)) * 100)) + " %"
                    // PF
                    $this.$refs.PFDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.PF[0].Tu) === 0 ? 0 : (parseInt(data.PFF[0].Tu) / parseInt(data.PF[0].Tu)) * 100
                    $this.$refs.PFDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.PF[0].Tu) === 0 ? 0 :  100 - ((parseInt(data.PFF[0].Tu) / parseInt(data.PF[0].Tu)) * 100)
                    $this.$refs.PFDisponiblidad.chartOptions.title.text = parseInt(data.PF[0].Tu) === 0 ? '0 %' : Math.round(((parseInt(data.PFF[0].Tu) / parseInt(data.PF[0].Tu)) * 100)) + " %"
                    $this.$refs.PFInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.PF[0].Td) === 0 ? 0 : (parseInt(data.PFF[0].Td) / parseInt(data.PF[0].Td)) * 100
                    $this.$refs.PFInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.PF[0].Td) === 0 ? 0 :  100 - ((parseInt(data.PFF[0].Td) / parseInt(data.PF[0].Td)) * 100)
                    $this.$refs.PFInventarioExh.chartOptions.title.text = parseInt(data.PF[0].Td) === 0 ? '0 %' : Math.round(((parseInt(data.PFF[0].Td) / parseInt(data.PF[0].Td)) * 100)) + " %"
                    $this.$refs.PFPrecio.chartOptions.series[0].data[0][1] = parseInt(data.PF[0].Tt) === 0 ? 0 : (parseInt(data.PFF[0].Tt) / parseInt(data.PF[0].Tt)) * 100
                    $this.$refs.PFPrecio.chartOptions.series[0].data[1][1] = parseInt(data.PF[0].Tt) === 0 ? 0 :  100 - ((parseInt(data.PFF[0].Tt) / parseInt(data.PF[0].Tt)) * 100)
                    $this.$refs.PFPrecio.chartOptions.title.text = parseInt(data.PF[0].Tt) === 0 ? '0 %' : Math.round(((parseInt(data.PFF[0].Tt) / parseInt(data.PF[0].Tt)) * 100)) + " %"
                    $this.$refs.PFExhibiAdc.chartOptions.series[0].data[0][1] = parseInt(data.PF[0].Tc) === 0 ? 0 : (parseInt(data.PFF[0].Tc) / parseInt(data.PF[0].Tc)) * 100
                    $this.$refs.PFExhibiAdc.chartOptions.series[0].data[1][1] = parseInt(data.PF[0].Tc) === 0 ? 0 :  100 - ((parseInt(data.PFF[0].Tc) / parseInt(data.PF[0].Tc)) * 100)
                    $this.$refs.PFExhibiAdc.chartOptions.title.text = parseInt(data.PF[0].Tc) === 0 ? '0 %' : Math.round(((parseInt(data.PFF[0].Tc) / parseInt(data.PF[0].Tc)) * 100)) + " %"
                    //$this.chartOptions.redraw
                    $this.TVu = data.TA[0].Total
                    $this.TVd = data.TA[1].Total
                    $this.TVt = data.TA[2].Total
                    $this.TVc = data.TA[3].Total
                    $this.TVci = data.TA[4].Total
                    $this.TVs = data.TA[5].Total
                    // AREA UNO
                    $this.$refs.AUnoDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Uno[0].TT) === 0 ? 0 : (parseInt(data.Uno[0].Tu) / parseInt(data.Uno[0].TT)) * 100
                    $this.$refs.AUnoDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Uno[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Uno[0].Tu) / parseInt(data.Uno[0].TT)) * 100)
                    $this.$refs.AUnoDisponiblidad.chartOptions.title.text = parseInt(data.Uno[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Uno[0].Tu) / parseInt(data.Uno[0].TT)) * 100)) + " %"
                    $this.$refs.AUnoInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Uno[0].TT) === 0 ? 0 : (parseInt(data.Uno[0].Td) / parseInt(data.Uno[0].TT)) * 100
                    $this.$refs.AUnoInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Uno[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Uno[0].Td) / parseInt(data.Uno[0].TT)) * 100)
                    $this.$refs.AUnoInventarioExh.chartOptions.title.text = parseInt(data.Uno[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Uno[0].Td) / parseInt(data.Uno[0].TT)) * 100)) + " %"
                    $this.$refs.AUnoPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Uno[0].TT) === 0 ? 0 : (parseInt(data.Uno[0].Tt) / parseInt(data.Uno[0].TT)) * 100
                    $this.$refs.AUnoPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Uno[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Uno[0].Tt) / parseInt(data.Uno[0].TT)) * 100)
                    $this.$refs.AUnoPrecio.chartOptions.title.text = parseInt(data.Uno[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Uno[0].Tt) / parseInt(data.Uno[0].TT)) * 100)) + " %"
                    // AREA DOS
                    $this.$refs.ADosDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Dos[0].TT) === 0 ? 0 : (parseInt(data.Dos[0].Tu) / parseInt(data.Dos[0].TT)) * 100
                    $this.$refs.ADosDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Dos[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Dos[0].Tu) / parseInt(data.Dos[0].TT)) * 100)
                    $this.$refs.ADosDisponiblidad.chartOptions.title.text = parseInt(data.Dos[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Dos[0].Tu) / parseInt(data.Dos[0].TT)) * 100)) + " %"
                    $this.$refs.ADosInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Dos[0].TT) === 0 ? 0 : (parseInt(data.Dos[0].Td) / parseInt(data.Dos[0].TT)) * 100
                    $this.$refs.ADosInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Dos[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Dos[0].Td) / parseInt(data.Dos[0].TT)) * 100)
                    $this.$refs.ADosInventarioExh.chartOptions.title.text = parseInt(data.Dos[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Dos[0].Td) / parseInt(data.Dos[0].TT)) * 100)) + " %"
                    $this.$refs.ADosPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Dos[0].TT) === 0 ? 0 : (parseInt(data.Dos[0].Tt) / parseInt(data.Dos[0].TT)) * 100
                    $this.$refs.ADosPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Dos[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Dos[0].Tt) / parseInt(data.Dos[0].TT)) * 100)
                    $this.$refs.ADosPrecio.chartOptions.title.text = parseInt(data.Dos[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Dos[0].Tt) / parseInt(data.Dos[0].TT)) * 100)) + " %"
                    // AREA TRES
                    $this.$refs.ATresDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Tres[0].TT) === 0 ? 0 : (parseInt(data.Tres[0].Tu) / parseInt(data.Tres[0].TT)) * 100
                    $this.$refs.ATresDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Tres[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Tres[0].Tu) / parseInt(data.Tres[0].TT)) * 100)
                    $this.$refs.ATresDisponiblidad.chartOptions.title.text = parseInt(data.Tres[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Tres[0].Tu) / parseInt(data.Tres[0].TT)) * 100)) + " %"
                    $this.$refs.ATresInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Tres[0].TT) === 0 ? 0 : (parseInt(data.Tres[0].Td) / parseInt(data.Tres[0].TT)) * 100
                    $this.$refs.ATresInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Tres[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Tres[0].Td) / parseInt(data.Tres[0].TT)) * 100)
                    $this.$refs.ATresInventarioExh.chartOptions.title.text = parseInt(data.Tres[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Tres[0].Td) / parseInt(data.Tres[0].TT)) * 100)) + " %"
                    $this.$refs.ATresPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Tres[0].TT) === 0 ? 0 : (parseInt(data.Tres[0].Tt) / parseInt(data.Tres[0].TT)) * 100
                    $this.$refs.ATresPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Tres[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Tres[0].Tt) / parseInt(data.Tres[0].TT)) * 100)
                    $this.$refs.ATresPrecio.chartOptions.title.text = parseInt(data.Tres[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Tres[0].Tt) / parseInt(data.Tres[0].TT)) * 100)) + " %"
                    // AREA CUATRO
                    $this.$refs.ACuatroDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Cuatro[0].TT) === 0 ? 0 : (parseInt(data.Cuatro[0].Tu) / parseInt(data.Cuatro[0].TT)) * 100
                    $this.$refs.ACuatroDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Cuatro[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Cuatro[0].Tu) / parseInt(data.Cuatro[0].TT)) * 100)
                    $this.$refs.ACuatroDisponiblidad.chartOptions.title.text = parseInt(data.Cuatro[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Cuatro[0].Tu) / parseInt(data.Cuatro[0].TT)) * 100)) + " %"
                    $this.$refs.ACuatroInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Cuatro[0].TT) === 0 ? 0 : (parseInt(data.Cuatro[0].Td) / parseInt(data.Cuatro[0].TT)) * 100
                    $this.$refs.ACuatroInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Cuatro[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Cuatro[0].Td) / parseInt(data.Cuatro[0].TT)) * 100)
                    $this.$refs.ACuatroInventarioExh.chartOptions.title.text = parseInt(data.Cuatro[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Cuatro[0].Td) / parseInt(data.Cuatro[0].TT)) * 100)) + " %"
                    $this.$refs.ACuatroPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Cuatro[0].TT) === 0 ? 0 : (parseInt(data.Cuatro[0].Tt) / parseInt(data.Cuatro[0].TT)) * 100
                    $this.$refs.ACuatroPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Cuatro[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Cuatro[0].Tt) / parseInt(data.Cuatro[0].TT)) * 100)
                    $this.$refs.ACuatroPrecio.chartOptions.title.text = parseInt(data.Cuatro[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Cuatro[0].Tt) / parseInt(data.Cuatro[0].TT)) * 100)) + " %"
                    // AREA CINCO
                    $this.$refs.ACincoDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Cinco[0].TT) === 0 ? 0 : (parseInt(data.Cinco[0].Tu) / parseInt(data.Cinco[0].TT)) * 100
                    $this.$refs.ACincoDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Cinco[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Cinco[0].Tu) / parseInt(data.Cinco[0].TT)) * 100)
                    $this.$refs.ACincoDisponiblidad.chartOptions.title.text = parseInt(data.Cinco[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Cinco[0].Tu) / parseInt(data.Cinco[0].TT)) * 100)) + " %"
                    $this.$refs.ACincoInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Cinco[0].TT) === 0 ? 0 : (parseInt(data.Cinco[0].Td) / parseInt(data.Cinco[0].TT)) * 100
                    $this.$refs.ACincoInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Cinco[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Cinco[0].Td) / parseInt(data.Cinco[0].TT)) * 100)
                    $this.$refs.ACincoInventarioExh.chartOptions.title.text = parseInt(data.Cinco[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Cinco[0].Td) / parseInt(data.Cinco[0].TT)) * 100)) + " %"
                    $this.$refs.ACincoPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Cinco[0].TT) === 0 ? 0 : (parseInt(data.Cinco[0].Tt) / parseInt(data.Cinco[0].TT)) * 100
                    $this.$refs.ACincoPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Cinco[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Cinco[0].Tt) / parseInt(data.Cinco[0].TT)) * 100)
                    $this.$refs.ACincoPrecio.chartOptions.title.text = parseInt(data.Cinco[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Cinco[0].Tt) / parseInt(data.Cinco[0].TT)) * 100)) + " %"
                    // AREA SEIS
                    $this.$refs.ASeisDisponiblidad.chartOptions.series[0].data[0][1] = parseInt(data.Seis[0].TT) === 0 ? 0 : (parseInt(data.Seis[0].Tu) / parseInt(data.Seis[0].TT)) * 100
                    $this.$refs.ASeisDisponiblidad.chartOptions.series[0].data[1][1] = parseInt(data.Seis[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Seis[0].Tu) / parseInt(data.Seis[0].TT)) * 100)
                    $this.$refs.ASeisDisponiblidad.chartOptions.title.text = parseInt(data.Seis[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Seis[0].Tu) / parseInt(data.Seis[0].TT)) * 100)) + " %"
                    $this.$refs.ASeisInventarioExh.chartOptions.series[0].data[0][1] = parseInt(data.Seis[0].TT) === 0 ? 0 : (parseInt(data.Seis[0].Td) / parseInt(data.Seis[0].TT)) * 100
                    $this.$refs.ASeisInventarioExh.chartOptions.series[0].data[1][1] = parseInt(data.Seis[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Seis[0].Td) / parseInt(data.Seis[0].TT)) * 100)
                    $this.$refs.ASeisInventarioExh.chartOptions.title.text = parseInt(data.Seis[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Seis[0].Td) / parseInt(data.Seis[0].TT)) * 100)) + " %"
                    $this.$refs.ASeisPrecio.chartOptions.series[0].data[0][1] = parseInt(data.Seis[0].TT) === 0 ? 0 : (parseInt(data.Seis[0].Tt) / parseInt(data.Seis[0].TT)) * 100
                    $this.$refs.ASeisPrecio.chartOptions.series[0].data[1][1] = parseInt(data.Seis[0].TT) === 0 ? 0 :  100 - ((parseInt(data.Seis[0].Tt) / parseInt(data.Seis[0].TT)) * 100)
                    $this.$refs.ASeisPrecio.chartOptions.title.text = parseInt(data.Seis[0].TT) === 0 ? '0 %' : Math.round(((parseInt(data.Seis[0].Tt) / parseInt(data.Seis[0].TT)) * 100)) + " %"
                });
        },
    },
    mounted() {

    }
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col-sm-3">
                <week-select-kpi
                    :placeholderm="'Selecciones semana'"
                    ref="weekSelect"
                    v-on:cambioSemana="getReport"
                />
            </div>
        </div>
        <div class="row mt-sm-4">
            <div class="col-sm-4">
                <div class="card custom-card">
                    <div class="card-body dash1">
                        <div class="">
                            <p class="mb-1 tx-inverse">Total Tiendas <b class="tx-bold text-info">{{ Ttda }}</b></p>
                            <div class="ms-auto text-right">
                                <i class="fa fa-chart-line fs-20 text-primary"></i>
                            </div>
                        </div>
                        <div>
                            <h3 class="dash-25">En ruta {{ TtdaEnRuta }}</h3>
                        </div>
                        <div class="progress progress-xs  mb-1" role="progressbar" aria-label="Basic example" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                            <div class="progress-bar" :style="'width: '+ Math.round((TtdaEnRuta/Ttda) * 100) + '%'"></div>
                        </div>
                        <div class="expansion-label d-flex">
                            <span class="text-muted">Programadas: </span> <span class="ms-auto"><i class="fa fa-caret-up me-1 text-success text-right"></i> {{ Math.round((TtdaEnRuta/Ttda) * 100 )}}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="card custom-card">
                    <div class="card-body dash1">
                        <div class="">
                            <p class="mb-1 tx-inverse">Tiendas Con Ruta <b class="tx-bold text-info">{{ TtdaEnRuta }}</b></p>
                            <div class="ms-auto text-right">
                                <i class="fa fa-chart-line fs-20 text-primary"></i>
                            </div>
                        </div>
                        <div>
                            <h3 class="dash-25">Visitadas {{ Ttdavisitadas }}</h3>
                        </div>
                        <div class="progress progress-xs  mb-1" role="progressbar" aria-label="Basic example" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                            <div class="progress-bar" :style="'width: '+  (TtdaEnRuta === 0 ? 0 : Math.round((Ttdavisitadas/TtdaEnRuta) * 100)) + '%'"></div>
                        </div>
                        <div class="expansion-label d-flex">
                            <span class="text-muted">Por visitar: </span> <span class="ms-auto"><i class="fa fa-caret-up me-1 text-success text-right"></i> {{ TtdaEnRuta === 0 ? 0 : Math.round((Ttdavisitadas/TtdaEnRuta) * 100) }}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4"></div>
        </div>
        <div class="row mt-sm-6">
            <div class="col-sm-5">
                <v-client-table :data="tableDataTienda" :columns="columnsDataTienda" :options="optionsTiendaSi" class="table-responsive" ref="tablePromociones">
                    <div slot="Productos" slot-scope="props">
                        <div v-if="props.row.programadas.length === 0">
                            <b class="tx-bold text-danger">S/C</b>
                        </div>
                        <div v-else>
                            <div class="text-center">
                                <p class="badge badge-success">{{ props.row.programadas.length }}</p>
                            </div>
                        </div>
                    </div>
                </v-client-table>
            </div>
            <div class="col-sm-4">
                <highcharts :options="chartOptions"></highcharts>
            </div>
            <div class="col-sm-3">
                <label><b>Tienda no programadas</b></label>
                <v-client-table :data="tableDataTiendaNo" :columns="columnsDataTiendaNo" :options="optionsTiendaNo" class="table-responsive" ref="tablePromociones"></v-client-table>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-sm-4" />
            <div class="col-sm-4 text-center">
                <p class="h3">Categorías</p>
            </div>
            <div class="col-sm-4" />
        </div>
        <div class="row mt-3">
            <div class="col-sm-12 text-center">
                <div class="row">
                    <div class="col-sm-4 text-center">
                    </div>
                    <div class="col-sm-2 text-center">
                        <img alt="iconkpi" class="img-fluid" src="/theme/img/kpisams/kpiuno.png" >
                        <p class="font-weight-bold">Disponiblidad</p>
                    </div>
                    <div class="col-sm-2 text-center">
                        <img alt="iconkpi" class="img-fluid" src="/theme/img/kpisams/kpidos.png" >
                        <p class="font-weight-bold">Inventario Exhibidio</p>
                    </div>
                    <div class="col-sm-2 text-center">
                        <img alt="iconkpi" class="img-fluid" src="/theme/img/kpisams/kapitres.png" >
                        <p class="font-weight-bold">Precio</p>
                    </div>
                    <div class="col-sm-2 text-center">
                        <img alt="iconkpi" class="img-fluid" src="/theme/img/kpisams/kpiacuatro.png" height="44" width="44" >
                        <p class="font-weight-bold">Exhibición Adicional</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <span class="badge badge-info"><span class="h3">Higiénicos</span></span>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="HigDisponiblidad"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="HigInventarioExh"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="HigPrecio"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="HigExhibiAdc"></chart-semi-pie>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <span class="badge badge-info"><span class="h3">Servilletas</span></span>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="SerDisponiblidad"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="SerInventarioExh"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="SerPrecio"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="SerExhibiAdc"></chart-semi-pie>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <span class="badge badge-info"><span class="h3">Servitoallas</span></span>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="SerTDisponiblidad"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="SerTInventarioExh"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="SerTPrecio"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="SerTExhibiAdc"></chart-semi-pie>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <span class="badge badge-info"><span class="h3">Faciales</span></span>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="FacialDisponiblidad"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="FacialInventarioExh"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="FacialPrecio"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="FacialExhibiAdc"></chart-semi-pie>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <span class="badge badge-info"><span class="h3">Pañales</span></span>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="PanalDisponiblidad"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="PanalInventarioExh"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="PanalPrecio"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="PanalExhibiAdc"></chart-semi-pie>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <span class="badge badge-info"><span class="h3">Toallitas Húmedas</span></span>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="ThDisponiblidad"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="ThInventarioExh"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="ThPrecio"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="ThExhibiAdc"></chart-semi-pie>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <span class="badge badge-info"><span class="h3">Jabones</span></span>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="JabonesDisponiblidad"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="JabonesInventarioExh"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="JabonesPrecio"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="JabonesExhibiAdc"></chart-semi-pie>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <span class="badge badge-info"><span class="h3">Protección Femenina</span></span>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="PFDisponiblidad"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="PFInventarioExh"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="PFPrecio"></chart-semi-pie>
                    </div>
                    <div class="col-sm-2">
                        <chart-semi-pie ref="PFExhibiAdc"></chart-semi-pie>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card border border-danger custom-card">
                    <div class="card-body">
                        <p class="fs-14 fw-semibold mb-2 lh-1">
                            <h3 class="">ÁREA 1 | TV {{ TVu }}</h3>
                        </p>
                        <div class="row">
                            <div class="col-sm-4">
                                <chart-semi-pie ref="AUnoDisponiblidad"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Disponiblidad</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="AUnoInventarioExh"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Inventario</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="AUnoPrecio"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Precio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card border border-orange custom-card">
                    <div class="card-body">
                        <p class="fs-14 fw-semibold mb-2 lh-1">
                            <h3 class="">ÁREA 2 | TV {{ TVd }}</h3>
                        </p>
                        <div class="row">
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ADosDisponiblidad"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Disponiblidad</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ADosInventarioExh"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Inventario</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ADosPrecio"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Precio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card border border-warning custom-card">
                    <div class="card-body">
                        <p class="fs-14 fw-semibold mb-2 lh-1">
                            <h3 class="">ÁREA 3 | TV {{ TVt }}</h3>
                        </p>
                        <div class="row">
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ATresDisponiblidad"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Disponiblidad</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ATresInventarioExh"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Inventario</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ATresPrecio"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Precio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card border border-info custom-card">
                    <div class="card-body">
                        <p class="fs-14 fw-semibold mb-2 lh-1">
                            <h3 class="">ÁREA 4| TV {{ TVc }}</h3>
                        </p>
                        <div class="row">
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ACuatroDisponiblidad"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Disponiblidad</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ACuatroInventarioExh"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Inventario</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ACuatroPrecio"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Precio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card border border-black custom-card">
                    <div class="card-body">
                        <p class="fs-14 fw-semibold mb-2 lh-1">
                            <h3 class="">ÁREA 5| TV {{ TVci }}</h3>
                        </p>
                        <div class="row">
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ACincoDisponiblidad"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Disponiblidad</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ACincoInventarioExh"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Inventario</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ACincoPrecio"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Precio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card border border-success custom-card">
                    <div class="card-body">
                        <p class="fs-14 fw-semibold mb-2 lh-1">
                            <h3 class="">ÁREA 6| TV {{ TVs }}</h3>
                        </p>
                        <div class="row">
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ASeisDisponiblidad"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Disponiblidad</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ASeisInventarioExh"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Inventario</p>
                            </div>
                            <div class="col-sm-4">
                                <chart-semi-pie ref="ASeisPrecio"></chart-semi-pie>
                                <p class="text-center font-weight-bold">Precio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 660px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
.border-orange {
    border-color: #f6660e !important;
}
.border-black {
    border-color: #000000 !important;
}
</style>
