<template>
    <div>
        <v-select :multiple="false" :options="optionsCampanas" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedCampana" @input="setCambio" :disabled="desa"></v-select>
    </div>
</template>

<script>
export default {
    name: "cadenas",
    props:{
        placeholderm:String,
        desa:Boolean,
    },
    data(){
        return{
            selectedCampana:[],
            optionsCampanas:[],
            canal:[],
        }
    },
    mounted() {
        this.getCadenas();
    },
    methods:{
        getCadenas(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/getCampanasFiltro')
                .then(function (r){
                    $this.optionsCampanas = r.data.encuestas;
                });
        },
        setCambio(){
            this.$emit('cambioCadena');
        }

    }
}
</script>

<style scoped>

</style>
