<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-3">
                <label>Periodo:</label>
                <v-select :multiple="false" :options="optionsMes" placeholder="Seleccione"
                          v-model="selectedMes" @input="setCambio"></v-select>
                <label>Agencia:</label>
                <select-agenciasN placeholderm="Agencia" ref="agencias" v-on:cambioAgencia="changeAgencia"></select-agenciasN>
                <h3><span class="badge badge-success">100% de efectividad</span></h3>
                <h3><span class="badge badge-warning">Arriba del 70% de efectividad</span></h3>
                <h3><span class="badge badge-danger">Abajo del 70% de efectividad</span></h3>
                <button class="btn ripple btn-block btn-primary" @click="getReport">Generar Reporte </button>
            </div>
            <div class="col-9">
                <div class="text-center">
                    <div v-if="loadfile">
                        <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                        <p>Preparando información, espere...</p>
                    </div>
                </div>
                <div v-if="showReport">
                    <v-client-table :data="tableData" :columns="columns" :options="options" class="table-responsive" ref="tableSemaforo">
                    </v-client-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "semaforo",
    data(){
        return{
            selectedMes:null,
            optionsMes:[{value:12,label:"2021 Diciembre"},{value:11,label:"2021 Noviembre"},{value:10,label:"2021 Octubre"}],
            loadfile:false,
            showReport:false,
            columns: [],
            tableData: [],
            options: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Gestor','Agencia'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                },
                columnsClasses: {

                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            agencia:[]
        }
    },
    mounted() {
    },
    methods:{
        changeAgencia(){
            var agencia = this.$refs.agencias.selectedAgencias;
            var selectTodas = false;
            if (agencia.length>0){
                agencia.forEach(function (value,key){
                    if (agencia[key].value === 0){
                        selectTodas = true;
                    }
                });

                if (selectTodas){
                    this.agencia = this.$refs.agencias.optionsAgencias;
                    area.getAreas();
                }
                else{
                    this.agencia = agencia;
                }
            }
            else{
                this.agencia = null;
            }
        },
        keyboardEvent(e){
            if (e.which === 13) {
                this.getReport();
            }
        },
        setCambio(){
            this.$emit('cambioMes');
        },
        getReport(){ let $this = this;
            if (this.selectedMes === null){
                this.$toastMessage('error','Seleccione el mes, para generar el reporte!',3000,'center');
            }
            else if (this.$refs.agencias === 0){
                this.$toastMessage('error','Seleccione la agencia, para generar el reporte!',3000,'center');
            }else{
                this.loadfile = true;this.columns=[];this.showReport=false;
                axios.post('/api/SemaforoRutas/getTabla',{mes:this.selectedMes,agencias:this.agencia})
                    .then(r => {
                        $this.columns = r.data.Columnas;
                        $this.tableData = r.data.Data;
                        $this.showReport=true;
                        $this.loadfile=false;
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        }
                    );
            }
        }
    }
}
</script>

<style scoped>

</style>
