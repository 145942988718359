<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h5 class="text-center">GALERÍA ENCUESTA</h5>
                <div class="justify-content-center text-center mt-3 d-flex">

                    <a href="#" class="btn ripple btn-info btn-icon mr-3" @click="CambiarPaginaGaleria()">
                        <i class="fas fa-arrow-left"></i>
                    </a>
                </div>
            </div>
            <div class="col-md-12">
                <!-- Modal effects -->
                <div class="modal" :id="'modalShowData'+tipoGaleria" data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content modal-content-demo">
                            <div class="modal-header">
                                <h6 class="modal-title">Detalle Imagen <b>{{tipoGaleria}}</b> <i class="fas fa-file-image"></i></h6>
                                <button ref="closeModal" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                            </div>
                            <div class="modal-body">
                                <div class="row" v-if="dataGaleria.length>0">
                                    <div class="col-md-6">
                                        <div class="text-center">
                                            zoomed: {{ zoomed }}
                                            <button @click="$refs.zoomer.zoomIn()">+</button>
                                            <button @click="$refs.zoomer.zoomOut()">-</button>
                                        </div>
                                        <v-zoomer ref="zoomer"
                                                  style=" border: solid 1px silver;width: 350px; height: 500px;"
                                                  :max-scale="10"
                                                  :zooming-elastic="false"
                                                  :zoomed.sync="zoomed">
                                            <img
                                                :src="'/storage'+dataGaleria[idxGlobalSelected].Arch"
                                                style="object-fit: contain; width: 100%; height: 100%;"
                                                alt="imgZoom"
                                            >
                                        </v-zoomer>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="tx-bold">Código</label>
                                        <p class="tx-bold">{{dataGaleria[idxGlobalSelected].Cdgentpto}}</p>
                                        <label class="tx-bold">Producto</label>
                                        <p class="tx-12">{{dataGaleria[idxGlobalSelected].Producto}}</p>
                                        <label class="tx-bold">Fecha Registro</label>
                                        <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Fchcrt}}</p>
                                        <label class="tx-bold">Canal</label>
                                        <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Canal}}</p>
                                        <label class="tx-bold">Cadena</label>
                                        <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Cadena}}</p>
                                        <label class="tx-bold">Formato</label>
                                        <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Formato}}</p>
                                        <label class="tx-bold">Tienda</label>
                                        <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Tienda}}</p>
                                        <label class="tx-bold">Estado</label>
                                        <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Estado}}</p>
                                        <label class="tx-bold">Ciudad</label>
                                        <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Ciudad}}</p>
                                        <label class="tx-bold">Usuario</label>
                                        <p class="tx-12 tx-bold text-info" v-html="dataGaleria[idxGlobalSelected].Usuario"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Modal effects-->
                <div class="row">
                    <div class="col-md-2"><h5><b class="text-success">{{tipoGaleria}}</b></h5></div>
                    <div class="col-md-3"><h5>Total Imagenes: <b class="text-success">{{totalImg}}</b></h5></div>
                    <div class="col-md-7">
                        <button v-show="selectAllBtn" class="btn ripple btn-success" @click="selectAll(selectAllBtn)"><i class="fe fe-check-square"></i> Marcar Todo</button>
                        <button v-show="!selectAllBtn" class="btn ripple btn-danger " @click="selectAll(selectAllBtn)"><i class="fe fe-check-square"></i> Deseleccionar Todo</button>
                        <button v-if="!loadfileZip" class="btn ripple btn-primary " @click="ExportFile" ><i class="fe fe-download-cloud"></i> Descargar Zip</button>
                        <button v-if="!loadFilePptx" class="btn ripple btn-secondary "  @click="ExportFilePptx"><i class="fas fa-file-powerpoint"></i> Descargar PPTX</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <div v-if="loadfileZip || loadFilePptx || loadDataPost">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando informacion, espere...</p>
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                </div>
                <br>
                <div id="global" class="row" v-if="dataGaleria.length>0">
                    <div class="col-md-4" v-for="items in dataGaleria">
                        <div class="row" v-if="items.ShowT">
                            <div class="col-md-1">
                                <label class="ckbox" v-if="items.Disponible === '1'">
                                    <input type="checkbox" v-model="items.Checked">
                                    <span> </span>
                                </label>
                            </div>
                            <div class="col-md-6">
                                <img v-show="items.Mostrar === '1'" class="img-thumbnail" :src="'/storage'+items.Arch" alt="imagenFaltantes" @load="onImgLoad(items.Id)" @error="setFail(items.Id)"/>
                                <img v-if="items.Mostrar === '0'" alt="loading img" class="img-thumbnail" src="/theme/img/loads/lavaload.gif"/>
                            </div>
                            <div class="col-md-5">
                                <p class="tx-bold" v-if="items.Kcmid!==''">{{items.Kcmid}}</p>
                                <p class="tx-bold" v-if="items.Cdgentpto!==''">{{items.Cdgentpto}}</p>
                                <p class="tx-12" v-if="items.Producto!==''">{{items.Producto}}</p>
                                <p class="tx-12 tx-bold text-danger" v-if="items.Fchcrt!==''">{{items.Fchcrt}}</p>
                                <p class="tx-12 tx-bold text-danger" v-if="items.Fchreg!==''">{{items.Fchreg}}</p>
                                <p class="tx-12 tx-bold text-info" v-if="items.Rol!==''">{{items.Rol}}</p>
                                <p class="tx-12 tx-bold text-danger" v-if="items.Usuario!==''">{{items.Usuario}}</p>
                                <p class="tx-12 tx-bold text-danger" v-if="items.Pregunta!==''">{{items.Pregunta}}</p>
                                <p class="tx-12 tx-bold text-success" v-if="items.Comentario!==''">{{items.Comentario}}</p>
                                <p class="tx-12 tx-bold text-danger" v-if="tipoGaleria === 'PMAYOREO'">U: {{items.Unidad}} C: {{items.Caja}} M: {{items.Mayoreo}}</p>
                                <button @click="loadData(items.Id)" v-if="items.Disponible === '1'" class="btn ripple btn-outline-info" data-effect="effect-super-scaled" data-toggle="modal" :href="'#modalShowData'+tipoGaleria"><i class="fas fa-eye"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="text-center">
                            <button class="btn ripple btn-info btn-with-icon text-center" @click="mostrarveintemas"><i class="fas fa-question"></i> Mostrar siguientes 10</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "galeria",
    data(){
        return{
            loadfileZip:false,
            loadDataPost:false,
            filtros:{},
            dataGaleria:[],
            selectAllBtn:true,
            isLoaded: false,
            totalImg:0,
            imgToZoom:'/theme/img/impg.png',
            idxGlobalSelected:0,
            zoomed:false,
            loadFilePptx:false,
            imagesS:20,
            cveEncuesta:0,
            tipoGaleria:"ENCUESTAS",
        }
    },
    methods:{
        CambiarPaginaGaleria(){
            this.$emit('cambioGaleria');
        },
        mostrarveintemas(){ let $this = this;
            var a = 0;
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                if (a<=$this.imagesS){
                    $this.dataGaleria[z].ShowT = true;
                }
                a++;
            }
            this.imagesS = this.imagesS + 20;
        },
        loadData(cve){
            this.idxGlobalSelected = this.findIdx(cve);
        },
        setFail(cve) {
            var idx = this.findIdx(cve);
            this.dataGaleria[idx].Mostrar = '1';
            this.dataGaleria[idx].Arch = '/theme/img/img.png';
            this.dataGaleria[idx].Disponible = '0';
        },
        onImgLoad(cve) {
            var idx = this.findIdx(cve);
            this.dataGaleria[idx].Mostrar = '1';
        },
        getData(){ let $this = this; this.dataGaleria=[]; this.loadDataPost=true;
            axios.post('/api/ReporteGrafico/getDataImgEnct',{encuesta:this.cveEncuesta.value,type:this.tipoGaleria})
                .then(r => {
                        $this.dataGaleria = r.data.Data;
                        $this.totalImg = r.data.Total;
                        $this.selectAll(false);
                        $this.selectAllBtn=true;
                        $this.$toastMessage('success',r.data.Mensaje,2000,'top-right');
                        var a = 0;
                        for (var z = 0; z < this.dataGaleria.length;z++)
                        {
                            if (a<=10){
                                $this.dataGaleria[z].ShowT = true;
                            }else{
                                $this.dataGaleria[z].ShowT = false;
                            }
                            a++;
                        }
                        $this.loadDataPost=false;

                    },error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        selectAll(type){
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                if (this.dataGaleria[z].ShowT){
                    this.dataGaleria[z].Checked = type;
                }
            }
            this.selectAllBtn = !this.selectAllBtn;
        },
        findIdx(cve){
            var a = 0;
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                if (this.dataGaleria[z].Id === cve){
                    a = z;
                }
            }
            return a;
        },
        closeModal(){
            this.$refs.closeModal.click();
        },
        ExportFile(){let $this = this;
            this.loadfileZip = true;
            this.$swal({
                title: "Espere",
                text: "Preparando zip " + this.tipoGaleria + " ATENEA México, por favor espere.",
                imageUrl: "/theme/img/loads/lavaload.gif",
                showConfirmButton: false
            });
            return axios({
                method: 'post',
                url: '/api/Download/Zip',
                responseType: 'arraybuffer',
                data: {
                    data:$this.dataGaleria,
                    galeria:this.tipoGaleria
                }
            }).then(r => {
                    let fileName = r.headers["content-disposition"].split("filename=")[1];
                    let blob = new Blob([r.data], { type: 'application/zip' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    $this.$swal.close();
                    $this.loadfileZip = false;
                    $this.$toastMessage('success','Zip descargado!',3000,'center');
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfileZip = false;
                }
            );
        },
        ExportFilePptx(){let $this = this;
            this.loadFilePptx = true;
            this.$swal({
                title: "Espere",
                text: "Preparando presentación " + this.tipoGaleria + " ATENEA México, por favor espere.",
                imageUrl: "/theme/img/loads/lavaload.gif",
                showConfirmButton: false
            });
            return axios({
                method: 'post',
                url: '/api/Download/Pptx',
                responseType: 'arraybuffer',
                data: {data:$this.dataGaleria,galeria:this.tipoGaleria}
            }).then(r => {
                    let fileName = r.headers["content-disposition"].split("filename=")[1];
                    let blob = new Blob([r.data], { type: 'application/pptx' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    $this.$swal.close();
                    $this.loadFilePptx = false;
                    $this.$toastMessage('success','Presentación descargada!',3000,'center');
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadFilePptx = false;
                }
            );
        },
    }

}
</script>

<style scoped>

</style>
