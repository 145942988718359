<template>
    <div>
        <v-select :multiple="false" :options="optionsEncuestas" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedEncuestas" @input="setCambio" :disabled="disabledr"
        :autoscroll="true"></v-select>
        <br><br>
        <div class="row">
            <div class="col">
                <div class="text-center">
                    <button class="btn ripple btn-primary btn-with-icon btn-block"
                            data-effect="effect-just-me" data-toggle="modal" href="#myModal121">
                        <i class="fas fa-eye"></i> VER ENCUESTAS
                    </button>
                    <button class="btn ripple btn-success btn-with-icon btn-block" v-if="actividad!=0 && loadfile===false" @click="getDetalleActividad()">
                        <i class="fas fa-download"></i> ENCUESTAS POR ACTIVIDAD
                    </button>
                    <div v-if="loadfile" class="text-center">
                        <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                        <p>Preparando información, espere...</p>
                    </div>
                    <a class="btn btn-info btn-with-icon btn-block"
                       href="/view/user/evaluacionDeEjecucion" target="_blank">
                        <i class="fas fa-book-open"></i> VER EVALUACIÓN DE EJECUCIÓN
                    </a>
                </div>
            </div>
            <div class="col" v-if="!showExtras && getPasados && selectedEncuestas != null">
                <div class="text-center">
                    <a class="modal-effect btn ripple btn-info btn-icon mr-3"
                       data-effect="effect-scale" data-toggle="modal" href="#modaldemo81" @click="getDetalle">
                        <i class="fas fa-cogs"></i>
                    </a>
                </div>
            </div>

            <div class="col" v-if="showExtras">
                <div class="text-center">
                    <filters-tipo-encuesta :placeholderm="'TIPO ENCUESTA'" :todaOpciones="showExtras" :disablE="disabledr"
                                           @cambioTipoEncuesta="setCambioTipo" ref="tipoEnct"></filters-tipo-encuesta>
                </div>
            </div>
            <div class="col" v-if="showExtras">
                <div class="text-center">
                    <v-select :multiple="false" :options="optionsEncuestasCanal" placeholder="SELECCIONA CANAL"
                              v-model="selectedEncuestasCanal" @input="setCambioTipo" :disabled="disabledr"
                              :autoscroll="true" key="120"></v-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="text-center">

                </div>
            </div>
            <div class="col"></div>
            <div class="col"></div>
        </div>

        <!-- modal show data -->
        <div class="modal" id="myModal121" tabindex="-1" >
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Encuestas Activas</h6>

                        <div v-if="loadfile" class="text-center">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando información, espere...</p>
                        </div>
                        <button type="button" class="btn btn-with-icon btn-danger" @click="downloadPdf" v-else>
                            <i class="fas fa-file-pdf"></i> Descargar</button>
                        <button id="close" aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-responsive table-striped">
                            <thead>
                            <th style="text-align: center">Pregunta</th>
                            <th style="text-align: center">Inicio</th>
                            <th style="text-align: center">Fin</th>
                            <th style="text-align: center">Categoria</th>
                            <th style="text-align: center">Tipo</th>
                            <th style="text-align: center">Avance</th>
                            <th style="text-align: center">Tiendas</th>
                            <th style="text-align: center">Imagen</th>
                            </thead>
                            <tbody>
                            <tr v-for="items in dataEnct">
                                <td class="text-justify">{{items.enct}}</td>
                                <td class="text-center">{{items.ini}}</td>
                                <td class="text-center">{{items.fin}}</td>
                                <td class="text-center">{{items.categoria}}</td>
                                <td class="text-center">{{items.tipo}}</td>
                                <td class="text-center"><b>{{items.avance}}%</b></td>
                                <td class="text-center"><b>{{items.tiendas}}</b></td>
                                <td class="text-center"><img :src="items.img" alt="img encuesta" class="img-thumbnail" v-show="items.img !=''"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal"> Cerrar <i class="fa fa-times"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal apps malisiosas -->
        <div class="modal" id="modaldemo81">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h6 class="modal-title">RESULTADO</h6><button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <encuestas-detalle ref="detalleEnct"></encuestas-detalle>
                    </div>
                    <div class="modal-footer">

                        <button class="btn ripple btn-secondary" data-dismiss="modal" type="button">Salir</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import encuestasDetalle from '../../app/Dashboard/Encuestas/detalle';
export default {
    components:{
        "encuestas-detalle":encuestasDetalle
    },
    name: "encuestasFiltro",
    props:{
        placeholderm:String,
        actividadlabel:String,
        disabledr:Boolean,
        showExtras:{required:false,type:Boolean,default:false},
        getPasados:{required:false, default:false, type:Boolean},
    },
    data(){
        return{
            selectedEncuestas:null,
            optionsEncuestas:[],
            selectedEncuestasCanal:{label:"TODOS",value:0},
            optionsEncuestasCanal:[],
            tipoConsulta:true,
            loadfile:false,
            dataEnct:[],
            dataResult:[],
            loadE:0,
            actividad:0,
            seguimiento:1,
            dataRegion:[],
            dataArea:[],
            dataCanal:[],
            dataLider:[],
            dataNegativas:[],
        }
    },
    mounted() {
        this.getEncuestas();
    },
    methods:{
        async getDetalle() {
            this.$refs.detalleEnct.vigencia = this.selectedEncuestas.Vigencia;
            this.$refs.detalleEnct.nameEnct = this.selectedEncuestas.Pregunta;
            this.$refs.detalleEnct.getData(this.selectedEncuestas);
            this.$refs.detalleEnct.getData(this.selectedEncuestas);
            await this.$refs.detalleEnct.getWeeks(this.selectedEncuestas);
        },
        async getDetalleActividad(){let $this=this;
            this.$toastMessage('info','Generando reporte, por favor espere!',2000,'center');
            this.dataRegion=[];this.dataArea=[];this.dataNegativas=[];this.dataCanal=[];this.dataLider=[];
            for (const value of this.optionsEncuestas) {
                const key = this.optionsEncuestas.indexOf(value);
                this.loadfile=true;
                await axios.post('/api/AdminCatalogos/dataEncuestasRegionActividad',{cve:{value:value.value},semanaFin:{label:"GENERALES",value:null,ini:null,selected:false}})
                    .then(r => {
                            if (r.data.Success){
                                $this.dataRegion.push(r.data.DataRegion);
                                $this.dataArea.push(r.data.DataAreas);
                                $this.dataNegativas.push( r.data.Tabla);
                                $this.dataCanal.push(r.data.DataCanal);
                                $this.dataLider.push(r.data.DataLideres);
                            }
                        },
                        error => {
                            $this.$toastMessage('error', 'Error: ' + error + ' Por favor envia este error al área de soporte!', 9000, 'center');
                        }
                    );
                this.loadfile=false;
            }
            await this.ExportFile();
        },
        async ExportFile(){let $this = this;
            this.$toastMessage('info','Generando archivo, por favor espere!',2000,'center');
            this.loadfile=true;
            await axios({
                method: 'post',
                url: '/api/Dashboard/exportLayoutToExcelActividad',
                responseType: 'arraybuffer',
                data: {
                    Region: this.dataRegion,
                    Area: this.dataArea,
                    Negativas: this.dataNegativas,
                    Canal: this.dataCanal,
                    Lider: this.dataLider,
                    Vigencia: "safd",
                    Pregunta: "sadf",
                    Actividad: this.actividadlabel,
                }}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'NIVELDEEJECUCIONDEACTIVIDADES.xlsx'
                    link.click()

                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                }
            );
            this.loadfile=false;
        },
        downloadPdf(){ let $this = this;
            $this.loadfile = true;
            axios({
                method: 'post',
                url: '/api/AdminCatalogos/dataEncuestasPdf',
                responseType: 'arraybuffer',
                data: {data: this.dataEnct}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'EncuestasAtenea.pdf'
                    link.click()
                    $this.loadfile = false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfile = false;
                }
            );
        },
        async getEncuestas(){ let $this = this;
            $this.optionsEncuestas = [];
            $this.dataEnct = [];
            $this.dataResult = [];
            if(this.showExtras){
                if (this.$refs.tipoEnct.selectedTipoEncuesta === null){
                    this.$toastMessage('error', 'Error: Selecciona un Tipo De Encuesta, para generar el reporte!', 4000, 'center');
                }
                else if (this.selectedEncuestasCanal === null){
                    this.$toastMessage('error', 'Error: Selecciona un Canal, para generar el reporte!', 4000, 'center');
                }
                else{
                    await axios
                        .post('/api/AdminCatalogos/getEncuestasFiltro',{tipo:this.tipoConsulta,
                            tipoEncuesta:this.$refs.tipoEnct.selectedTipoEncuesta,canal:this.selectedEncuestasCanal,
                            actividad:this.actividad, seguimiento: this.seguimiento
                        })
                        .then(function (r){
                            $this.optionsEncuestas = r.data.encuestas;
                            $this.dataEnct = r.data.encuestas;
                            $this.dataResult = r.data.Result;
                            if ($this.loadE === 0){
                                $this.optionsEncuestasCanal = r.data.Canales;
                            }
                            $this.loadE ++;
                            $this.$emit('cargaEncuesta');
                        });
                }
            }else{
                await axios
                    .post('/api/AdminCatalogos/getEncuestasFiltro',{tipo:this.tipoConsulta,actividad:this.actividad})
                    .then(function (r){
                        $this.optionsEncuestas = r.data.encuestas;
                        $this.dataEnct = r.data.encuestas;
                        $this.dataResult = r.data.Result;
                        if ($this.loadE === 0){
                            $this.optionsEncuestasCanal = r.data.Canales;
                        }
                        $this.loadE ++;
                        $this.$emit('cargaEncuesta');
                    });
            }

        },
        setCambio(){
            this.$emit('cambioEncuesta');
        },
        async setCambioTipo() {
            this.$emit('cambioEncuestaFiltrosInit');
            await this.getEncuestas();

            this.$emit('cambioEncuestaFiltros');

        }
    }
}
</script>

<style scoped>
::-webkit-scrollbar-thumb {
    background: rgba(10, 46, 225, 0.9) !important;
}
</style>
