<template>
    <div>
        <v-select :multiple="false" :options="optionsAnios" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedAnios" @input="setCambio"></v-select>
    </div>
</template>

<script>
import {options} from "../../../../public/theme/plugins/apexcharts/apexcharts";

export default {
    name: "selectAnioSemana",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedAnios:{},
            optionsAnios:[{label:"2021",value:2021},{label:"2022",value:2022},{label:"2023",value:2023},{label:"2024",value:2024}]
        }
    },
    mounted() {
        let $this = this;
        const xmas = new Date()
        let year = xmas.getFullYear()
        this.selectedAnios = { label: year, value: year }
        $this.optionsAnios = []
        let a = 1
        for(a; a <= 4; a ++){
            $this.optionsAnios.push({ label: year, value: year })
            year = year - 1;
        }

    },
    methods:{
        setCambio(){
            this.$emit('cambioAnio');
        }
    }
}
</script>

<style scoped>

</style>
