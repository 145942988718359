<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-md-3">
                <filters-general ref="filtersReport"></filters-general>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-center">
                        <button class="btn ripple btn-primary" @click="getReport">Generar Reporte </button>
                    </div>
                    <div class="col-md-4" v-if="showResult">
                        <button v-if="!loadfile"  class="btn btn-success" type="button" @click="ExportFile"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12">
                        <div v-if="loadfile" class="text-center">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando información, espere...</p>
                        </div>
                    </div>
                    <div class="col-md-12" v-show="showResult">
                        <v-client-table :data="tableDataExhibiciones" :columns="columnsExhibiciones" :options="optionsExhibiciones" class="table-responsive" ref="tablePromociones">
                            <div slot="Exhadc" slot-scope="props">
                                <b v-if="props.row.Exhadc===0">NO</b>
                                <b v-if="props.row.Exhadc===1">FERIA DE LA FAMILIA KIMBERLY CLARK</b>
                                <b v-if="props.row.Exhadc===2">SERVICIOS DE EXHIBICIÓN EVENFLO</b>
                                <b v-if="props.row.Exhadc===3">KLEENEX 100 AÑOS</b>
                                <b v-if="props.row.Exhadc===4">Kotex te inspira</b>
                            </div>
                            <div slot="Acc" slot-scope="props">
                                <i class="fas fa-edit tx-indigo" style="cursor: pointer;"
                                   @click="updateRegistro(props.row.Cverepexh)"
                                   data-effect="effect-super-scaled" data-toggle="modal" href="#modalChangeExhibicion"></i>
                            </div>
                        </v-client-table>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal effects -->
        <div class="modal" id="modalChangeExhibicion" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Actualizar Registro <i class="fas fa-edit"></i></h6>
                        <button ref="closeModal" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                        <button class="btn ripple btn-success text-right btn-with-icon" @click="saveEdit" type="button">Guardar &nbsp;&nbsp;<i class="fa fa-save"></i></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <div v-if="loadRegisterUpdate" class="text-center">
                                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                    <p>Preparando información, espere...</p>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <label>Tipo Exhibición:</label>
                                <select-tipoexhibicion :multi="false" :placeholderm="'EXHIBICION'" ref="newTpoExh" v-on:cambioExhibiciones="cambioTE"></select-tipoexhibicion>
                            </div>
                            <div class="col-md-3 text-center" v-if="!(Object.keys(registro).length === 0) && selected.value !== 3">
                                <label>Cantidad:</label>
                                <input type="number" min="0" max="2000" step="1" class="form-control" v-model="registro.Cantidad">
                            </div>
                            <div class="col-md-3 text-center" v-if="!(Object.keys(selected).length === 0)">
                                <div v-show="selected.value === 3">
                                    <label>Cabeceras:</label>
                                    <div>
                                        <p-radio class="p-default p-round p-thick" color="primary-o" :value="4" v-model="cabecera" name="cabe" @change.native="changeValor">4</p-radio>
                                        <p-radio class="p-default p-round p-thick" color="info-o" :value="5" v-model="cabecera" name="cabe" @change.native="changeValor">5</p-radio>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center" v-if="!(Object.keys(selected).length === 0)">
                                <div v-show="selected.value === 3">
                                    <label>Valor:</label>
                                    <div v-if="cabecera===4">
                                        <p-radio class="p-default p-round p-thick" color="success-o" :value=".25" v-model="valor" name="cabeVal">1</p-radio>
                                        <p-radio class="p-default p-round p-thick" color="success-o" :value=".50" v-model="valor" name="cabeVal">2</p-radio>
                                        <p-radio class="p-default p-round p-thick" color="success-o" :value=".75" v-model="valor" name="cabeVal">3</p-radio>
                                        <p-radio class="p-default p-round p-thick" color="success-o" :value="1" v-model="valor" name="cabeVal">4</p-radio>
                                    </div>
                                    <div v-else>
                                        <p-radio class="p-default p-round p-thick" color="success-o" :value=".2" v-model="valor" name="cabeVal">1</p-radio>
                                        <p-radio class="p-default p-round p-thick" color="success-o" :value=".4" v-model="valor" name="cabeVal">2</p-radio>
                                        <p-radio class="p-default p-round p-thick" color="success-o" :value=".6" v-model="valor" name="cabeVal">3</p-radio>
                                        <p-radio class="p-default p-round p-thick" color="success-o" :value=".8" v-model="valor" name="cabeVal">4</p-radio>
                                        <p-radio class="p-default p-round p-thick" color="success-o" :value="10" v-model="valor" name="cabeVal">5</p-radio>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-center">
                                {{idUpdate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal effects-->
    </div>
</template>

<script>
export default {
    name: "tablaExhibiciones",
    data(){
        return{
            loadfile:false,
            loadRegisterUpdate:false,
            showResult:false,
            loadingGetData: false,
            showData: false,
            columnsExhibiciones: ['Fecha','Semana','Area','Agencia','Rol','Usuario','Estado','Ciudad','Distrito','Canal','Cadena','Formato','Tienda','Kcmid','Fabricante','Departamento','Categoria','Marca','Codigo','Producto','Exhibicion','Cantidad','Exhadc','Comentario','Charolas','Cnt','Valor','Acc'],
            tableDataExhibiciones: [],
            optionsExhibiciones: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Exhadc','Tienda','Area','Formato','Canal','Cadena','Codigo','Producto','Exhibicion','Kcmid','Fabricante','Departamento','Categoria','Marca','Exhadc'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                    Exhadc: [ { text:'NO', id: 0 },{ id: 1, text: 'FERIA DE LA FAMILIA KIMBERLY CLARK' },
                        { id: 2, text: 'SERVICIOS DE EXHIBICIÓN EVENFLO' },{ id:3, text: 'KLEENEX 100 AÑOS' },
                        { id: 4, text: 'Kotex te inspira' },{ id:5, text: 'Toallitas Húmedas' }]
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                    Exhibicion: 'Tipo Exhibición',
                    Cnt: 'Cantidad',
                    Kcmid: 'KCMID',
                    Exhadc: 'Adicional',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Exhadc: 'text-center font-bold text-info',
                    Acc: 'text-center',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Cnt: 'text-center',
                    Charolas: 'text-center',
                    Valor: 'text-center',
                    Cantidad: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            idUpdate:0,
            registro:{},
            selected:{label:'',value:0},
            cabecera:4,
            valor:1,
            cantidad:[{valor:.2,cantidad:1},{valor:.4,cantidad:2},{valor:.6,cantidad:3},{valor:.8,cantidad:4},
                {valor:10,cantidad:5},{valor:.25,cantidad:1},{valor:.50,cantidad:2},{valor:.75,cantidad:3},
                {valor:1,cantidad:4}]
        }
    },
    mounted() {
        this.$refs.filtersReport.shows.trades = false;
        this.$refs.filtersReport.shows.respuestas = false;
        this.$refs.filtersReport.shows.texhibicion = true;
    },
    methods:{
        changeValor(){
            console.log(this.cabecera)
            if (this.cabecera === 4 ){
                this.valor=1;
            }
            else{
                this.valor=10;
            }
            console.log(this.valor)
        },
        cambioTE(){
            if (this.$refs.newTpoExh.selectedExhibicioness !== null){
                this.selected.value = this.$refs.newTpoExh.selectedExhibicioness.value;
                this.selected.label = this.$refs.newTpoExh.selectedExhibicioness.label;
            }
        },
        async saveEdit(){
            if (this.$refs.newTpoExh.selectedExhibicioness === null){
                this.$toastMessage('error',"Seleccione el tipo de exhibición, para realizar la actualización",4000,'top-right');
            }
            else{
                const resultado = this.tableDataExhibiciones.findIndex( data => data.Cverepexh === this.idUpdate );
                const resultadoV = this.cantidad.findIndex( data => data.valor === this.valor );
                this.tableDataExhibiciones[resultado].Cveentexh = this.$refs.newTpoExh.selectedExhibicioness.value;
                this.tableDataExhibiciones[resultado].Exhibicion = this.$refs.newTpoExh.selectedExhibicioness.label;
                if (this.$refs.newTpoExh.selectedExhibicioness.value === 3){
                    this.tableDataExhibiciones[resultado].Cantidad = 0;
                    this.tableDataExhibiciones[resultado].Charolas = this.cabecera;
                    this.tableDataExhibiciones[resultado].Cnt = this.cantidad[resultadoV].cantidad;
                    this.tableDataExhibiciones[resultado].Valor = this.cantidad[resultadoV].valor === 10 ? 1 : this.cantidad[resultadoV].valor;
                }else{
                    this.tableDataExhibiciones[resultado].Charolas = 0;
                    this.tableDataExhibiciones[resultado].Cnt = 0;
                    this.tableDataExhibiciones[resultado].Valor = 0;
                }
                var response = await this.$peticionesApi('/api/Exhibiciones/editRecord',{data:this.registro,
                    exhibicion:this.$refs.newTpoExh.selectedExhibicioness});
                if (response!==null){
                    this.$refs.closeModal.click();
                    this.$toastMessage('success',response.Mensaje,4000,'center');

                }
            }

        },
        async updateRegistro(id){
            this.idUpdate = id;
            this.loadRegisterUpdate = true;
            const resultado = await this.tableDataExhibiciones.find( data => data.Cverepexh === id );
            this.loadRegisterUpdate = false;
            this.registro = resultado;
            this.selected.label = this.registro.Exhibicion;
            this.selected.value = this.registro.Cveentexh;
            this.$refs.newTpoExh.selectedExhibicioness=this.selected;

            //console.log(resultado);
        },
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        async getReport(){
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else if (Date.parse(filters.timeEnd) < Date.parse(filters.timeInit)){
                this.$toastMessage('error',"Seleccione una fecha fin mayor a la fecha inicial!",4000,'center');
            }else{
                this.loadfile = true;
                this.showData = false;
                this.showResult = false;
                this.loadingGetData = true;
                this.tableDataExhibiciones = [];
                const response = await this.$peticionesApi('/api/Exhibiciones/Tabla', filters);
                if (response != null ){
                    this.loadfile = false;
                    this.showData = true;
                    this.tableDataExhibiciones = response.Data;
                    this.showResult = true;
                    this.loadingGetData = false;
                }

            }
        },
        async ExportFile(){
            this.loadfile = true;
            await this.$peticionesApiFilesDownload('/api/Exhibiciones/exportLayoutToExcel','ExhibicionesAtenea.xlsx',this.tableDataExhibiciones);
            this.loadfile=false;
        },
    }
}
</script>

<style scoped>

</style>
