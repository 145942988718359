<template>
    <div>
        <v-select :multiple="multiples" :options="optionsResposable" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedResponsable" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "departamentos",
    props:{
        placeholderm:String,
        multiples:{ required: true, default: false, type: Boolean}
    },
    data(){
        return{
            selectedResponsable: null,
            optionsResposable: [],
        }
    },
    mounted() {
        this.getResponsables();
    },
    methods:{
        getResponsables(){
            let $this = this;
            axios.post('/api/AdminCatalogos/responsablesSelect')
                .then(r => {
                        $this.optionsResposable = r.data;
                        //console.log(r);
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        setCambio(){
            this.$emit('cambioResponsable');
        }
    }
}
</script>

<style scoped>

</style>
