<template>
    <div>
        <encuestas-contenedor></encuestas-contenedor>
        <semana-exhibiciones :user-id="userId"></semana-exhibiciones>
        <!--<share-exhibiciones></share-exhibiciones>-->
        <tabs-faltantes></tabs-faltantes>
        <reporte-eficiencia></reporte-eficiencia>
        <br><br><br>
    </div>
</template>

<script>
export default {
    name: "contenedor",
    props: {
        userId: {
            required: true,
            default: 0,
            type: Number,
        }
    },
}
</script>

<style scoped>

</style>
