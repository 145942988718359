<template>
    <div>
        <v-select :multiple="false" :options="optionsMes" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedMes" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "anios",
    props:{placeholderm:String,},
    data(){
        return{
            selectedMes:{label:"Enero",value:1},
            optionsMes:[{label:"Enero",value:1},{label:"Febrero",value:2},{label:"Marzo",value:3},{label:"Abril",value:4},
                {label:"Mayo",value:5},{label:"Junio",value:6},{label:"Julio",value:7},{label:"Agosto",value:8},{label:"Septiembre",value:9},
                {label:"Octubre",value:10},{label:"Noviembre",value:11},{label:"Diciembre",value:12}]
        }
    },
    mounted() {
        let xmas95 = new Date();
        let month = xmas95.getMonth()
        if (xmas95.getDate() <= 3){
            xmas95 = xmas95.setDate(xmas95.getDate() - 4)
            month = xmas95.getMonth();
        }
        let mesActual = new Intl.DateTimeFormat('es-ES', { month: 'long'}).format(new Date());
        this.selectedMes = { label: mesActual.toLocaleUpperCase(), value: month + 1 }
    },
    methods:{
        setCambio(){
            this.$emit('cambioMes');
        }

    }
}
</script>

<style scoped>

</style>
