<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <label><b>Canales:</b></label>
                <select-canalesN placeholderm="Canal" v-on:cambioCanal="changeCanal" ref="selectCanal"></select-canalesN>
                <label><b>Cadenas:</b></label>
                <select-cadenasN placeholderm="Cadena" v-on:cambioCadena="changeCadena" ref="selectCadena"></select-cadenasN>
                <label><b>Formatos:</b></label>
                <select-formatosN placeholderm="Formato" v-on:cambioFormato="changeFormato" ref="selectFormato"></select-formatosN>
                <label class="typo__label"><b>Clasificación:</b></label>
                <select-clasificacion :placeholderm="'Clasificación'" v-on:cambioClasificacion="getClasificacion" ref="selectClasificacion"></select-clasificacion><br>
                <label class="typo__label"><b>Tipo Exhibición:</b></label>
                <select-tipoexhibicion :placeholderm="'Tipo de Exhibición'" v-on:cambioExhibiciones="getTipoExhibicion" ref="selectTipoExhibicion"></select-tipoexhibicion><br>
                <label class="typo__label"><b>Áreas:</b></label>
                <select-areas :placeholderm="'Área'" v-on:cambioArea="getArea" ref="selectArea"></select-areas><br>
                <label class="typo__label"><b>Año:</b></label>
                <select-anios :placeholderm="'Año'" v-on:cambioAnio="getAnio" ref="selectAnio"></select-anios><br>
                <div class="row">
                    <div class="col-md-12"><label class="rdiobox"><input name="rdio" type="radio" checked :value="1" v-model="filters.tipo"> <span>Mensual</span></label></div>
                    <div class="col-md-4"><label class="rdiobox"><input name="rdio" type="radio" :value="2" v-model="filters.tipo"> <span>Semanal</span></label></div>
                    <div class="col-md-4">
                        <select-semanas :placeholderm="'Semana'" v-on:cambioSemana="getSemanaI" ref="selectSemanaI"></select-semanas>
                    </div>
                    <div class="col-md-4">
                        <select-semanas :placeholderm="'Semana'" v-on:cambioSemana="getSemanaF" ref="selectSemanaF"></select-semanas>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn ripple btn-primary btn-block" @click="getReport">Generar Reporte <i class="fas fa-cogs"></i></button>
                    </div>
                    <div class="col-md-12">
                        <button class="btn ripple btn-info btn-block" data-effect="effect-super-scaled" data-toggle="modal" href="#myModal4" @click="getCalendar">Ver Calendario <i class="fas fa-calendar-alt"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-12">
                        <div v-if="loadingGetData" class="text-center">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando información, espere...</p>
                        </div>
                        <div v-if="showReport">
                            <div class="text-center">
                                <button v-if="!loadingGetData"  class="btn btn-success" type="button" @click="ExportFile"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                            </div>
                            <table class="table table-bordered mg-b-0 table-hover table-responsive">
                                <thead>
                                <tr>
                                    <th v-for="items in tableReport">
                                        <span v-html="items"></span>
                                    </th>
                                </tr>
                                </thead>
                                    <tr class="bg-success">
                                        <td v-for="items in tableVisitadas" class="text-center">
                                            <span v-html="items"></span>
                                        </td>
                                    </tr>
                                    <tr v-for="(items, index) in tableCategorias">
                                        <td>
                                            <span v-html="items"></span>
                                        </td>
                                        <td v-for="ite in tableCuerpo[index]" class="text-center">
                                            <span v-html="ite"></span>
                                        </td>
                                    </tr>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL -->
        <div class="modal" id="myModal4" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h4 class="modal-title"><span>Calendario Semanal</span></h4>
                        <button ref="closeModal" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered mg-b-0 table-hover">
                            <thead>
                            <tr>
                                <th>Mes</th>
                                <th>Semana</th>
                                <th>Fecha Inicio</th>
                                <th>Fechan Fin</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="items in tableCalendar">
                                    <td>{{items.Mes}}</td>
                                    <td>{{items.Semana}}</td>
                                    <td>{{items.Fi}}</td>
                                    <td>{{items.Ff}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "report",
    data(){
        return{
            loadingGetData: false,
            showReport: false,
            filters:{
                canales:null,
                cadenas:null,
                formatos:null,
                clasificacion:null,
                tipoExhibicion:null,
                area:null,
                anio:null,
                tipo:1,
                semanaI:null,
                semanaF:null,
            },
            tableCalendar:[],
            tableReport:[],
            tableVisitadas:[],
            tableCuerpo:[],
            tableCategorias:[],
            tableReportE:[],
            tableVisitadasE:[],
            tableCuerpoE:[],
            tableCategoriasE:[],

        }
    },
    mounted(){
        this.filters.anio = this.$refs.selectAnio.selectedAnios;
        this.getClasificacion();

    },
    methods:{
        getData(){let $this = this;  this.loadingGetData = true; this.showReport = false;
            axios.post('/api/Exhibiciones/reporteSM',{data:this.filters})
                .then(r => {
                    if (r.data.Success){
                        $this.tableReport = r.data.Header;
                        $this.tableVisitadas = r.data.Visitadas;
                        $this.tableCuerpo = r.data.Cuerpo;
                        $this.tableCategorias = r.data.Categorias;
                        $this.tableReportE = r.data.HeaderE;
                        $this.tableVisitadasE = r.data.VisitadasE;
                        $this.tableCuerpoE = r.data.CuerpoE;
                        $this.tableCategoriasE = r.data.CategoriasE;
                        $this.loadingGetData = false;
                        $this.showReport = true;
                    }
                    },
                    error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    }
                );
        },
        getCalendar(){let $this = this;
            axios.post('/api/Exhibiciones/Calendar',{year:this.filters.anio})
                .then(r => {
                    if (r.data.Success){
                        $this.tableCalendar = r.data.Data;
                    }
                    },
                    error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    }
                );
        },
        changeCanal(){
            var canal = this.$refs.selectCanal.selectedCanales;
            var selectTodas = false;
            if (canal.length>0){
                canal.forEach(function (value,key){
                    if (canal[key].value === 0){
                        selectTodas = true;
                    }
                });
                var cadena = this.$refs.selectCadena;
                if (selectTodas){
                    cadena.canal = this.$refs.selectCanal.optionsCanales;
                    this.filters.canales = this.$refs.selectCanal.optionsCanales;
                    cadena.getCadenas();
                }
                else{
                    cadena.canal = canal;
                    this.filters.canales = canal;
                    cadena.getCadenas();
                }
            }
            else{
                this.filters.canales = null;
            }
        },
        changeCadena(){
            var cadenas = this.$refs.selectCadena.selectedCadenas;
            var selectTodas = false;
            if (cadenas.length>0){
                cadenas.forEach(function (value,key){
                    if (cadenas[key].value === 0){
                        selectTodas = true;
                    }
                });
                var formato = this.$refs.selectFormato;
                if (selectTodas){
                    formato.cadenas = this.$refs.selectCadena.optionsCadenas;
                    this.filters.cadenas = this.$refs.selectCadena.optionsCadenas;
                    formato.getDataFormatos();
                }
                else{
                    formato.cadenas = cadenas;
                    this.filters.cadenas = cadenas;
                    formato.getDataFormatos();
                }
            }
            else{
                this.filters.cadenas = null;
            }
        },
        changeFormato(){
            var formatos = this.$refs.selectFormato.selectedFormatos;
            var selectTodas = false;
            if (formatos.length>0){
                formatos.forEach(function (value,key){
                    if (formatos[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.formatos = this.$refs.selectFormato.optionsFormatos;
                }
                else{
                    this.filters.formatos = formatos;
                }
            }
            else{
                this.filters.formatos = null;
            }
        },
        getClasificacion(){
            this.filters.clasificacion = this.$refs.selectClasificacion.selectedClasificacion;
            this.$refs.selectTipoExhibicion.tipo = this.$refs.selectClasificacion.selectedClasificacion.value;
            this.$refs.selectTipoExhibicion.getExhibiciones();
        },
        getTipoExhibicion(){
            var exhibicion = this.$refs.selectTipoExhibicion.selectedExhibicioness;
            var selectTodas = false;
            if (exhibicion.length>0){
                exhibicion.forEach(function (value,key){
                    if (exhibicion[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.tipoExhibicion = this.$refs.selectTipoExhibicion.optionsExhibiciones;
                }
                else{
                    this.filters.tipoExhibicion = exhibicion;
                }
            }
            else{
                this.filters.tipoExhibicion = null;
            }
        },
        getArea(){
            this.filters.area = this.$refs.selectArea.selectedAreas;
        },
        getAnio(){
            this.filters.anio = this.$refs.selectAnio.selectedAnios;
        },
        getSemanaI(){
            this.filters.semanaI = this.$refs.selectSemanaI.selectedSemanas;
        },
        getSemanaF(){
            this.filters.semanaF = this.$refs.selectSemanaF.selectedSemanas;
        },
        getReport(){
            this.filters.formatos = this.$refs.selectFormato.selectedFormatos;
            this.getData();
        },
        ExportFile(){let $this = this;
            this.loadfile = true;
            axios({
                method: 'post',
                url: '/api/Exhibiciones/exportLayoutToExcelDashboard',
                responseType: 'arraybuffer',
                data: {
                    tipo: this.filters.tipo,
                    header: this.tableReportE,
                    visitadas: this.tableVisitadasE,
                    cuerpo: this.tableCuerpoE,
                    categorias: this.tableCategoriasE,
                }}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'ExhibicionesAteneaDashboard.xlsx'
                    link.click()
                    $this.loadfile=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfile=false;
                }
            );
        },
    }
}
</script>

<style scoped>
.tableFixHead {
    overflow-y: auto;
    height: 106px;
}
.tableFixHead thead th {
    position: sticky;
    top: 0;
}
table {
    border-collapse: collapse;
    width: 100%;
}
</style>
