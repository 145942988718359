<template>
    <div>
        <h1>medidas</h1>
    </div>
</template>

<script>
export default {
    name: "productosMedidas"
}
</script>

<style scoped>

</style>
