<template>
    <div class="border">
        <br><br>
        <div class="row">
            <div class="col-md-12 text-center">
                <h2><span class="text-primary">EXHIBICIONES</span></h2>
                <br>
                <b>Tipo Reporte:</b>&nbsp;&nbsp;&nbsp;&nbsp;
                <p-radio class="p-default p-round" name="radio1" style="font-size: 16px" @click.native="abrir" checked color="success">Semanal</p-radio>
                <!-- or if it's only p-default and p-round you can leave out class attribute -->
                <p-radio name="radio1" style="font-size: 16px" @click.native="abrir" color="success">Mensual</p-radio>
                <p-check class="p-icon p-smooth" color="success-o" @click.native="changeTiendasBliendadas">
                    <i slot="extra" class="icon fa fa-store"></i>
                    Tiendas Blindadas
                </p-check>
                <p-radio v-show="tiendasBlindadas" class="p-default p-round" name="radio33" style="font-size: 16px" @click.native="tdaBlindada(false)" checked color="info" :value="false">
                    CATEGORÍAS
                </p-radio>
                <!-- or if it's only p-default and p-round you can leave out class attribute -->
                <p-radio v-show="tiendasBlindadas"  name="radio33" style="font-size: 16px" @click.native="tdaBlindada(true)" color="info" :value="true">
                    PROTECCIÓN FEMENINA
                </p-radio>
                <button v-show="tiendasBlindadas && validaUsuarioAdmin" class="modal-effect btn btn-primary dim text-center" type="button" @click="getReportDetalle"
                        data-effect="effect-super-scaled" data-toggle="modal" href="#tiendasBlindadas">
                    <i class="fa fa-cogs"> Tiendas Blindadas</i>
                </button>
            </div>
            <div class="col-md-12">
                <br>
                <div class="row">
                    <div class="col-md-2">
                        <label><b>Año:</b></label><br>
                        <select-anios ref="selectedAnio" :placeholderm="'Año'"></select-anios>
                    </div>
                    <div class="col-md-2">
                        <label class="typo__label"><b>Responsable:</b></label>
                        <select-responsablesN :placeholderm="'Responsable'" ref="selectResponsable" v-on:cambioResponsable="changeResponsable" :multiples="false"></select-responsablesN>
                    </div>
                    <div class="col-md-2">
                        <label class="typo__label"><b>Canales:</b></label>
                        <select-canalesN :placeholderm="'Canal'" ref="selectCanal" v-on:cambioCanal="changeCanal"></select-canalesN>
                    </div>
                    <div class="col-md-2">
                        <label class="typo__label"><b>Cadenas:</b></label>
                        <select-cadenas-ex placeholderm="Cadena" v-on:cambioCadena="changeCadena" ref="selectCadena" :desa="false"></select-cadenas-ex>
                    </div>
                    <div class="col-md-2">
                        <label><b>Categoria:</b></label><br>
                        <categorias-select :mltp="true" :placeholderm="'Categoria'" ref="Categoria"></categorias-select>
                    </div>
                    <div class="col-md-2 text-center"><br>
                        <button class="btn btn-primary dim text-center" type="button" @click="getReportDetalle">
                            <i class="fa fa-cogs"> Generar Reporte</i>
                        </button>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-12">
                <div v-if="showLoad" class="text-center">
                    <img :src="'/theme/img/loads/spiralload.gif'" alt="carga reporte" height="40">
                    <p>Consultando base de datos, por favor espere...</p>
                </div>
                <div v-else class="text-center">
                    <!--<highcharts :options="chartOptions" style="height: 400px; min-width: 100%"></highcharts>
                    <br>-->
                    <button class="btn btn-outline btn-success dim" type="button"
                            @click="showImpacto = false; showImpulso=false; showTotales=true">
                        <i class="fa fa-cogs"></i> TOTALES
                    </button>
                    <button class="btn btn-outline btn-info dim" type="button"
                            @click="showImpacto = true; showImpulso=false; showTotales=false">
                        <i class="fa fa-boxes"></i> ALTO IMPACTO
                    </button>
                    <button class="btn btn-outline btn-warning dim" type="button"
                            @click="showImpacto = false; showImpulso=true; showTotales=false">
                        <i class="fa fa-box"></i> IMPULSO
                    </button>
                    <button class="btn btn-outline btn-outline-success dim" type="button"
                            @click="getFileExhibiciones">
                        <i class="fa fa-file-excel"></i> REPORTE
                    </button>
                    <br>
                    <highcharts :options="chartOptionsTotales" style="height: 400px; min-width: 100%" v-show="showTotales"></highcharts>
                    <highcharts :options="chartOptionsImpacto" style="height: 400px; min-width: 100%" v-show="showImpacto"></highcharts>
                    <highcharts :options="chartOptionsImpulso" style="height: 400px; min-width: 100%" v-show="showImpulso"></highcharts>
                    <div class="row" v-if="showSemanal">
                        <div class="col-md-4"></div>
                        <div class="col-md-4 text-center">
                            <button class="btn btn-rounded btn-secondary" v-if="showTodos" @click="showTodo(true)">MOSTRAR TODO <i class="fa fa-eye"></i></button>
                            <button class="btn btn-rounded btn-indigo" v-else @click="showTodo(false)">MOSTRAR NORMAL <i class="fa fa-eye"></i></button>
                        </div>
                        <div class="col-md-4 text-right">
                            <h5 class="font-weight-bold"><span >Suma real de exhibiciones</span></h5>
                        </div>
                    </div>
                    <div class="row" v-if="!showSemanal">
                        <div class="col-md-4"></div>
                        <div class="col-md-4 text-center">
                        </div>
                        <div class="col-md-4 text-right">
                            <h5 class="font-weight-bold"><span>Promedio entre las semanas del mes</span></h5>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div style="display:none" clas="row">
            <div class="col-md-12">
                <div class="text-center">
                    <div class="col-md-6">
                        <label class="typo__label">Productos:</label>
                        <multiselect v-model="filters.productos" :options="optionsProductosReal" :multiple="false" :close-on-select="true"
                                     :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectProducto">
                            <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                    {{ selectTodosProductos ? 'Todos' : values.length > 1 ? values.length + ' productos' : values.length + ' producto' }}
                                </span>
                            </template>
                        </multiselect>
                    </div>
                </div>
                <br><br>
                <highcharts :options="chartOptionsPto"></highcharts>
                <br><br>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal" id="tiendasBlindadas" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Tiendas Blindadas <i class="icon fa fa-store"></i></h6>
                        <button ref="closeModalEdit" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <p-radio class="p-default p-round" name="radio333" style="font-size: 16px" @click.native="updateTiendaBlindada" checked color="success">
                                    LAYOUT
                                </p-radio>
                                <!-- or if it's only p-default and p-round you can leave out class attribute -->
                                <p-radio  name="radio333" style="font-size: 16px" @click.native="updateTiendaBlindada" color="danger">
                                    MANUAL
                                </p-radio>
                            </div>
                        </div>
                        <div class="row" v-show="tiendaManualLayot">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <button class="btn btn-outline btn-success dim" type="button"><i class="icon fa fa-file-excel"></i> Descarga Layout</button>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                        <br>
                        <div class="row" v-show="tiendaManualLayot">
                            <div class="col-md-2"></div>
                            <div class="col-md-8">
                                <h5 class="text-info">Cargar archivo de tiendas blindadas</h5>
                                <div class="form-group">
                                    <label class="control-label">Archivo</label>
                                    <div class="">
                                        <input type="file"  id="archivo" name="archivo" class="form-control" required="" ref="fileRutas" v-on:change="handleFileUpload()">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                        <div class="row" v-show="!tiendaManualLayot">
                            <div class="col-md-12">
                                <p-radio class="p-default p-round" :value="1" name="radioGrupo" style="font-size: 16px" v-model="tipoGrupo" checked color="success">
                                    CATEGORÍAS
                                </p-radio>
                                <!-- or if it's only p-default and p-round you can leave out class attribute -->
                                <p-radio  name="radioGrupo" :value="2" style="font-size: 16px" v-model="tipoGrupo" color="danger">
                                    PF
                                </p-radio>
                                <br>
                                <select-tiendas
                                :placeholderm="'tienda'"
                                ref="selectTiendaBlindada"
                                @cambioTienda="addTienda"></select-tiendas>
                            </div>
                            <br>
                            <v-server-table
                                :data="tableTiendasBlindadas"
                                :columns="columnsTiendasBlindadas"
                                :options="optionsTiendasBlindadas"
                                class="table-responsive"
                                ref="tableTiendasBlindadas">
                                <div slot="Acciones" slot-scope="props">
                                    <span class="badge badge-danger ripple" @click="deleteTdaBlindada(props.row.Id)">Eliminar</span>
                                </div>
                            </v-server-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts'
import selectTiendas from './../../../components/generales/tiendas.vue'

function createChartESNormal(vm){
    return{
        chart: {
            type: 'column',
        },
        title: {
            text: 'REPORTE EXHIBICIONES POR SEMANA'
        },
        xAxis: {
            title: {
                text: 'Semanas'
            },
            categories: [],
            min: 0,
            max:44
        },
        scrollbar: {
            enabled: true
        },
        yAxis: {
            min: 0,
                title: {
                text: 'Total Exhibiciones'
            },
            stackLabels: {
                enabled: true,
                    style: {
                    fontWeight: 'bold',
                        color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        legend: {
            align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x} </b><br/>',

        },
        plotOptions: {
            column: {
                stacking: 'normal',
                    dataLabels: {
                    enabled: true
                }
            },
            series: {
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        mapNavigation: {
            enableMouseWheelZoom: true
        },
        series: [
            {
                name: 'Impulso', color: '#de945b',
                data: []
            },
            {
                name: 'Alto Impacto', color: '#4c8bde',
                data: []
            },
        ],
        exporting: {
            sourceWidth: 3200,
            //sourceHeight: 200,
            //scale: 4,// (default)
            chartOptions: {
                subtitle: "atenea.grupopromociones.com"
            }
        }
    }
}
function createChartESImpacto(vm){
    return{
        chart: {
            type: 'column',
            zoomType: 'x'
        },
        title: {
            text: 'REPORTE SEMANAL'
        },
        xAxis: {
            title: {
                text: 'Semanas',
                offset:0,
                y: 6,
                x:-60,
                align: 'low'
            },
            categories: [],
            min: 0,
            max:44
        },
        scrollbar: {
            enabled: true
        },
        yAxis: {
            min: 0,
                title: {
                text: 'Total Exhibiciones'
            },
            stackLabels: {
                enabled: true,
                    style: {
                    fontWeight: 'bold',
                        color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'dark'
                }
            }
        },

        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',

        },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
            series: {
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        mapNavigation: {
            enableMouseWheelZoom: true
        },
        series: [
            {
                name: 'Alto Impacto', color: '#4c8bde',
                data: []
            },
            {
                type: 'spline',
                name: 'Alto Impacto ' + vm.pasado,
                data: [],
                marker: {
                    symbol: 'url(https://atenea.grupopromociones.com/img/circle.png)',
                    fillColor: '#ffffff',
                },
                dataLabels: {
                    enabled: true,

                    style: {
                        textOutline: 0,
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'dark'
                    }
                }
            },
        ],
        exporting: {
            sourceWidth: 3200,
            //sourceHeight: 200,
            //scale: 4,// (default)
            chartOptions: {
                subtitle: "atenea.grupopromociones.com"
            }
        }
    }
}
function createChartESImpulso(vm){
    return{
        chart: {
            type: 'column',
            zoomType: 'x'
        },
        title: {
            text: 'REPORTE SEMANAL'
        },
        xAxis: {
            title: {
                text: 'Semanas',
                offset:0,
                y: 6,
                x:-60,
                align: 'low'
            },
            categories: [],
            min: 0,
            max:44
        },
        scrollbar: {
            enabled: true
        },
        yAxis: {
            min: 0,
                title: {
                text: 'Total Exhibiciones'
            },
            stackLabels: {
                enabled: true,
                    style: {
                    fontWeight: 'bold',
                        color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'dark'
                }
            }
        },

        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',

        },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
            series: {
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        mapNavigation: {
            enableMouseWheelZoom: true
        },
        series: [
            {
                name: 'Impulso', color: '#de945b',
                data: []
            },
            {
                type: 'spline',
                name: 'Impulso ' + vm.pasado,
                data: [],
                marker: {
                    symbol: 'url(https://atenea.grupopromociones.com/img/circle.png)',
                    fillColor: '#ffffff',
                },
                dataLabels: {
                    enabled: true,

                    style: {
                        textOutline: 0,
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'dark'
                    }
                }
            }
        ],
        exporting: {
            sourceWidth: 3200,
            //sourceHeight: 200,
            //scale: 4,// (default)
            chartOptions: {
                subtitle: "atenea.grupopromociones.com"
            }
        }
    }
}
function createChartESTotaltes(vm){
    return{
        chart: {
            type: 'column',
            zoomType: 'x'
        },
        title: {
            text: 'REPORTE SEMANAL'
        },
        xAxis: {
            title: {
                text: 'Semanas',
                offset:0,
                y: 6,
                x:-60,
                align: 'low'
            },
            categories: [],
            min: 0,
            max: 44
        },
        scrollbar: {
            enabled: true
        },
        yAxis: {
            min: 0,
                title: {
                text: 'Total Exhibiciones'
            },
            stackLabels: {
                enabled: true,
                    style: {
                    fontWeight: 'bold',
                        color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'dark'
                }
            }
        },
        tooltip: {
            headerFormat: '<b>{point.x} </b><br/>',
        },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
            series: {
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        mapNavigation: {
            enableMouseWheelZoom: true
        },
        series: [
            {
                name: 'Impulso', color: '#de945b',
                data: []
            },
            {
                name: 'Alto Impacto', color: '#4c8bde',
                data: []
            },
            {
                type: 'spline',
                name: 'Total ' + vm.pasado,
                data: [],
                marker: {
                    symbol: 'url(https://atenea.grupopromociones.com/img/circle.png)',
                    fillColor: '#ffffff',
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        textOutline: 0,
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'dark'
                    }
                }
            },
        ],
        exporting: {
            sourceWidth: 3200,
            //sourceHeight: 200,
            //scale: 4,// (default)
            chartOptions: {
                subtitle: "atenea.grupopromociones.com"
            }
        }
    }
}
function createChartPTO(vm){
    return{
        chart: {
            type: 'column',
        },
        scrollbar:{
            enabled:true
        },
        title: {
            text: 'REPORTE EXHIBICIONES POR SEMANA POR PRODUCTO'
        },
        xAxis: {
            title: {
                text: 'Semanas'
            },
            categories: [],
            min: 0,
            max:44
        },

        yAxis: {
            min: 0,
                title: {
                text: 'Total Exhibiciones'
            },
            stackLabels: {
                enabled: true,
                    style: {
                    fontWeight: 'bold',
                        color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        legend: {
            align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',

        },
        plotOptions: {
            column: {
                stacking: 'normal',
                    dataLabels: {
                    enabled: true
                }
            },
            series: {
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        mapNavigation: {
            enableMouseWheelZoom: true
        },
        series: [
            {
                name: 'IMPACTO', color: '#de945b',
                data: []
            },
            {
                name: 'IMPULSO', color: '#4c8bde',
                data: []
            },
            {
                type: 'spline',
                name: 'PRODUCTO 2020',
                data: [],
                marker: {
                    symbol: 'url(https://atenea.grupopromociones.com/img/circle.png)',
                    fillColor: 'white',
                },
            }
        ],
        exporting: {
            sourceWidth: 3200,
            //sourceHeight: 200,
            //scale: 4,// (default)
            chartOptions: {
                subtitle: "atenea.grupopromociones.com"
            }
        }
    }
}
export default {
    name: "reporteExhibicionesSemana",
    components:{
        selectTiendas
    },
    props:{
        userId: {
            required: true,
            default: 0,
            type: Number
        }
    },
    computed:{
        validaUsuarioAdmin(){
            return this.usersAdmins.includes(this.userId);
        }
    },
    data(){
        return{
            tableTiendasBlindadas: [],
            tipoGrupo: 1,
            usersAdmins:[ 2, 30001, 34582, 35973, 34437 ],
            columnsTiendasBlindadas: ['Id', 'Kcmid', 'Tienda', 'Tipo', 'Acciones'],
            optionsTiendasBlindadas: {
                async requestFunction(data) {
                    //console.log(data)
                    //data.typeUsers = 1
                    //console.log(data)
                    return await axios.post('/api/Dashboard/tiendasBlindadas', {
                        params: data,
                    })
                        .then(function (data) {
                            //console.log(data)
                            return data
                        })
                        .catch(function (e) {
                            console.log(e)
                        })
                },
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Kcmid', 'Tienda', 'Tipo'],
                sortable: ['Kcmid', 'Tienda', 'Tipo'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                    Tipo: [
                        {
                            id: 1,
                            text: 'CATEGORÍA',
                        },
                        {
                            id: 2,
                            text: 'PF',
                        }
                    ]
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    FechaInicio: 'Fecha Inicio',
                    FechaTermino: 'Fecha Termino',
                    Image: 'Imagen',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Pdf: 'text-center',
                    Fecha: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                }
            },
            showLoad:true,
            tiendaManualLayot:true,
            showSemanal:true,
            showTodos:true,
            value6: new Date(),
            selectTodosCanales:false,
            catPF: false,
            tiendasBlindadas: false,
            selectTodasCadenas:false,
            selectTodosProductos:false,
            showTotales:true,
            showImpacto:false,
            showImpulso:false,
            optionsCanalesReal:[],
            optionsProductosReal:[],
            optionsCadena:[],
            optionsCadenaReal:[],
            filters:{
                canales:null,
                canalesReal:null,
                cadenas:null,
                cadenasReal:null,
                productos:null,
                productosReal:null,
                responsables:null,
            },
            category:'',
            oculta:false,
            varsi:[],
            pasado: 0,
            chartOptionsTotales: createChartESTotaltes(this),
            chartOptionsImpulso: createChartESImpulso(this),
            chartOptionsImpacto: createChartESImpacto(this),
            chartOptions: createChartESNormal(this),
            chartOptionsPto: createChartPTO(this),
            typeReport:true,
            dataAltoImpactoTiendas: [],
            dataImpulsoTiendas: []
        }
    },
    async mounted() {
        //this.getProductos();
        this.$refs.selectTiendaBlindada.getTiendas()
        await this.getReportDetalle();
    },
    methods: {
        async addTienda(){
            if (this.$refs.selectTiendaBlindada.selectedTiendas !== null){
                let $this = this
                await axios.post('/api/Dashboard/addTiendaBlindada', {
                    grupo: this.tipoGrupo,
                    tienda: this.$refs.selectTiendaBlindada.selectedTiendas.value,
                }).then(function ({data}) {
                    if (data.Success){
                        $this.$toastMessage('success', data.Mensaje, 4000, 'center')
                        $this.$refs.tableTiendasBlindadas.getData()
                    }
                    else {
                        $this.$toastMessage('warning', data.Mensaje, 4000, 'center')
                    }
                }).catch((e) => {
                    $this.$toastMessage('error', 'Error al agregar la tienda blindada: ' + e, 6000, 'center')
                })
            }
        },
        changeTiendasBliendadas(){
            this.tiendasBlindadas = !this.tiendasBlindadas
        },
        showTodo(type){
            if (type){
                this.chartOptionsTotales.xAxis.min = 0;
                this.chartOptionsTotales.xAxis.max = 52;
                this.chartOptionsImpulso.xAxis.min = 0;
                this.chartOptionsImpulso.xAxis.max = 52;
                this.chartOptionsImpacto.xAxis.min = 0;
                this.chartOptionsImpacto.xAxis.max = 52;
            }
            else{
                this.chartOptionsTotales.xAxis.min = 26;
                this.chartOptionsTotales.xAxis.max = 46;
                this.chartOptionsImpulso.xAxis.min = 26;
                this.chartOptionsImpulso.xAxis.max = 46;
                this.chartOptionsImpacto.xAxis.min = 26;
                this.chartOptionsImpacto.xAxis.max = 46;
            }
            this.showTodos = !this.showTodos;
        },
        changeCanal(){
            var canal = this.$refs.selectCanal.selectedCanales;
            var selectTodas = false;
            if (canal.length>0){
                this.$refs.selectCadena.selectedCadenas = [];
                canal.forEach(function (value,key){
                    if (canal[key].value === 0){
                        selectTodas = true;
                    }
                });
                var cadena = this.$refs.selectCadena;
                if (selectTodas){
                    cadena.canal = this.$refs.selectCanal.optionsCanales;
                    this.filters.canalesReal = this.$refs.selectCanal.optionsCanales;
                    cadena.getCadenas();
                }
                else{
                    cadena.canal = canal;
                    this.filters.canalesReal = canal;
                    cadena.getCadenas();
                }
            }
            else{
                this.filters.canalesReal = null;
                this.filters.cadenasReal = null;
                this.$refs.selectCadena.selectedCadenas = [];
            }
        },
        changeResponsable(){
            this.filters.responsables = this.$refs.selectResponsable.selectedResponsable;
        },
        changeCadena(){
            var cadenas = this.$refs.selectCadena.selectedCadenas;
            var selectTodas = false;
            if (cadenas.length>0){
                cadenas.forEach(function (value,key){
                    if (cadenas[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.cadenasReal = this.$refs.selectCadena.optionsCadenas;
                }
                else{
                    this.filters.cadenasReal = cadenas;
                }
            }
            else{
                this.filters.cadenasReal = null;
            }
        },
        abrir(){
            this.typeReport =  !this.typeReport;
            this.showSemanal =  !this.showSemanal;
        },
        tdaBlindada(value){
            this.catPF =  value
            if (this.catPF){
                this.$refs.Categoria.fiterCategoriasPF()
            }
            else{
                this.$refs.Categoria.showAll()
            }
        },
        updateTiendaBlindada(){
            this.tiendaManualLayot =  !this.tiendaManualLayot;
        },
        async deleteTdaBlindada(cveDelete){
            let $this = this
            await axios.post('/api/Dashboard/tiendasBlindadasDelete', {
                cveDelete: cveDelete,
            }).then(r => {
                $this.$refs.tableTiendasBlindadas.getData()
                $this.$toastMessage('success',r.data.Mensaje,6000,'center');
            })
        },
        getProductos(){let $this = this;
            axios.post('/api/AdminCatalogos/productos')
                .then(r => {
                        if (r.data.Success){
                            $this.optionsProductos = r.data.Data;$this.optionsProductosReal = r.data.Data;
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectCanal(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.canales = [];
                    $this.filters.canales.push({
                        "name":'TODOS',
                        "value":0,
                    })
                }else{
                    $this.filters.canalesReal = $this.filters.canales;
                }
            });
            if (selectedOption.length === 0){ $this.filters.canalesReal = $this.filters.canales; $this.optionsCadenaReal = $this.optionsCadena; }
            else{$this.optionsCadenaReal = [];}
            $this.filters.canales.forEach(function (value,key){
                $this.optionsCadena.forEach(function (valueIn,keyIn){
                    if (value.value === 0){
                        $this.selectTodosCanales = true;
                        $this.filters.canalesReal = $this.optionsCanalesReal;
                        $this.optionsCadenaReal = $this.optionsCadena;
                        return;
                    }
                    if (parseInt(value.value) === parseInt(valueIn.canal)){
                        $this.selectTodosCanales = false;
                        $this.optionsCadenaReal.push({name: '' + valueIn.name+ '', value:valueIn.value, canal:''+valueIn.canal+''});
                    }
                });
            });
        },
        SelectCadena(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.cadenas = [];
                    $this.filters.cadenas.push({
                        "name":'TODAS',
                        "value":0,
                        "canal":0,
                    })
                }else{
                    $this.filters.cadenasReal = $this.filters.cadenas;
                }
            });
            if (selectedOption.length === 0){ $this.filters.cadenasReal = $this.filters.cadenas;}
            this.filters.cadenas.forEach(function (value,key){
                if (value.value === 0){
                    $this.selectTodasCadenas = true;
                    $this.filters.cadenasReal = $this.optionsCadenaReal;
                }
            });
        },
        SelectProducto(selectedOption, id){let $this = this;
            console.log(selectedOption);
            this.chartOptionsPto.xAxis.categories = [];
            this.chartOptionsPto.series[0].data = [];
            this.chartOptionsPto.series[1].data = [];
            axios.post('/api/Dashboard/exhibicionesSemanaProducto',{anio: 2021,producto:this.filters.productos.value})
                .then(r => {
                        if (r.data.Success){
                            if (!$this.typeReport){
                                $this.chartOptions.xAxis.max = 11;
                                $this.chartOptions.xAxis.title.text = 'Mes';
                                //$this.chartOptionsNuevo.xAxis.max = 11;
                                //$this.chartOptionsNuevo.xAxis.title.text = 'Mes';
                                $this.chartOptionsPto.xAxis.max = 11;
                                $this.chartOptionsPto.xAxis.title.text = 'Mes';
                            }
                            else{
                                $this.chartOptions.xAxis.max = 22;
                                $this.chartOptions.xAxis.title.text = 'Semanas <br /> Tiendas <br /> ';
                                //$this.chartOptionsNuevo.xAxis.max = 22;
                                //$this.chartOptionsNuevo.xAxis.title.text = 'Semanas <br /> Tiendas';
                                $this.chartOptionsPto.xAxis.max = 22;
                                $this.chartOptionsPto.xAxis.title.text = 'Semanas <br /> Tiendas';
                            }
                            $this.showLoad = false;
                            $this.chartOptionsPto.xAxis.categories = r.data.Semanas;

                            r.data.ProductoImpacto.forEach(function (value,key){
                                $this.chartOptionsPto.series[0].data.push(parseInt(value));
                            });
                            r.data.ProductoImpulso.forEach(function (value,key){
                                $this.chartOptionsPto.series[1].data.push(parseInt(value));
                            });

                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        async getReportDetalle(){ let $this = this;
            var anios = this.$refs.selectedAnio.selectedAnios;
            if (anios === null){
                this.$toastMessage('error','Seleccione el año, para generar el reporte!',4000,'center');
            }
            else{
                this.chartOptions.xAxis.categories = [];
                this.chartOptions.series[0].data = [];
                this.chartOptions.series[1].data = [];
                ////// para la grafica con el comparativo del año pasado
                this.chartOptionsTotales.xAxis.categories = [];
                this.chartOptionsTotales.series[0].data = [];
                this.chartOptionsTotales.series[1].data = [];
                this.chartOptionsTotales.series[2].data = [];
                //
                this.chartOptionsImpulso.xAxis.categories = [];
                this.chartOptionsImpulso.series[0].data = [];
                this.chartOptionsImpulso.series[1].data = [];
                //
                this.chartOptionsImpacto.xAxis.categories = [];
                this.chartOptionsImpacto.series[0].data = [];
                this.chartOptionsImpacto.series[1].data = [];
                this.showLoad = true;
                var cat = this.$refs.Categoria.selectedCategorias;
                await axios.post('/api/Dashboard/exhibicionesSemana',{
                    anio: anios.value,
                    canal: this.filters.canalesReal,
                    cadena: this.filters.cadenasReal,
                    responsables: this.filters.responsables,
                    categoria: cat,
                    blindadas: this.tiendasBlindadas,
                    catPF: this.catPF,
                    type: this.typeReport
                })
                    .then(r => {
                            if (r.data.Success){
                                $this.dataAltoImpactoTiendas = r.data.AltoImpacto;
                                let dfasdf = 0
                                let seriesSem = []
                                let seriesSemAlto = []
                                let seriesSemImpulso = []
                                r.data.Semanas.forEach(function (value,key){
                                    if (dfasdf < r.data.AltoImpacto.length ){
                                        if (dfasdf < r.data.Impulso.length ){
                                            seriesSem.push(value+'<br />'+r.data.AltoImpacto[dfasdf].Totales +'<br />' + ((parseFloat(r.data.AltoImpacto[dfasdf].Total) + parseFloat(r.data.Impulso[dfasdf].Total))/r.data.AltoImpacto[dfasdf].Totales).toFixed(2));
                                            seriesSemAlto.push(value+'<br />'+r.data.AltoImpacto[dfasdf].Tienda +'<br />' + (r.data.AltoImpacto[dfasdf].Total / r.data.AltoImpacto[dfasdf].Tienda ).toFixed(2));
                                            seriesSemImpulso.push(value+'<br />'+r.data.Impulso[dfasdf].Tienda +'<br />' + (r.data.Impulso[dfasdf].Total / r.data.AltoImpacto[dfasdf].Tienda ).toFixed(2));
                                        }
                                        else{
                                            seriesSem.push(value+'<br />'+r.data.AltoImpacto[dfasdf].Totales +'<br />' + ((parseFloat(r.data.AltoImpacto[dfasdf].Total) )/r.data.AltoImpacto[dfasdf].Totales).toFixed(2));
                                            seriesSemAlto.push(value+'<br />'+r.data.AltoImpacto[dfasdf].Tienda +'<br />' + (r.data.AltoImpacto[dfasdf].Total / r.data.AltoImpacto[dfasdf].Tienda ).toFixed(2));
                                            seriesSemImpulso.push(value+'<br />0<br />0');
                                        }

                                        dfasdf ++
                                    }
                                    else{
                                        seriesSem.push(value+'<br />0<br />0');
                                        seriesSemAlto.push(value+'<br />0<br />0');
                                        seriesSemImpulso.push(value+'<br />0<br />0');
                                    }
                                });
                                $this.showLoad = false;
                                $this.chartOptions.xAxis.categories = seriesSem;
                                $this.chartOptionsTotales.xAxis.categories = seriesSem;
                                $this.chartOptionsImpulso.xAxis.categories = seriesSemImpulso;
                                $this.chartOptionsImpacto.xAxis.categories = seriesSemAlto;

                                $this.chartOptionsTotales.series[2].name = 'Total '+r.data.AnioP;
                                $this.chartOptionsImpulso.series[1].name = 'Impulso '+r.data.AnioP;
                                $this.chartOptionsImpacto.series[1].name = 'Impacto '+r.data.AnioP;


                                if (!$this.typeReport){
                                    $this.chartOptionsTotales.xAxis.max = 11;
                                    $this.chartOptionsTotales.title.text = 'REPORTE MENSUAL TOTALES';
                                    $this.chartOptionsTotales.xAxis.title.text = 'Mes <br /> Tiendas <br /> Promedio';
                                    $this.chartOptionsImpulso.xAxis.max = 11;
                                    $this.chartOptionsImpulso.title.text = 'REPORTE MENSUAL IMPULSO';
                                    $this.chartOptionsImpulso.xAxis.title.text = 'Mes <br /> Tiendas <br /> Promedio';
                                    $this.chartOptionsImpacto.xAxis.max = 11;
                                    $this.chartOptionsImpacto.title.text = 'REPORTE MENSUAL IMPACTO';
                                    $this.chartOptionsImpacto.xAxis.title.text = 'Mes <br /> Tiendas <br /> Promedio';

                                }
                                else{
                                    $this.chartOptionsTotales.xAxis.max = 22;
                                    $this.chartOptionsTotales.xAxis.title.text = 'Semanas <br /> Tiendas <br /> Promedio';
                                    $this.chartOptionsTotales.title.text = 'REPORTE SEMANAL TOTALES';
                                    $this.chartOptionsImpulso.xAxis.max = 22;
                                    $this.chartOptionsImpulso.xAxis.title.text = 'Semanas <br /> Tiendas <br /> Promedio';
                                    $this.chartOptionsImpulso.title.text = 'REPORTE SEMANAL IMPULSO';
                                    $this.chartOptionsImpacto.xAxis.max = 22;
                                    $this.chartOptionsImpacto.xAxis.title.text = 'Semanas <br /> Tiendas <br /> Promedio';
                                    $this.chartOptionsImpacto.title.text = 'REPORTE SEMANAL IMPACTO';

                                }

                                r.data.Impulso.forEach(function (value,key){
                                    $this.chartOptionsTotales.series[0].data.push(parseInt(value.Total));
                                });

                                $this.dataImpulsoTiendas = r.data.Impulso;
                                r.data.AltoImpacto.forEach(function (value,key){
                                    $this.chartOptionsTotales.series[1].data.push(parseInt(value.Total));
                                });
                                r.data.Totales.forEach(function (value,key){
                                    $this.chartOptionsTotales.series[2].data.push(parseInt(value));
                                });

                                r.data.Impulso.forEach(function (value,key){
                                    $this.chartOptionsImpulso.series[0].data.push(parseInt(value.Total));
                                });
                                r.data.ImpulsoPasado.forEach(function (value,key){
                                    $this.chartOptionsImpulso.series[1].data.push(parseInt(value.Total));
                                });

                                r.data.AltoImpacto.forEach(function (value,key){
                                    $this.chartOptionsImpacto.series[0].data.push(parseInt(value.Total));
                                });

                                r.data.AltoImpactoPasado.forEach(function (value,key){
                                    $this.chartOptionsImpacto.series[1].data.push(parseInt(value.Total));
                                });
                            }
                        },
                        error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                    );
            }

        },
        async getFileExhibiciones(){
            let $this = this;
            var anios = this.$refs.selectedAnio.selectedAnios;
            if (anios === null){
                this.$toastMessage('error','Seleccione el año, para generar el reporte!',4000,'center');
            }else {
                var cat = this.$refs.Categoria.selectedCategorias;
                $this.showLoad=true;
                await axios.post('/api/Dashboard/exhibicionesSemanaExcel',{
                    anio: anios.value,
                    canal: this.filters.canalesReal,
                    cadena: this.filters.cadenasReal,
                    categoria: cat,
                    type: this.typeReport
                    },{
                        responseType: 'arraybuffer',
                    })
                    .then(r => {
                        let blob = new Blob([r.data], { type: 'application/xlsx' })
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = 'ExhibicionesAteneaDashboard.xlsx'
                        link.click()
                        $this.showLoad=false;
                    })
                    .catch((error) => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        $this.showLoad=false;
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>
