<template>
    <div>
        <div class="row">
            <div class="col-md-2">
                <v-select :multiple="false" :options="optionsAnios" :placeholder="'Selecciona Año'"
                          v-model="selectedAnios" @input="setCambio"></v-select>
            </div>
            <div class="col-md-3">
                <v-select :multiple="false" :options="optionsSemanas" :placeholder="'Selecciona Semana'"
                          v-model="selectedSemana" @input="changeSemana" ref="semanaSelect"></v-select>
            </div>
            <div class="col-md-4">
                <kpi-select ref="kpiselect" :placeholderm="'KPI'" v-on:cambioKpi="getData"></kpi-select>
            </div>
            <div class="col-md-3" v-if="showButton">
                <div class="text-center">
                    <button class="btn btn-with-icon btn-primary" @click="getFile"><i class="fas fa-download"></i> Descargar Reporte</button>
                </div>
            </div>
        </div>
        <br/><br/>
    </div>
</template>

<script>
export default {

    name: "kpi",
    data(){
        return{
            showButton: false,
            idReport: 0,
            filters:{
                anio: 2023,
                semana: 26,
            },
            selectedAnios: null,
            optionsAnios: [{label:"2023",value:2023},{label:"2024",value:2024},{label:"2025",value:2025}],
            selectedSemana: null,
            optionsSemanas: []
        }
    },
    methods:{
        mounted(){

        },
        beforeMount(){
            //this.setCambio();
        },
        setCambio(){
            if (this.selectedAnios != null){
                this.filters.anio = this.selectedAnios.value;
                this.getDataSemanas();
            }

        },
        changeSemana(){
            this.filters.semana = this.selectedSemana.value;
        },
        getData(){
            if (this.$refs.kpiselect.selectedKpi != null){
                this.idReport = this.$refs.kpiselect.selectedKpi.value;
                this.showButton=true;
            } else {
                this.showButton=false;this.idReport = 0;
            }

        },
        async getFile(){
            this.showButton = false;
            if (this.$refs.kpiselect.selectedKpi === null){
                this.$toastMessage('error','Error: SELECCIONE EL KPI A DESCARGAR',4000,'center');
                this.showButton = true;
                return;
            }
            if (this.$refs.kpiselect.selectedKpi.value === 1){
                await this.$peticionesApiFilesDownload('/api/KPI/exportLayoutToExcel','kpiAtenea.xlsx', {ini:this.selectedSemana.ini,fin:this.selectedSemana.fin});
            }
            if (this.$refs.kpiselect.selectedKpi.value === 2){
                await this.$peticionesApiFilesDownload('/api/KPI/exportLayoutToExcelWalmart','kpiAteneaWalmart.xlsx', {ini:this.selectedSemana.ini,fin:this.selectedSemana.fin});
            }

            this.showButton = true;
        },
        getDataSemanas(){
            let $this = this;
            axios.post('/api/Dashboard/getSemanasKpi',{ anio: this.filters.anio })
                .then(r => {
                        $this.optionsSemanas = r.data.Data;
                        $this.selectedSemana = $this.optionsSemanas[$this.optionsSemanas.length-1];
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
    }
}
</script>

<style scoped>

</style>
