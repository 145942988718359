<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
    name: "samskpi",
    components: {
        StreamBarcodeReader,
    },
    data(){
        return {
            mensaje: "CLICK EN EL BOTÓN ESCANEAR",
            scanCode: false,
            validatePto: false,
            sendData: false,
            producto: {
                "Dsc": "CLICK EN EL BOTÓN ESCANEAR"
            },
            rutas: [],
            id: null,
            dataEncuesta: {
                idRuta: {"label": 'Selecciona tienda', "value": 0},
                p1: false,
                evidencia1: '',
                p2: false,
                evidencia2: '',
                p3: false,
                evidencia3: '',
                p4: false,
                evidencia4: '',
            }
        }
    },
    methods: {
        async chooseFiles(e,pregunta) {
            const file = e.target.files[0];
            if (pregunta === 'primera'){
                this.dataEncuesta.evidencia1 = await this.createBase64Image(file);
            }
            else if (pregunta === 'segunda'){
                this.dataEncuesta.evidencia2 = await this.createBase64Image(file);
            }
            else if (pregunta === 'tercera'){
                this.dataEncuesta.evidencia3 = await this.createBase64Image(file);
            }
            else {
                this.dataEncuesta.evidencia4 = await this.createBase64Image(file);
            }

        },
        clickFiles(pregunta) {
            document.getElementById(pregunta).click()
        },
        async onDecode(a, b, c) {
            this.mensaje = a;
            //console.log(a, b, c);
            this.scanCode = false
            await this.getPto(a)
        },
        onLoaded() {
            console.log("load");
        },
        async getRutas() {
            let $this = this
            await axios.post('/api/WebServices/getRutas')
                .then(({data}) =>{
                    $this.rutas = data.data
                })
                .catch((error) =>{
                    $this.rutas = []
                    $this.$toastMessage('error',error.message,4000,'center');
                })
        },
        async getPto(producto) {
            let $this = this
            if (this.dataEncuesta.idRuta.value === 0){
                $this.$toastMessage('error',"SELECCIONE LA RUTA, PARA BUSCAR PRODUCTOS",6000,'center')
                return
            }
            await axios.post('/api/WebServices/getProducto', {
                pto: producto
            })
                .then(({data}) =>{
                    console.log(data)
                    if (data.Success){
                        $this.producto = data
                        $this.validatePto = true
                    }
                    else{
                        $this.$toastMessage('error',"PRODUCTO NO ENCONTRADO: " + producto ,9000,'center')
                        $this.producto = {
                            "Dsc": "PRODUCTO NO REGISTRADO EN LA BASE DE DATOS, VALIDAR CON SOPORTE TÉCNICO"
                        }
                        $this.validatePto = false
                    }

                })
                .catch((error) =>{
                    $this.rutas = []
                    $this.$toastMessage('error',error.message,4000,'center');
                })
        },
        async saveData(){
            this.sendData = true
            let $this = this;
            if (this.dataEncuesta.idRuta.value === 0){
                $this.$toastMessage('error', 'Seleccione la ruta, para registrar el KPI',4000,'center');
            }
            else if (!this.validatePto){
                $this.$toastMessage('error', 'Escanee el producto, para registrar',4000,'center');
            }
            else if (this.dataEncuesta.evidencia1 === ''){
                $this.$toastMessage('error', 'Toma evidencia de la pregunta 1',4000,'center');
            }
            else if (this.dataEncuesta.evidencia2 === ''){
                $this.$toastMessage('error', 'Toma evidencia de la pregunta 2',4000,'center');
            }
            else if (this.dataEncuesta.evidencia3 === ''){
                $this.$toastMessage('error', 'Toma evidencia de la pregunta 3',4000,'center');
            }
            else if (this.dataEncuesta.evidencia4 === '' && this.dataEncuesta.p4){
                $this.$toastMessage('error', 'Toma evidencia de la pregunta 4',4000,'center');
            }
            else{
                await axios.post('/api/WebServices/saveSamsKpi', {
                    data: this.dataEncuesta, producto: this.producto.Codigo
                })
                    .then(({data}) =>{
                        //console.log(data)
                        $this.dataEncuesta.p1 = false
                        $this.dataEncuesta.p2 = false
                        $this.dataEncuesta.p3 = false
                        $this.dataEncuesta.p4 = false
                        $this.dataEncuesta.evidencia1 = ''
                        $this.dataEncuesta.evidencia2 = ''
                        $this.dataEncuesta.evidencia3 = ''
                        $this.dataEncuesta.evidencia4 = ''
                        $this.$toastMessage('success', 'registro exitoso',4000,'center');
                    })
                    .catch((error) =>{
                        $this.rutas = []
                        $this.$toastMessage('error',error.message,4000,'center');
                    })
                $this.validatePto = false
                this.sendData = false
            }
            this.sendData = false

        },
        async createBase64Image(fileObject) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(fileObject);
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
            })
        },
        setCambio(){
            this.$emit('cambioRuta');
        },
    },
    mounted() {
        this.getRutas()
    }
}
</script>

<template>
    <div>
        <div class="container">
            <div v-if="rutas.length === 1">
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-danger" role="alert">
                            <strong class="h1">RUTAS NO ASIGNADAS A TIENDAS SAM'S CLUB, REPORTAR INCIDENCIA</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-12">
                        <v-select :multiple="false" :options="rutas" :placeholder="'Selecciona ruta'"
                                  v-model="dataEncuesta.idRuta" @input="setCambio"
                                  :autoscroll="true"></v-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-1" />
                    <div class="col text-center">
                        <br >
                        <button class="btn ripple btn-success" v-if="!scanCode" @click="scanCode = !scanCode">
                            <i class="fas fa-barcode mx-1"></i>
                            ESCANEAR PRODUCTO
                        </button>
                        <div class="hello" v-if="scanCode">
                            <button class="btn ripple btn-danger" @click="scanCode = !scanCode">
                                <i class="fas fa-times-circle mx-1"></i>
                                CANCELAR
                            </button>
                            <StreamBarcodeReader
                                @decode="(a, b, c) => onDecode(a, b, c)"
                                @loaded="() => onLoaded()"
                            ></StreamBarcodeReader>
                        </div>
                    </div>
                    <div class="col-1" />
                </div>
                <div class="row">
                    <div class="col-12 t-20">
                        <p class="font-weight-bold text-center">PRODUCTO: {{ mensaje || "NO SE ESCANEO" }}</p>
                        <p class="text-center">{{ producto.Dsc }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 nopadding t-20">
                        <div class="alert alert-success" role="alert">
                            <strong>¿ESTE PRODUCTO SE ENCUENTRA EN EL RACK?</strong>
                        </div>
                    </div>
                    <div class="col-7 text-left t-15">
                        <p-radio :value="true" v-model="dataEncuesta.p1" class="p-icon p-round p-smooth p-pulse" color="success" name="radio1" style="font-size: 20px">
                            <i slot="extra" class="icon mdi mdi-check"></i>
                            SI
                        </p-radio>
                        <p-radio :value="false" v-model="dataEncuesta.p1" class="p-icon p-round p-smooth p-pulse" color="danger" name="radio1"  style="font-size: 20px">
                            <i slot="extra" class="icon mdi mdi-check"></i>
                            NO
                        </p-radio>
                    </div>
                    <div class="col-5 text-center t-15">
                        <input type="file" accept="image/*" capture="camera" hidden="" id="primera" @change="chooseFiles($event, 'primera')"/>
                        <button class="btn ripple btn-outline-info small" @click="clickFiles('primera')">
                            <i class="fas fa-camera-retro mx-1"></i>
                        </button>
                        <img alt="demo" :src="dataEncuesta.evidencia1" class="img-fluid img-thumbnail" v-show="dataEncuesta.evidencia1 !== ''"/>
                        <p class="text-danger font-weight-bold small" v-if="dataEncuesta.evidencia1 === ''">Evidencia obligatoria</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 nopadding t-20">
                        <div class="alert alert-success" role="alert">
                            <strong>¿ESTE PRODUCTO TIENE INVENTARIO?</strong>
                        </div>
                    </div>
                    <div class="col-7 text-left t-15">
                        <p-radio :value="true" v-model="dataEncuesta.p2" class="p-icon p-round p-smooth p-pulse" color="success" name="radio2" style="font-size: 20px">
                            <i slot="extra" class="icon mdi mdi-check"></i>
                            SI
                        </p-radio>
                        <p-radio :value="false" v-model="dataEncuesta.p2" class="p-icon p-round p-smooth p-pulse" color="danger" name="radio2"  style="font-size: 20px">
                            <i slot="extra" class="icon mdi mdi-check"></i>
                            NO
                        </p-radio>
                    </div>
                    <div class="col-5 text-center t-15">
                        <input type="file" accept="image/*" capture="camera" hidden="" id="segunda" @change="chooseFiles($event, 'segunda')"/>
                        <button class="btn ripple btn-outline-info small" @click="clickFiles('segunda')">
                            <i class="fas fa-camera-retro mx-1"></i>
                        </button>
                        <img alt="demo" :src="dataEncuesta.evidencia2" class="img-fluid img-thumbnail" v-show="dataEncuesta.evidencia2 !== ''"/>
                        <p class="text-danger font-weight-bold small" v-if="dataEncuesta.evidencia2 === ''">Evidencia obligatoria</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12 nopadding t-20">
                        <div class="alert alert-success" role="alert">
                            <strong>¿EL PRECIO ES CORRECTO?</strong>
                        </div>
                    </div>
                    <div class="col-7 text-left t-15">
                        <p-radio :value="true" v-model="dataEncuesta.p3" class="p-icon p-round p-smooth p-pulse" color="success" name="radio3" style="font-size: 20px">
                            <i slot="extra" class="icon mdi mdi-check"></i>
                            SI
                        </p-radio>
                        <p-radio :value="false" v-model="dataEncuesta.p3" class="p-icon p-round p-smooth p-pulse" color="danger" name="radio3"  style="font-size: 20px">
                            <i slot="extra" class="icon mdi mdi-check"></i>
                            NO
                        </p-radio>
                    </div>
                    <div class="col-5 text-center t-15">
                        <input type="file" accept="image/*" capture="camera" hidden="" id="tercera" @change="chooseFiles($event, 'tercera')"/>
                        <button class="btn ripple btn-outline-info small" @click="clickFiles('tercera')">
                            <i class="fas fa-camera-retro mx-1"></i>
                        </button>
                        <img alt="demo" :src="dataEncuesta.evidencia3" class="img-fluid img-thumbnail" v-show="dataEncuesta.evidencia3 !== ''"/>
                        <p class="text-danger font-weight-bold small" v-if="dataEncuesta.evidencia3 === ''">Evidencia obligatoria</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 nopadding t-20">
                        <div class="alert alert-success" role="alert">
                            <strong>¿CUENTA CON EXHIBICION ADICIONAL?</strong>
                        </div>
                    </div>
                    <div class="col-7 text-left t-15">
                        <p-radio :value="true" v-model="dataEncuesta.p4" class="p-icon p-round p-smooth p-pulse" color="success" name="radio4" style="font-size: 20px">
                            <i slot="extra" class="icon mdi mdi-check"></i>
                            SI
                        </p-radio>
                        <p-radio :value="false" v-model="dataEncuesta.p4" class="p-icon p-round p-smooth p-pulse" color="danger" name="radio4"  style="font-size: 20px">
                            <i slot="extra" class="icon mdi mdi-check"></i>
                            NO
                        </p-radio>
                    </div>
                    <div class="col-5 text-center t-15">
                        <input type="file" accept="image/*" capture="camera" hidden="" id="cuarta" @change="chooseFiles($event, 'cuarta')"/>
                        <button class="btn ripple btn-outline-info small" @click="clickFiles('cuarta')" v-if="dataEncuesta.p4">
                            <i class="fas fa-camera-retro mx-1"></i>
                        </button>
                        <img alt="demo" :src="dataEncuesta.evidencia4" class="img-fluid img-thumbnail" v-show="dataEncuesta.evidencia4 !== '' && dataEncuesta.p4"/>
                        <p class="text-danger font-weight-bold small" v-if="dataEncuesta.evidencia4 === '' && dataEncuesta.p4">
                            Evidencia obligatoria
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-1 t-40" />
                    <div class="col text-center t-40">
                        <button class="btn ripple btn-info" v-if="!sendData" @click="saveData">
                            <i class="fas fa-save mx-1"></i>
                            REGISTRAR INFORMACIÓN
                        </button>
                        <div class="" v-else>
                            <br>
                            <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                            <p>Enviando información, por favor espere!</p>
                        </div>
                    </div>
                    <div class="col-1 t-40" />
                </div>
            </div>
        </div>
        <br >
        <br >
    </div>
</template>

<style scoped>
.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}
</style>
