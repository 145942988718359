<script>
import Highcharts from 'highcharts'
function createChart(vm){
    return{
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{point.nombre}: {point.percentage:.2f} % <br><b>{point.y}</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.2f} %<br> <b style="color: darkorange">{point.y}</b>',
                    style: {
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                    }
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        series: [{
            name: 'Avance',
            data: [
                {
                    name: 'MATERIAL EN TIENDA',
                    y: 24.00,
                    color: '#2cb517'
                }, {
                    name: 'SIN MATERIAL EN TIENDA',
                    y: 76.00,
                    color: '#e1144b'
                }
            ]
        }]
    }
}
function createChartRespuestas(vm){
    return{
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'column'
        },
        title: {
            text: 'Respuesta Material POP'
        },
        tooltip: {
            pointFormat: '{point.nombre} <br><b>{point.y}</b>'
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        xAxis: {
            type: 'category',
            crosshair: true,
            accessibility: {
                description: 'Respuestas'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Cantidad de tiendas'
            }
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y}'
                }
            }
        },
        series: [
            {
                name: 'Respuestas',
                colorByPoint: true,
                data: []
            }
        ]
    }
}
function createChartAvanceRegional(vm){
    return{
        chart: {
            type: 'bar',
            height: 0,
        },
        scrollbar:{
            enabled:false
        },
        title: {
            text: 'AVANCE REGIÓN'
        },
        xAxis: {
            categories: [],
            scrollbar: {
                enabled: false
            },
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
                enabled: false
            },
            labels: {
                enabled: false
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            bar: {
                grouping: false
            },
            series: {
                pointWidth: 18,
                //stacking: 'percent',
                dataLabels: {
                    enabled: true,
                    inside: true,
                    align: 'right',
                    color: '#fff',
                    style: {
                        textShadow: false,
                        textOutline: "0px"
                    }
                }
            }
        },
        credits:{
            text: 'GRUPO PROMOCIONES',
            href: 'https://grupopromociones.com'
        },
        tooltip: {
            useHTML: true,
            shared: true,
            formatter: function() {
                return '<i>' + this.points[1].x +'</i>: <b>'+ this.points[1].y +'%</b>';
            }
        },
        series: [
            {
                name: 'background filler',
                data: [],
                color:'#ed5565',
                animation: false,
                dataLabels: {
                    enabled: false
                }
            },
            {
                name: 'actual Value',
                data: [],
                color:'#1ab394',
                dataLabels: {
                    enabled: true,
                    formatter: function(){
                        return this.y + '%';
                    }
                }
            }]
    }
}
export default {
    name: "prueba",
    data(){
       return {
           chartOptions: createChart(this),
           chartOptionsR: createChartRespuestas(this),
           chartOptionsRegional: createChartAvanceRegional(this),
           semanasSelect: [],
           semana: [],
           loadSemanas: false,
           loadfile: false,
           showDonwload: false,
           loadReport: false,
           evalucaion: 0,
           dataReport: [],
           tableDataReporte: [],
           columnsExhibiciones: ['Tienda','KCMID','Lider','Regional','Sector','Estatus','Respuesta','Etiqueta'],
           optionsReporteTable: {
               footerHeadings:false,
               filterByColumn:true,
               perPage: 20,
               perPageValues: [5,10,20,40],
               filterable: ['Tienda','KCMID','Lider','Regional','Sector','Estatus','Respuesta'],
               sortIcon:
                   { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
               ,
               listColumns: {
                   Estatus: [ { text:'OK', id: 1 },{ text:'NO', id: 0}, { text:'NO REPORTA', id: 2}]
               },
               cellClasses:{
               },
               columnsDropdown:false,
               headings: {
                   Id: '#',
               },
               columnsClasses: {
                   Tienda: 'text-justify font-weight-bold',
                   Etiqueta: 'text-center font-weight-bold',
                   KCMID: 'text-center font-weight-bold',
                   Sector: 'text-center font-weight-bold text-info',
                   Estatus: 'text-center',
               },
               texts: {
                   count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                   first: 'Primero',
                   last: 'Último',
                   filter: "Filtro:",
                   filterPlaceholder: "Buscar:",
                   limit: "Registros:",
                   page: "Pagína:",
                   noResults: "No hay resultados",
                   filterBy: "Filtrar",
                   loading: 'Buscando...',
                   defaultOption: 'Todos',
                   //defaultOption: 'Elige {column}',
                   columns: 'Columnas'
               },
           },
       }
    },
    methods:{
        async getSemanas(){
            let $this = this
            this.loadSemanas = true
            if (this.$refs.matPopSelect.selectedPop === null || this.$refs.matPopSelect.selectedPop === []){
                this.$toastMessage('error','Seleccione el material pop para generar el reporte',4000,'center');
                return
            }
            await axios.post('/api/POP/getSemanas',
            {
                idMatPop: this.$refs.matPopSelect.selectedPop
            }).then(({data}) =>{
                $this.semanasSelect = data.Semanas
            }).catch((error) =>{
                $this.$toastMessage('error',error.message,4000,'center');
            })
            this.loadSemanas = false
        },
        async getReport(){
            let $this = this
            this.loadReport = true
            this.showDonwload = false
            if (this.semana === null || this.semana === []){
                this.$toastMessage('error','Seleccione la semana para poder generar el reporte',4000,'center');
                return
            }
            await axios.post('/api/POP/getReporte',
                {
                    idMatPop: this.$refs.matPopSelect.selectedPop,
                    semana: this.semana,
                }).then(({data}) =>{
                    $this.tableDataReporte = data.Data
                    $this.evalucaion = data.Evaluacion
                    $this.chartOptions.series[0].data[0].y = parseFloat(data.Evaluacion)
                    $this.chartOptions.series[0].data[1].y = 100 - parseFloat(data.Evaluacion)
                    $this.chartOptionsR.series[0].data = []
                    data.Totales.forEach((value,key) =>{
                        $this.chartOptionsR.series[0].data.push({
                            name: value.Respuesta,
                            y: parseFloat(value.Total)
                        })
                    })
                    data.TotalesRegionales.forEach((value,key) =>{
                        $this.chartOptionsRegional.series[0].data.push(100)
                        $this.chartOptionsRegional.series[1].data.push(parseFloat(value.Evaluacion))
                        $this.chartOptionsRegional.series[1].data.name = value.Regional
                        $this.chartOptionsRegional.xAxis.categories.push(value.Regional)
                        })
                }).catch((error) =>{
                    $this.$toastMessage('error',error.message,4000,'center');
                })
            this.loadReport = false
            this.showDonwload = true
        },
        async getReportExcel(){
            this.loadfile = true;
            this.showDonwload = false;
            await this.$peticionesApiFilesDownload('/api/POP/exportLayoutToExcel','MaterialPopAtenea.xlsx',this.tableDataReporte);
            this.loadfile=false;
            this.showDonwload = true;
        }
    }
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <label>Material POP:</label>
                <select-matpop
                    ref="matPopSelect"
                    :placeholderm="'material pop'"
                    :multipl="false"
                    :todos="false"
                    @cambioPop="getSemanas" />
            </div>
            <div class="col-md-4">
                <div class="row" v-if="loadSemanas">
                    <div class="col-md-12 text-center">
                        <div class="spinner-border text-secondary" role="status">
                        </div><br />
                        <span class="">Buscando información...</span>
                    </div>
                </div>
                <label>Semanas:</label>
                <v-select :options="semanasSelect" :placeholder="'Selecciona semana'"
                          v-model="semana" @input="getReport"/>
            </div>
            <div class="col-md-4 text-center">
                <button v-if="showDonwload" class="btn ripple btn-success" @click="getReportExcel">Descargar Excel</button>
                <div class="row" v-if="loadfile">
                    <div class="col-md-12 text-center">
                        <div class="spinner-border text-secondary" role="status">
                        </div><br />
                        <span class="">Buscando información...</span>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="row" v-if="loadReport">
            <div  class="col-md-12">
                <div class="text-center">
                    <div class="spinner-border text-secondary" role="status">
                    </div><br />
                    <span class="">Buscando información...</span>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-md-7">
                <v-client-table :data="tableDataReporte" :columns="columnsExhibiciones" :options="optionsReporteTable" class="table-responsive" ref="tableReporte">
                    <div slot="Tienda" slot-scope="props">
                        {{ props.row.Tienda }}
                    </div>
                    <div slot="Estatus" slot-scope="props">
                        <span class="badge badge-success" v-if="props.row.Estatus === 1">OK</span>
                        <span class="badge badge-warning" v-if="props.row.Estatus === 0">NO</span>
                        <span class="badge badge-danger" v-if="props.row.Estatus === 2">NO REPORTA</span>
                    </div>
                </v-client-table>
            </div>
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <span class="h3">
                            Evaluación: {{ evalucaion }} %
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <highcharts :options="chartOptions"></highcharts>
                        <highcharts :options="chartOptionsR"></highcharts>
                        <highcharts :options="chartOptionsRegional"></highcharts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
