<template>
    <div>
        <div v-show="!load">
            <v-select :multiple="multipl" :options="optionsMatPop" :placeholder="'Selecciona '+placeholderm"
                      v-model="selectedPop" @input="setCambio"></v-select>
        </div>
        <div v-show="load">
            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="20">
            <p>Preparando encuestas, espere...</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "matpopselect",
    props:{
        placeholderm: String,
        multipl: {default: true, required: false, type: Boolean},
        todos: {default: true, required: false, type: Boolean},
    },
    data(){
        return{
            selectedPop: [],
            optionsMatPop: [],
            agencia:[],
            load: false
        }
    },
    mounted() {
        this.getMatPop();
    },
    methods:{
        async getMatPop() {
            let $this = this;
            this.selectedPop = []; this.load = true;
            await axios
                .post('/api/AdminCatalogos/getMatPopSelect')
                .then(function (r) {
                    if ($this.todos){
                        $this.optionsMatPop = r.data;
                    }
                    else {
                        $this.optionsMatPop = r.data.splice(1);
                    }
                    $this.load = false;
                });
        },
        setCambio(){
            this.$emit('cambioPop');
        }

    }
}
</script>

<style scoped>

</style>
