<template>
    <div class="border"><br><br>
        <div class="text-center">
            <h2><span class="text-primary">EFICIENCIA OPERATIVA</span></h2>
        </div>
        <br>
        <div class="example">
            <div class="">
                <div class="bg-gray-300 nav-bg">
                    <nav class="nav nav-tabs">
                        <a class="nav-link active" data-toggle="tab" href="#tabCont1">Eficiencia General <i class="fas fa-chart-pie"></i></a>
                        <a class="nav-link" data-toggle="tab" href="#tabCont2">Eficiencia Región <i class="fas fa-globe"></i></a>
                        <a class="nav-link" data-toggle="tab" href="#tabCont3">Eficiencia Distrito <i class="fas fa-map-marked-alt"></i></a>
                    </nav>
                </div>
                <div class="card-body tab-content">
                    <div class="tab-pane active show" id="tabCont1">
                        <div class="text-center">
                            <reporte-general></reporte-general>
                        </div>
                    </div>
                    <div class="tab-pane" id="tabCont2">
                        <div class="text-center">
                            <region-eficiencia></region-eficiencia>
                        </div>
                    </div>
                    <div class="tab-pane" id="tabCont3">
                        <div class="text-center">
                            <distrito-eficiencia></distrito-eficiencia>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "reporte",
}
</script>

<style scoped>

</style>
