<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-md-3">
                <select-canal :placeholderm="'Canal'" ref="selectCanal" @cambioCanal="cambioCanal"></select-canal><br>
                <select-cadena :placeholderm="'Cliente'" ref="selectCadena" @cambioCadena="cambioCadena"></select-cadena><br>
                <select-categoria :placeholderm="'Categoria'" ref="selectCategoria"></select-categoria><br>
                <select-clasificacion :placeholderm="'Clasificación'" ref="selectClasificacion"></select-clasificacion><br>
                <p-check class="p-default p-curve" color="success" off-color="info" toggle
                @click.native="tipoReporte = !tipoReporte">
                    SEMANAL
                    <label slot="off-label">MENSUAL</label>
                </p-check>
                <br><br><br><br><br><br>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-center">
                        <button class="btn ripple btn-primary" @click="getReport" v-if="!loadingGetData">Generar Reporte </button>
                    </div>
                    <div class="col-md-4" v-if="showResult">
                        <button v-if="!loadfile"  class="btn btn-success" type="button" @click="ExportFile"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12">
                        <div v-if="loadfile" class="text-center">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando información, espere...</p>
                        </div>
                    </div>
                    <div class="col-md-12" v-show="showResult">
                        <v-client-table :data="tableDataExhibiciones" :columns="columnsExhibiciones" :options="optionsExhibiciones" class="table-responsive" ref="tablePromociones">
                        </v-client-table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import selectCadena from '../../components/filters/cadenas'
import selectCategoria from '../../components/filters/categorias'
import selectCanal from '../../components/filters/canal'
export default {
    name: "shareExhibiciones",
    components:{
        'select-cadena': selectCadena,
        'select-categoria': selectCategoria,
        'select-canal': selectCanal,
    },
    data(){
        return{
            loadfile:false,
            loadRegisterUpdate:false,
            tipoReporte:true,
            showResult:false,
            loadingGetData: false,
            showData: false,
            columnsExhibiciones: ['Canal','Anio','Cliente','Categoria','Tipo','Area','Mes','Total'],
            tableDataExhibiciones: [],
            optionsExhibiciones: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Anio','Cliente','Categoria','Area','Mes'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Mes: 'Tipo Reporte',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Acc: 'text-center',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Cnt: 'text-center',
                    Charolas: 'text-center',
                    Valor: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            idUpdate:0,
            registro:{},
            selectedCadenas:[],
            cabecera:4,
            valor:1,
        }
    },
    mounted() {

    },
    methods:{
        cambioCanal(){
            this.$refs.selectCadena.selectedCadenas = [];
            this.$refs.selectCadena.canal = this.$refs.selectCanal.selectedCanales;
            this.$refs.selectCadena.getCadenas();
        },
        cambioCadena(){
            if (this.$refs.selectCadena.selectedCadenas.find(element => element.value === 0)){
                this.selectedCadenas = this.$refs.selectCadena.optionsCadenas;
            }
            else{
                this.selectedCadenas = this.$refs.selectCadena.selectedCadenas;
            }
        },
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        async getReport(){
            var filters = this.selectedCadenas;
            var filtersCategorias = this.$refs.selectCategoria.selectedCategorias;
            var filtersTipo = this.$refs.selectClasificacion.selectedClasificacion;
            if (filters.length === 0){
                this.$toastMessage('error',"Seleccione un cliente para generar el reporte",4000,'center');
            }
            else if (filtersCategorias.length === 0){
                this.$toastMessage('error',"Seleccione una categoria para generar el reporte",4000,'center');
            }
            else if (filtersTipo === null){
                this.$toastMessage('error',"Seleccione una clasificación para generar el reporte",4000,'center');
            }
            else{
                this.loadfile = true;
                this.showData = false;
                this.showResult = false;
                this.loadingGetData = true;
                this.tableDataExhibiciones = [];
                const response = await this.$peticionesApi('/api/Exhibiciones/getResumenExhibicionesShare',
                    { clientes: filters, categorias: filtersCategorias, tipo: this.tipoReporte, tipoExhibicion: filtersTipo });
                if (response != null ){
                    this.loadfile = false;
                    this.showData = true;
                    response.Data.forEach((value,key)=>{
                        value.forEach((vl,k)=>{
                            this.tableDataExhibiciones.push(vl);
                        });
                    });

                    this.showResult = true;
                    this.loadingGetData = false;
                }

            }
        },
        async ExportFile(){
            this.loadfile = true;
            await this.$peticionesApiFilesDownload('/api/Exhibiciones/exportLayoutToExcelExhibicionesShare','ExhibicionesShareAtenea.xlsx',this.tableDataExhibiciones);
            this.loadfile=false;
        },
    }
}
</script>

<style scoped>

</style>
