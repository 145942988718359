<template>
    <div>
        <v-select :multiple="false" :options="optionsTiendas" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedTiendas" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "tiendas",
    props:{
        placeholderm: String,
    },
    data(){
        return{
            selectedTiendas: null,
            optionsTiendas: [],
            cveentfmt: 0
        }
    },
    mounted() {

    },
    methods:{
        getTiendas(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/tiendasMain',{formato:this.cveentfmt})
                .then(function (r){
                    $this.optionsTiendas = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioTienda');
        },
        getTiendasBlindadas(grupo){ let $this = this;
            axios
                .post('/api/AdminCatalogos/tiendasBlindadas',{grupo})
                .then(function (r){
                    $this.optionsTiendas = r.data;
                });
        },
    }
}
</script>

<style scoped>

</style>
