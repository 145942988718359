<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row" >
            <div class="col-md-2">
                <label>Fecha de inicio:</label><br>
                <date-picker v-model="timeInit" type="week" placeholder="Selecciona La Semana" valueType="'YYYY-MM-DD'" :lang="lang"></date-picker>

            </div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <!--<button class="btn ripple btn-primary" @click="getReport" v-if="!loadingGetData">Generar Reporte </button>-->
                        <button class="btn btn-outline btn-outline-success dim" type="button" v-if="!loadingGetData"
                                @click="getFileExhibiciones">
                            <i class="fa fa-file-excel"></i> DESCARGAR REPORTE
                        </button>
                        <div class="text-center">
                            <div class="row" v-if="loadingGetData">
                                <div class="col-md-12 text-center">
                                    <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                                    <p><b>Consultando información, por favor espere!</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "report",
    data(){
        return{
            timeInit: null,
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
            },
            tiendasReal:null,
            loadingGetData: false,
            showData: false,
            total: null,
            visitadas: null,
            novisitadas: null,
            proceso: null,
            pendientes: null,
            canceladas: null,
            dataAlcanceTienda: null,
            rutasProgramadas: 0,
            rutasVisitadas: 0,
            rutasCanceladas: 0,
            rutasProceso: 0,
            rutasPendientes: 0,
            rutasHoras: 0,
            rutasMinutos: 0,

        }
    },
    mounted() {

    },
    methods:{
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        async getReport(){ let $this = this;

            if (this.timeInit === null){
                this.$toastMessage('error',"Semana Obligatoria",4000,'center');
            }
            else if (this.$refs.selectTienda.selectedTiendas === null){
                this.$toastMessage('error',"Tienda Obligatoria",4000,'center');
            }else{
                this.loadingGetData = true; this.dataAlcanceTienda = null; this.dataRutas = []; this.tableData = [];
                this.rutasVisitadas = 0; this.rutasProceso = 0; this.rutasCanceladas = 0; this.rutasPendientes = 0;
                await axios.post('/api/ReporteRutas/getDataTienda',{semana: this.timeInit, tienda: this.$refs.selectTienda.selectedTiendas})
                    .then(r => {
                        if (r.data.Success){
                            $this.$toastMessage('success',r.data.Mensaje+'',9000,'center');
                            $this.dataAlcanceTienda = r.data.Alcance
                            $this.dataRutas = r.data.RutasData
                            $this.tableData = r.data.RutasData
                            $this.rutasProgramadas = $this.dataRutas.length
                            $this.dataRutas.forEach((value,key)=>{
                                if (value.Stt === 'Visitada'){
                                    $this.rutasVisitadas ++;
                                    $this.rutasHoras = $this.rutasHoras + value.Horas
                                    $this.rutasMinutos = $this.rutasMinutos + value.Minutos
                                }
                                if (value.Stt === 'Cancelada'){
                                    $this.rutasCanceladas ++;
                                }
                                if (value.Stt === 'Pendiente') {
                                    $this.rutasPendientes++;
                                }
                                if (value.Stt === 'No visitada'){
                                    $this.rutasPendientes ++;
                                }
                                if (value.Stt === 'Proceso'){
                                    $this.rutasProceso ++;
                                }

                            })
                        }else{
                            $this.$toastMessage('error','Error: ' + r.data.Mensaje +' Por favor envia este error al área de soporte!',19000,'center');
                        }
                            $this.loadingGetData = false;
                        },
                        error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',19000,'center');}
                    );
                $this.loadingGetData = false;
            }
        },
        changeTienda(){
            this.tiendasReal = this.$refs.selectTienda.selectedTiendas;
        },
        async getFileExhibiciones(){
            let $this = this;
            if (this.timeInit === null){
                this.$toastMessage('error',"Semana Obligatoria",4000,'center');
            }else{
                $this.loadingGetData=true;
                await axios.post('/api/ReporteRutas/getDataExcelTiendas',{
                    semana: this.timeInit,
                },{
                    responseType: 'arraybuffer',
                })
                .then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'ReporteTiendasAlcance.xlsx'
                    link.click()
                    $this.loadingGetData=false;
                })
                .catch((error) => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadingGetData=false;
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
