<template>
    <div>
        <v-select :multiple="false" :options="optionsTipoSeguimiento" :placeholder="'SELECCIONA '+placeholderm"
                  v-model="selectedTipoSeguimiento" @input="setCambio" :disabled="disablE"></v-select>
    </div>
</template>

<script>
export default {
    name: "tipoEncuestasSeguimiento",
    props:{
        placeholderm:String,
        todaOpciones:{required:true,type:Boolean,default:false},
        disablE:{required:false,type:Boolean,default:false},
    },
    data(){
        return{
            selectedTipoSeguimiento: {label:"EJECUCIÓN",value:1},
            optionsTipoSeguimiento:[],
            agencia:[]
        }
    },
    mounted() {
        if (this.todaOpciones){
           this. optionsTipoSeguimiento = [{label:"TODAS",value:'TODAS'}, {label:"EJECUCIÓN",value:1},{label:"SEGUIMIENTO",value:2}]
            this.selectedTipoSeguimiento = {label:"TODAS",value:'TODAS'};
        }
        else{
            this. optionsTipoSeguimiento = [{label:"EJECUCIÓN",value:1},{label:"SEGUIMIENTO",value:2}]
        }
    },
    methods:{
        setCambio(){
            this.$emit('cambioTipoEncuestaSeguimiento');
        }

    }
}
</script>

<style scoped>

</style>
