<script>
import Highcharts from 'highcharts'

Highcharts.setOptions({
    colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
        return {
            radialGradient: {
                cx: 0.5,
                cy: 0.3,
                r: 0.7
            },
            stops: [
                [0, color],
                [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
            ]
        };
    })
})
function createChartEstadistica(vm){
    return{
        chart: {
            type: 'pie',
            backgroundColor: null,
        },
        title: {
            text: '92%',
            align: 'center',
            verticalAlign: 'middle',
            style: {
                color: '#000000'
            }
        },
        plotOptions: {
            pie: {
                size: 80,
                innerSize: 60,
                depth: 40,
                dataLabels: {
                    enabled: false,
                }
            },

        },
        series: [{
            name: 'Resultados',
            data: [
                ['Real', 92],
                ['Faltante', 8],
            ]
        }],
        credits:{
            enabled:false, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
    }
}
export default {
    name: "chartSemiPie",
    data(){
        return{
            chartOptions: createChartEstadistica(this),
        }
    }
}
</script>

<template>
    <div>
        <highcharts :options="chartOptions" style="height: 100px;margin: 0 auto"></highcharts>
    </div>
</template>

<style scoped>

</style>
