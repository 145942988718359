<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-md-3">
                <filters-general ref="filtersReport"></filters-general>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-center">
                        <button class="btn ripple btn-primary" @click="getReport">Generar Reporte </button>
                    </div>
                    <div class="col-md-4" v-if="showResult">
                        <button v-if="!loadfile"  class="btn btn-success" type="button" @click="ExportFile"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12">
                        <div v-if="loadfile" class="text-center">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando información, espere...</p>
                        </div>
                    </div>
                    <div class="col-md-12" v-show="showResult">
                        <v-client-table :data="tableDataExhibiciones" :columns="columnsExhibiciones" :options="optionsExhibiciones" class="table-responsive" ref="tablePromociones">
                            <div slot="Acc" slot-scope="props">
                                <i class="fas fa-edit tx-indigo" style="cursor: pointer;"
                                   @click="updateRegistro(props.row.Cverepexh)"
                                   data-effect="effect-super-scaled" data-toggle="modal" href="#modalChangeExhibicion"></i>
                            </div>
                        </v-client-table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "tablaExhibiciones",
    data(){
        return{
            loadfile:false,
            loadRegisterUpdate:false,
            showResult:false,
            loadingGetData: false,
            showData: false,
            columnsExhibiciones: ['Fecha','Semana','Area','Agencia','Rol','Usuario','Estado','Ciudad','Canal','Cadena','Formato','Tienda','Kcmid','Categoria','Comentario','Respuesta',"Campana"],
            tableDataExhibiciones: [],
            optionsExhibiciones: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Tienda','Area','Formato','Canal','Cadena','Kcmid','Categoria'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                    Exhibicion: 'Tipo Exhibición',
                    Cnt: 'Cantidad',
                    Kcmid: 'KCMID',
                    Campana: 'Campaña',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Acc: 'text-center',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Cnt: 'text-center',
                    Charolas: 'text-center',
                    Valor: 'text-center',
                    Cantidad: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            idUpdate:0,
            registro:{},
            selected:{label:'',value:0},
            cabecera:4,
            valor:1,
            cantidad:[{valor:.2,cantidad:1},{valor:.4,cantidad:2},{valor:.6,cantidad:3},{valor:.8,cantidad:4},
                {valor:10,cantidad:5},{valor:.25,cantidad:1},{valor:.50,cantidad:2},{valor:.75,cantidad:3},
                {valor:1,cantidad:4}]
        }
    },
    mounted() {
        this.$refs.filtersReport.shows.trades = false;
        this.$refs.filtersReport.shows.texhibicion = false;
        this.$refs.filtersReport.shows.departamentos = false;
        this.$refs.filtersReport.shows.fabricantes = false;
        this.$refs.filtersReport.shows.marcas = false;
        this.$refs.filtersReport.shows.productos = false;
        this.$refs.filtersReport.shows.respuestas = false;
        this.$refs.filtersReport.shows.campanas = true;
    },
    methods:{
        changeValor(){
            console.log(this.cabecera)
            if (this.cabecera === 4 ){
                this.valor=1;
            }
            else{
                this.valor=10;
            }
            console.log(this.valor)
        },
        cambioTE(){
            if (this.$refs.newTpoExh.selectedExhibicioness !== null){
                this.selected.value = this.$refs.newTpoExh.selectedExhibicioness.value;
                this.selected.label = this.$refs.newTpoExh.selectedExhibicioness.label;
            }
        },
        async saveEdit(){
            if (this.$refs.newTpoExh.selectedExhibicioness === null){
                this.$toastMessage('error',"Seleccione el tipo de exhibición, para realizar la actualización",4000,'top-right');
            }
            else{
                const resultado = this.tableDataExhibiciones.findIndex( data => data.Cverepexh === this.idUpdate );
                const resultadoV = this.cantidad.findIndex( data => data.valor === this.valor );
                this.tableDataExhibiciones[resultado].Cveentexh = this.$refs.newTpoExh.selectedExhibicioness.value;
                this.tableDataExhibiciones[resultado].Exhibicion = this.$refs.newTpoExh.selectedExhibicioness.label;
                if (this.$refs.newTpoExh.selectedExhibicioness.value === 3){
                    this.tableDataExhibiciones[resultado].Cantidad = 0;
                    this.tableDataExhibiciones[resultado].Charolas = this.cabecera;
                    this.tableDataExhibiciones[resultado].Cnt = this.cantidad[resultadoV].cantidad;
                    this.tableDataExhibiciones[resultado].Valor = this.cantidad[resultadoV].valor === 10 ? 1 : this.cantidad[resultadoV].valor;
                }else{
                    this.tableDataExhibiciones[resultado].Charolas = 0;
                    this.tableDataExhibiciones[resultado].Cnt = 0;
                    this.tableDataExhibiciones[resultado].Valor = 0;
                }
                var response = await this.$peticionesApi('/api/Exhibiciones/editRecord',{data:this.registro,
                    exhibicion:this.$refs.newTpoExh.selectedExhibicioness});
                if (response!==null){
                    this.$refs.closeModal.click();
                    this.$toastMessage('success',response.Mensaje,4000,'center');

                }
            }

        },
        async updateRegistro(id){
            this.idUpdate = id;
            this.loadRegisterUpdate = true;
            const resultado = await this.tableDataExhibiciones.find( data => data.Cverepexh === id );
            this.loadRegisterUpdate = false;
            this.registro = resultado;
            this.selected.label = this.registro.Exhibicion;
            this.selected.value = this.registro.Cveentexh;
            this.$refs.newTpoExh.selectedExhibicioness=this.selected;

            //console.log(resultado);
        },
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        async getReport(){
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else if (Date.parse(filters.timeEnd) < Date.parse(filters.timeInit)){
                this.$toastMessage('error',"Seleccione una fecha fin mayor a la fecha inicial!",4000,'center');
            }else if (filters.campanaReal === null){
                this.$toastMessage('error',"Seleccione una Campaña para realizar la busqueda!",4000,'center');
            }else{
                this.loadfile = true;
                this.showData = false;
                this.showResult = false;
                this.loadingGetData = true;
                this.tableDataExhibiciones = [];
                const response = await this.$peticionesApi('/api/Campanas/PeticionesTabla', filters);
                if (response != null ){
                    this.loadfile = false;
                    this.showData = true;
                    this.tableDataExhibiciones = response.Data;
                    this.showResult = true;
                    this.loadingGetData = false;
                }

            }
        },
        async ExportFile(){
            this.loadfile = true;
            await this.$peticionesApiFilesDownload('/api/Campanas/exportLayoutToExcel','CampañaAtenea.xlsx',this.tableDataExhibiciones);
            this.loadfile=false;
        },
    }
}
</script>

<style scoped>

</style>
