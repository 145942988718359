var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',[_vm._v("Fecha de inicio:")]),_c('br'),_vm._v(" "),_c('date-picker',{attrs:{"valueType":"'YYYY-MM-DD'","lang":_vm.lang},model:{value:(_vm.filters.timeInit),callback:function ($$v) {_vm.$set(_vm.filters, "timeInit", $$v)},expression:"filters.timeInit"}}),_vm._v(" "),_c('br'),_c('label',[_vm._v("Fecha de termino:")]),_c('br'),_vm._v(" "),_c('date-picker',{attrs:{"valueType":"'YYYY-MM-DD'","lang":_vm.lang},model:{value:(_vm.filters.timeEnd),callback:function ($$v) {_vm.$set(_vm.filters, "timeEnd", $$v)},expression:"filters.timeEnd"}}),_c('br'),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Agencias:")]),_vm._v(" "),_c('multiselect',{ref:"selectAgencia",attrs:{"options":_vm.optionsAgencias,"multiple":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name"},on:{"input":_vm.SelectAgencia},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectTodasAgencias ? 'Todas' : values.length > 1 ? values.length + ' agencias' : values.length + ' agencia'))]):_vm._e()]}}]),model:{value:(_vm.filters.agencias),callback:function ($$v) {_vm.$set(_vm.filters, "agencias", $$v)},expression:"filters.agencias"}}),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Areas:")]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.optionsAreasReal,"multiple":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name"},on:{"input":_vm.SelectArea},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectTodasAreas ? 'Todas' : values.length > 1 ? values.length + ' areas' : values.length + ' area')+" ")]):_vm._e()]}}]),model:{value:(_vm.filters.areas),callback:function ($$v) {_vm.$set(_vm.filters, "areas", $$v)},expression:"filters.areas"}}),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Tipo Gestor:")]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.optionsTiposGestores,"multiple":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name"},on:{"input":_vm.SelectTipoGestor},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectTodosTiposGestores ? 'Todos' : values.length > 1 ? values.length + ' tipos' : values.length + ' tipo'))]):_vm._e()]}}]),model:{value:(_vm.filters.tiposGestores),callback:function ($$v) {_vm.$set(_vm.filters, "tiposGestores", $$v)},expression:"filters.tiposGestores"}}),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Gestores:")]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.optionsGestoresReal,"multiple":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name"},on:{"input":_vm.SelectGestor},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectTodosGestores ? 'Todos' : values.length > 1 ? values.length + ' gestores' : values.length + ' gestor'))]):_vm._e()]}}]),model:{value:(_vm.filters.gestores),callback:function ($$v) {_vm.$set(_vm.filters, "gestores", $$v)},expression:"filters.gestores"}}),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Estados:")]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.optionsEstadosReal,"multiple":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name"},on:{"input":_vm.SelectEstado},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectTodosEstados ? 'Todos' : values.length > 1 ? values.length + ' estados' : values.length + ' estado'))]):_vm._e()]}}]),model:{value:(_vm.filters.estados),callback:function ($$v) {_vm.$set(_vm.filters, "estados", $$v)},expression:"filters.estados"}}),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Ciudades:")]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.optionsCiudadesReal,"multiple":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name"},on:{"input":_vm.SelectCiudad},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectTodasCiudades ? 'Todas' : values.length > 1 ? values.length + ' ciudades' : values.length + ' ciudad'))]):_vm._e()]}}]),model:{value:(_vm.filters.ciudades),callback:function ($$v) {_vm.$set(_vm.filters, "ciudades", $$v)},expression:"filters.ciudades"}}),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Canales:")]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.optionsCanalesReal,"multiple":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name"},on:{"input":_vm.SelectCanal},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectTodosCanales ? 'Todos' : values.length > 1 ? values.length + ' canales' : values.length + ' canal'))]):_vm._e()]}}]),model:{value:(_vm.filters.canales),callback:function ($$v) {_vm.$set(_vm.filters, "canales", $$v)},expression:"filters.canales"}}),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Cadenas:")]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.optionsCadenaReal,"multiple":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name"},on:{"input":_vm.SelectCadena},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectTodasCadenas ? 'Todos' : values.length > 1 ? values.length + ' cadenas' : values.length + ' cadena'))]):_vm._e()]}}]),model:{value:(_vm.filters.cadenas),callback:function ($$v) {_vm.$set(_vm.filters, "cadenas", $$v)},expression:"filters.cadenas"}}),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Formatos:")]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.optionsFormatosReal,"multiple":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name"},on:{"input":_vm.SelectFormato},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectTodosFormatos ? 'Todos' : values.length > 1 ? values.length + ' formatos' : values.length + ' formato'))]):_vm._e()]}}]),model:{value:(_vm.filters.formatos),callback:function ($$v) {_vm.$set(_vm.filters, "formatos", $$v)},expression:"filters.formatos"}}),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Tiendas:")]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.optionsTiendasReal,"multiple":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name"},on:{"input":_vm.SelectTienda},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectTodasTiendas ? 'Todos' : values.length > 1 ? values.length + ' tiendas' : values.length + ' tienda'))]):_vm._e()]}}]),model:{value:(_vm.filters.tiendas),callback:function ($$v) {_vm.$set(_vm.filters, "tiendas", $$v)},expression:"filters.tiendas"}}),_vm._v(" "),_c('label',{staticClass:"typo__label"},[_vm._v("Estatus Tienda:")]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.optionsEstatus,"multiple":false,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Seleccione:","label":"name","track-by":"name","preselect-first":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" options selected")]):_vm._e()]}}]),model:{value:(_vm.filters.estatusTienda),callback:function ($$v) {_vm.$set(_vm.filters, "estatusTienda", $$v)},expression:"filters.estatusTienda"}}),_vm._v(" "),_c('br'),_c('br'),_c('br'),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }