<template>
    <div class="border">
        <br><br>
        <div class="text-center">
            <h2><span class="text-primary">FALTANTES</span></h2>
        </div>
        <br>
        <vue-tabs active-tab-color="#c3a900"
                  active-text-color="white"
                  type="pills"
                  :start-index="1">
            <v-tab title="REPORTE - POR DETALLE"><br>
                <detalle-faltantes></detalle-faltantes>
            </v-tab>

            <v-tab title="REPORTE - POR CADENA 80/20"><br>
                <ochenta-faltantes></ochenta-faltantes>
            </v-tab>
        </vue-tabs>
    </div>
</template>

<script>
export default {
    name: "tabsFaltantes"
}
</script>

<style scoped>

</style>
