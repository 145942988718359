/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

////////////////// DASHBOARD
//// FALTANTES
Vue.component('dashboard-component', require('./app/Dashboard/contenedor').default); // CONTENDER DEL DASHBOARD
Vue.component('tabs-faltantes', require('./app/Dashboard/Faltantes/tabsFaltantes').default); // TABS FALTANTES
Vue.component('selectanio-faltantes', require('./components/filters/selectAnioSemana').default); // SELECT ANIO SEMANAS
Vue.component('selectaniosemana-faltantes', require('./components/filters/selectAnioSemanaF').default); // SELECT ANIO SEMANAS
Vue.component('detalle-faltantes', require('./app/Dashboard/Faltantes/faltantesDetalle').default); // FALTANTES DETALLE
//Vue.component('detalle-faltantesnew', require('./app/Dashboard/Faltantes/faltantesDetalleNew').default); // FALTANTES DETALLE
Vue.component('ochenta-faltantes', require('./app/Dashboard/Faltantes/faltantesOchenta').default); // FALTANTES DETALLE
//// EXHIBICIONES
Vue.component('semana-exhibiciones', require('./app/Dashboard/ExhibicionesSemana/reporteExhibicionesSemana').default); // Exhibiciones Semana DETALLE
Vue.component('tablads-exhibiciones', require('./app/Dashboard/ExhibicionesTable/report').default); // Exhibiciones Semana DETALLE Tabla
//// SHARE EXHIBICIONES
Vue.component('share-exhibiciones', require('./app/Dashboard/ExhibicionesSemana/shareExhibiciones').default); // Exhibiciones Semana DETALLE Tabla
Vue.component('share-grafica', require('./app/Dashboard/ExhibicionesSemana/chartShare').default); // Exhibiciones Semana DETALLE Tabla
Vue.component('reporte-eficiencia', require('./app/Dashboard/Eficiencia/reporte').default); // Eficiencia reporte contenedor
Vue.component('reporte-general', require('./app/Dashboard/Eficiencia/general').default); // Eficiencia reporte general
Vue.component('region-eficiencia', require('./app/Dashboard/Eficiencia/region').default); // Eficiencia reporte region
Vue.component('distrito-eficiencia', require('./app/Dashboard/Eficiencia/distrito').default); // Eficiencia reporte distrito
//// MATERIAL POP
Vue.component('administracion-pop', require('./app/MaterialPOP/AdministracionPop').default); // material pop administracion
Vue.component('dashboard-pop', require('./app/MaterialPOP/dashboardPop').default); // material pop dashboard
//// CAMPANAS
Vue.component('campanas-crear', require('./app/Campanas/crearCampana').default); // CREAR CAMAPAÑAS ATENEA
Vue.component('campanas-tabla', require('./app/Campanas/tabla').default); // CREAR CAMAPAÑAS ATENEA TABLA
Vue.component('campanas-tabla-data', require('./app/Campanas/tablaDatos').default); // CREAR CAMAPAÑAS ATENEA TABLA
//// ENCUESTAS
Vue.component('encuestas-contenedor', require('./app/Dashboard/Encuestas/contenedor').default); // Eficiencia reporte distrito
//Vue.component('encuestas-general', require('./app/Dashboard/Encuestas/general').default); // Eficiencia reporte distrito
//Vue.component('encuestas-detalle', require('./app/Dashboard/Encuestas/detalle').default); // Eficiencia reporte distrito
//Vue.component('encuestas-galeria', require('./app/Dashboard/Encuestas/galeria').default); // Eficiencia reporte distrito
//// ADMINISTRACIÓN MAIN
////////////////// ADMINISTRACIÓN
//// ADMINISTRACIÓN MAIN
Vue.component('mainr-component', require('./app/Administracion/Main/mainr').default); // Acciones main
Vue.component('seguimientops-component', require('./app/Administracion/Seguimiento/seguimientoPS').default); // Seguimiento Promotores Supervisores main
//// USUARIOS
Vue.component('tableusers-component', require('./app/Administracion/Usuarios/tableUsers').default); // table data
Vue.component('modalnewuser-component', require('./app/Administracion/Usuarios/newUser').default); // modal new user
Vue.component('addpermisos-component', require('./app/Administracion/Usuarios/editPermisos').default); // modal add permiosos
Vue.component('modaleditser-component', require('./app/Administracion/Usuarios/editUser').default); // edit user
//// KPI
Vue.component('kpi-component', require('./app/Kpi/kpi').default); // KPI
Vue.component('kpi-sams-component', require('./app/Kpi/sams.vue').default); // KPI SAMS
Vue.component('kpi-sams-table-component', require('./app/Kpi/tablaSams.vue').default); // KPI SAMS tabla
Vue.component('kpi-sams-dash-component', require('./app/Kpi/dashKpiSams.vue').default); // KPI SAMS Dash tabla
//// PERMISOS
Vue.component('permisos-component', require('./app/Administracion/Permisos/permisosUsuarios').default); // edit user
//// MODULOS
Vue.component('modulos-component', require('./app/Administracion/Modulos/modulos').default); // show modules app Atenea
//// REPORTE RUTAS
Vue.component('reporterutas-component', require('./app/Administracion/ReporteRutas/report').default);
Vue.component('reportetiendas-component', require('./app/Administracion/ReporteRutas/reporttiendas').default);
//// SEMAFORO RUTAS
Vue.component('semaforo-rutas', require('./app/Administracion/SemaforoRutas/semaforo').default); // Semaforo Rutas
//// ADMINISTRACIÓN TIENDAS
Vue.component('tiendasadmin-component', require('./app/Administracion/Tiendas/admtiendas').default); // Administración Tiendas
Vue.component('tiendas-component', require('./app/Administracion/Tiendas/tiendas').default); // Acciones Tiendas
Vue.component('cancadfmt-component', require('./app/Administracion/Tiendas/canalcadenaformato').default); // Acciones CANALES CADENAS FORMATOS
//Vue.component('ciudades-component', require('./app/Administracion/Tiendas/ciudades').default); // Acciones CIUDADES
//Vue.component('layouthermes-component', require('./app/Administracion/Tiendas/importLayout').default); // Acciones LAYOUT hermes
//Vue.component('layoutsegso-component', require('./app/Administracion/Tiendas/importSegmentacion').default); // Acciones LAYOUT SEGMENTACIÓN  Y SO
//// ADMINISTRACIÓN PRIORIDADES IMAGENES
//Vue.component('prioridades-imagenes-component', require('./app/Administracion/Prioridades/FormularioPrioridades').default); // FORMULARIO PRIORIDADES
//// REPORTE COMENTARIOS
Vue.component('comentarios-component', require('./app/Administracion/reporteComentarios/tabla').default); // TABLA COMENTARIOS
//// ADMINISTRACIÓN PRODUCTOS
Vue.component('productos-component', require('./app/Administracion/Productos/productos').default); // Acciones de administración de productos
//Vue.component('productostable-component', require('./app/Administracion/Productos/tableptos').default); // Tabla and admin de productos
//Vue.component('importproductos-component', require('./app/Administracion/Productos/importLayout').default); // IMPORTA LOS PRODUCTOS DE PROMETEO Y VALIDA CON ATENEA
//Vue.component('catalogospto-component', require('./app/Administracion/Productos/catalogos').default); // Administración de catálogos ATENEA
Vue.component('logactividad-component', require('./app/Administracion/LogActividad/logAactividad').default); // muestra los acceso a Atenea
/////////////////////Administración permisos rol aplicación
Vue.component('usrperapp-component', require('./app/Administracion/PermisosAplicacion/UsuariosPermisosAplicacion').default);//permisos para los usuario en la aplicación ATENEA
////////////////// FINALIZA ADMINISTRACIÓN
////////////////// INICIA ENCUESTAS
Vue.component('crear-encuestas', require('./app/Encuestas/crearEncuesta').default); // Crear Encuestas
Vue.component('tabs-encuestas', require('./app/Encuestas/pasos/encuestasTabs').default); // TABS Encuestas
//Vue.component('tabscrear-encuestas', require('./app/Encuestas/pasos/crearEncuesta').default); // Crear Encuestas
//Vue.component('tabsload-encuestas', require('./app/Encuestas/pasos/loadEncuestas').default); // Cargar Encuestas
Vue.component('autorizar-encuestas', require('./app/Encuestas/aturizarEncuesstas').default); // Autorizar Encuestas
Vue.component('ver-encuestas', require('./app/Encuestas/verEncuesstas').default); // Ver Encuestas
Vue.component('tabla-encuestas', require('./app/Encuestas/tabla').default); // Tabla de Encuestas
Vue.component('reporte-encuestas', require('./app/Encuestas/estadisticas/reporte').default); // Reporte de Encuestas estadisticas
Vue.component('reporte-evaluacion', require('./app/Encuestas/Evaluacion/evaluacion').default); // Evaluación de ejecución
////////////////// FINALIZA ENCUESTAS
////////////////// INICIA ANAQUEL
Vue.component('tabla-anaquel', require('./app/Anaquel/tabla').default); // Tabla de Anaquel
Vue.component('productos-anaquel', require('./app/Anaquel/productosMedidas').default); // Productos medidas anaquel
////////////////// FINALIZA ANAQUEL
////////////////// INICIA RUTERO
Vue.component('contenedor-rutero', require('./app/Rutero/contenedor').default); // contenedor rutero
Vue.component('importar-rutero', require('./app/Rutero/importar').default); // Reporte rutero
Vue.component('actualizar-rutero', require('./app/Rutero/actualizar').default); // Reporte rutero actualizar rutas
////////////////// FINALIZA RUTERO
////////////////// INICIA FALTANTES
Vue.component('faltantessemaforo-component', require('./app/Faltantes/semaforo').default); // Semaforo Faltantes
Vue.component('tabla-faltantes', require('./app/Faltantes/tabla').default); // Tabla de Faltantes
////////////////// FINALIZA FALTANTES
////////////////// INICIA PROMOCIONES
Vue.component('tabla-promociones', require('./app/Promociones/tabla').default); // Tabla de Promociones
////////////////// FINALIZA PROMOCIONES
////////////////// INICIA PRECIOS
Vue.component('tabla-precios', require('./app/Precios/tabla').default); // Tabla de Precios
Vue.component('tabla-precios-mayoreo', require('./app/Precios/tablaMayoreo').default); // Tabla de Precios Mayoreo
////////////////// FINALIZA PRECIOS
//////////////// INICIA EXHIBICIONES
Vue.component('tabla-exhibiciones-lideres', require('./app/Exhibiciones/lideresReporte.vue').default); // Tabla de Exhibiciones para los lideres
Vue.component('tabla-exhibiciones-data', require('./app/Exhibiciones/dataReporte.vue').default); // Tabla de Exhibiciones para los lideres
Vue.component('tabla-exhibiciones', require('./app/Exhibiciones/tabla').default); // Tabla de Exhibiciones
Vue.component('shareresumen-exhibiciones', require('./app/Exhibiciones/share').default); // Tabla de Exhibiciones sahre mes semana
////////////////// FINALIZA EXHIBICIONES
//////////////// INICIA DETALLE DE INFORMACIÓN
Vue.component('tabla-resumen-informacion', require('./app/DetalleInformacion/tablaResumen').default); // Tabla de Resumen de información
Vue.component('tabla-dashboard-informacion', require('./app/DetalleInformacion/dashboardEfectividad').default); // Tabla de Dashboard de información
Vue.component('tabla-detalle-informacion', require('./app/DetalleInformacion/detalleInformacion').default); // Tabla de detalle de información
////////////////// FINALIZA DETALLE DE INFORMACIÓN
////////////////// INICIA REPORTE GRAFICO
/////// IMAGENES
Vue.component('reporte-grafico', require('./app/ReporteGrafico/Galeria/galeria').default); // muestra las imagenes registradas en el sistema
Vue.component('tipo-galeria', require('./app/ReporteGrafico/Galeria/tipoGaleria').default); // componente que muta de acuero al tipo de galeria a consultar
/////// Tabla
Vue.component('tabla-reportegrafico', require('./app/ReporteGrafico/tabla').default); // Muestra la tabla de resmen de captura
////////////////// FINALIZA REPORTE GRAFICO
////////////////// INICIA ACTIVIDADES
////////////////// INICIA ACTIVIDADES
////////////Inventario
//Vue.component('inventario-registro', require('./app/Actividad/Inventario/register').default); // registro de inventario en página web
//Vue.component('inventario-reporte', require('./app/Actividad/Inventario/reporte').default); // reporte de inventario en página web
////////////Ventas
//Vue.component('ventas-registro', require('./app/Actividad/Ventas/register').default); // registro de ventas en página web
//Vue.component('ventas-reporte', require('./app/Actividad/Ventas/reporte').default); // reporte de ventas en página web
////////////Resurtido
//Vue.component('resurtidos-registro', require('./app/Actividad/Resurtidos/register').default); // registro de resurtidos en página web
//Vue.component('resurtidos-reporte', require('./app/Actividad/Resurtidos/reporte').default); // reporte de resurtidos en página web
////////////////// FINALIZA ACTIVIDADES


////////////////// COMPONENTES GLOBALES PARA ATENEA
Vue.component('load-txtapp', require('./app/Administracion/loadtxt').default); // carga el archivo txt para validar las pruebas del envió de datos
Vue.component('filtrosreporterutas-component', require('./components/FilterReporteRutas').default); // Filtros para atenea Reporte Rutas!
//Vue.component('filtros-exhibiciones', require('./components/FilterReporteExhibiciones').default); // Filtros para atenea Reporte Exhibiciones!
//Vue.component('filtros-anaquel', require('./components/FilterReporteAnaquel').default); // Filtros para atenea Reporte Exhibiciones!
//Vue.component('filtros-precios', require('./components/FilterReportePrecios').default); // Filtros para atenea Reporte Exhibiciones!
//Vue.component('filtros-promociones', require('./components/FilterReportePromos').default); // Filtros para atenea Reporte Exhibiciones!
//Vue.component('filtros-fotografico', require('./components/FilterReporteFotografico').default); // Filtros para atenea Reporte Exhibiciones!
//Vue.component('filtros-faltantes', require('./components/FilterReporteFalantes').default); // Filtros para atenea Reporte Exhibiciones!
//Vue.component('filtrossemaforofaltantes-component', require('./components/FilterSemaforoFaltantes').default); // Filtros para atenea Semáforo Faltantes!
Vue.component('keyboard-events', require('./components/EventsKeyUp').default); // Eventos de key up en el documento!
Vue.component('gestores-component', require('./components/generales/gestores').default); // Gestores de ATENEA
Vue.component('tiposgestores-component', require('./components/generales/tipogestores').default); // Tipos Gestores de ATENEA
//Vue.component('chosenrolsupervisor-component', require('./components/generales/chosenRolSupervisor').default); // get choseen roles ATENEA
Vue.component('cadenas-component', require('./components/generales/cadenas').default); // Cadenas de ATENEA
Vue.component('formatos-component', require('./components/generales/formatos').default); // Formatos de ATENEA
Vue.component('tiendasmain-component', require('./components/generales/tiendas').default); // Tiendas de ATENEA requiere formato
Vue.component('tiendas-select', require('./components/generales/tiendasGeneral').default); // Tiendas de ATENEA completas sin filtros
Vue.component('fabricantes-select', require('./components/generales/fabricantes').default); // Fabricantes de ATENEA
Vue.component('departamentos-select', require('./components/generales/departamentos').default); // Departamentos de ATENEA
Vue.component('marcas-select', require('./components/generales/marcas').default); // Marcas de ATENEA
Vue.component('categorias-select', require('./components/generales/categorias').default); // Categorías de ATENEA
Vue.component('productos-select', require('./components/generales/productos').default); // Productos de ATENEA
Vue.component('download-app', require('./components/generales/downloadapp').default); // Descarga app móvil de ATENEA
Vue.component('download-invitado', require('./components/generales/downloadinvitado').default); // Descarga app móvil de ATENEA INVITADO KCM
Vue.component('select-encuesta', require('./components/generales/encuestas').default); // filtro para encuestas
Vue.component('select-agencias', require('./components/generales/agencias').default); // filtro para agencias
Vue.component('select-canal', require('./components/generales/canal').default); // filtro para canales
Vue.component('select-cadena', require('./components/generales/cadenasSelect').default); // filtro para cadenas
Vue.component('select-formato', require('./components/generales/formatosSelect').default); // filtro para formatos
Vue.component('select-clasificacion', require('./components/generales/clasificacion').default); // filtro para clasificación alto impacto - impulso
Vue.component('select-tipoexhibicion', require('./components/generales/tipoExhibicion').default); // filtro para clasificación alto impacto - impulso
Vue.component('select-areas', require('./components/generales/areas').default); // filtro para clasificación alto impacto - impulso
Vue.component('select-anios', require('./components/generales/anios').default); // filtro para clasificación alto impacto - impulso
Vue.component('select-mes', require('./components/generales/mes').default); // filtro para clasificación alto impacto - impulso
Vue.component('select-semanas', require('./components/generales/semanas').default); // filtro para clasificación alto impacto - impulso
Vue.component('select-region', require('./components/generales/region').default); // filtro para clasificación alto impacto - impulso
Vue.component('select-distrito', require('./components/generales/distrito').default); // filtro para clasificación alto impacto - impulso
Vue.component('select-roles', require('./components/filters/rolesatenea').default); // get roles atenea
////////////////// FINALIZA COMPONENTES GLOBALES PARA ATENEA
////////////////// INICIA FILTROS GLOBALES PARA ATENEA CON TEXTOS
Vue.component('select-areasN', require('./components/filters/areas').default); // filtro para agencias nuevo con texto
Vue.component('select-agenciasN', require('./components/filters/agencias').default); // filtro para agencias nuevo con texto
Vue.component('select-estadosN', require('./components/filters/estados').default); // filtro para estados nuevo con texto
Vue.component('select-ciudadesN', require('./components/filters/ciudades').default); // filtro para ciudades nuevo con texto
Vue.component('select-distritosN', require('./components/filters/distritos').default); // filtro para distritos nuevo con texto
Vue.component('select-canalesN', require('./components/filters/canal').default); // filtro para estados nuevo con texto
Vue.component('select-canalesNM', require('./components/filters/canalM').default); // filtro para estados nuevo con texto
Vue.component('select-cadenasN', require('./components/filters/cadenas').default); // filtro para estados nuevo con texto
Vue.component('select-cadenas-ex', require('./components/filters/cadenasExh').default); // filtro para estados nuevo con texto
Vue.component('select-formatosN', require('./components/filters/formatos').default); // filtro para estados nuevo con texto
Vue.component('select-tiendasN', require('./components/filters/tiendas').default); // filtro para estados nuevo con texto
Vue.component('select-departamentosN', require('./components/filters/departamentos').default); // filtro para estados nuevo con texto
Vue.component('select-categoriasN', require('./components/filters/categorias').default); // filtro para estados nuevo con texto
Vue.component('select-categoriasShare', require('./components/filters/categoriasShare').default); // filtro para estados nuevo con texto
Vue.component('select-promotoresN', require('./components/filters/promotores').default); // filtro para estados nuevo con texto
Vue.component('select-usuariosAtenea', require('./components/filters/usuariosAtenea').default); // filtro de usuarios atenea
Vue.component('select-fabricantesN', require('./components/filters/fabricantes').default); // filtro para estados nuevo con texto
Vue.component('select-marcasN', require('./components/filters/marcas').default); // filtro para estados nuevo con texto
Vue.component('select-productosN', require('./components/filters/productos').default); // filtro para estados nuevo con texto
Vue.component('select-tradesN', require('./components/filters/trades').default); // filtro para estados nuevo con texto
Vue.component('select-encuestasN', require('./components/filters/encuestas').default); // filtro para las encuestas vigentes
Vue.component('select-matpop', require('./components/filters/materialpop').default); // filtro para las materiales pop en proceso
Vue.component('select-campanas', require('./components/filters/campanas').default); // filtro para estados nuevo con texto
Vue.component('select-texhibicionN', require('./components/filters/tipoExhibicion').default); // filtro para estados nuevo con texto
Vue.component('select-promocionN', require('./components/filters/tipoPromocion').default); // filtro para estados nuevo con texto
Vue.component('select-responsablesN', require('./components/filters/responsableselect').default); // filtro para los responsables
Vue.component('filters-general', require('./components/filters/filtrosGeneral').default); // general para faltantes
Vue.component('filters-general-mayoreo', require('./components/filters/filtrosGeneralMayoreo').default); // general para faltantes
Vue.component('filters-tipo-encuesta', require('./components/filters/tipoEncuesta').default); // tipo encuesta a crear
Vue.component('filters-tipo-encuesta-seguimiento', require('./components/filters/tipoEncuestaSeguimiento').default); // tipo encuesta a crear
Vue.component('kpi-select', require('./components/filters/kpiselect').default); // kpi select
Vue.component('descarga-respaldos', require('./app/Administracion/respaldos/descargarFicheros.vue').default); // descarga de respaldos
//Vue.component('semana-select', require('./components/filters/semanaselect').default); // semana select
////////////////// FINALIZA FILTROS GLOBALES PARA ATENEA


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//// GLOGAL COMPONENTES
//VUE TABLES
import {ServerTable, ClientTable, Event} from 'vue-tables-2';

Vue.use(ClientTable);
Vue.use(ServerTable);
Vue.use(Event);
//SWEET ALERT 2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
// VUE TOOLTIP
import VTooltip from 'v-tooltip';
Vue.use(VTooltip);
//FORM WIZARD
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
Vue.use(VueFormWizard);
// VUELIDATE
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);
// VUE MULTI SELECT
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
Vue.component('multiselect', Multiselect);
// VUE DATEPICKER
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
Vue.component('DatePicker', DatePicker);
//VUE NAV TABS
import VueTabs from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
Vue.use(VueTabs);
import VueHighcharts from 'highcharts-vue';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import exportingData from 'highcharts/modules/export-data';
import loadDrillDown from 'highcharts/modules/drilldown';
import loadSolidGauge from 'highcharts/modules/solid-gauge';
import Highcharts3D from 'highcharts/highcharts-3d';
import heatmap from 'highcharts/modules/heatmap';
import pyramid3d from 'highcharts/modules/pyramid3d';
import funnel from 'highcharts/modules/funnel';
import funnel3d from 'highcharts/modules/funnel3d';
import cylinder from 'highcharts/modules/cylinder';
import loadHighchartsMore from 'highcharts/highcharts-more';
import accesibility from 'highcharts/modules/accessibility';
import stockInit from 'highcharts/modules/stock';

stockInit(Highcharts);
loadDrillDown(Highcharts);
exportingInit(Highcharts);
exportingData(Highcharts);
Highcharts3D(Highcharts);
heatmap(Highcharts);
loadHighchartsMore(Highcharts);
loadSolidGauge(Highcharts);
funnel(Highcharts);
funnel3d(Highcharts);
cylinder(Highcharts);
pyramid3d(Highcharts);
accesibility(Highcharts);
Vue.use(VueHighcharts, { Highcharts });
/// v-select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);
// momment
Vue.use(require('vue-moment'));
/// zoom image
import VueZoomer from 'vue-zoomer';
Vue.use(VueZoomer);
/// vue pretty checkbox
import 'pretty-checkbox/src/pretty-checkbox.scss';
import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox);


/// vue calendar
import VCalendar from 'v-calendar';
Vue.use(VCalendar);

import axios from 'axios';
axios.defaults.headers.post['Authorization'] = Token.Token;

///////////////////////////////////////// --------------------> INICIA EL REGISTRO DE FUNCIONES GLOBALES
Vue.prototype.$toastMessage = function toastMessage(type, text, duration,position) {
    this.Toast = this.$swal.mixin({
        toast: true, position: position,
        showConfirmButton: false, timer: duration
    });
    this.Toast.fire({
        icon: type,
        title: text
    });
}
//// PETICIONES POST WEBSERVICES ATENEA
Vue.prototype.$peticionesApi = async function(url, vars) {
    try {
        const resData = await axios.post(url, {data: vars});
        return resData.data;
    }
    catch (error) {
        let mensaje = "", type = "";
        switch (error.response.status) {
            case 500:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url}`;
                type = "error";
                break
            case 403:
                mensaje = `Sesión finalizada, por favor inicie sesión nuevamente. :)`;
                type = "warning";
                break
            default:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url} <br> Estatus: ${error.response.status}`;
                type = "error";
        }
        this.$toastMessage(type, `Error: ${mensaje}`, 4000, 'center');
        if (error.response.status === 403) {
            setTimeout(window.location.href = "/login", 45000);
        }
        return null;
    }
}
//// PETICIONES POST WEBSERVICES ATENEA FILES UPLOAD
Vue.prototype.$peticionesApiFiles = async function(url, formdata) {
    try {
        const resData = await axios.post(url, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return resData.data;
    } catch (error) {
        let mensaje = "", type = "";
        switch (error.response.status) {
            case 500:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url}`;
                type = "error";
                break
            case 403:
                mensaje = `Sesión finalizada, por favor inicie sesión nuevamente. :)`;
                type = "warning";
                break
            default:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url} <br> Estatus: ${error.response.status}`;
                type = "error";
        }
        this.$toastMessage(type, `Error: ${mensaje}`, 4000, 'center');
        if (error.response.status === 403) {
            setTimeout(window.location.href = "/login", 45000);
        }
        return [];
    }
}
//// PETICIONES POST WEBSERVICES ATENEA FILES DOWNLOAD
Vue.prototype.$peticionesApiFilesDownload = async function(url, nameFile, dataProccess) {
    try {
        await axios({
            method: 'post',
            url: url,
            responseType: 'arraybuffer',
            data: { data: dataProccess } }).then(r => {
                let blob = new Blob([r.data], { type: 'application/xlsx' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = nameFile
                link.click()
            });

    }
    catch (error) {
        let mensaje = "", type = "";
        switch (error.response.status) {
            case 500:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url}`;
                type = "error";
                break
            case 403:
                mensaje = `Sesión finalizada, por favor inicie sesión nuevamente. :)`;
                type = "warning";
                break
            default:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url} <br> Estatus: ${error.response.status}`;
                type = "error";
        }
        this.$toastMessage(type, `Error: ${mensaje}`, 4000, 'center');
        if (error.response.status === 403) {
            setTimeout(window.location.href = "/login", 45000);
        }
        return [];
    }
}
///////////////////////////////////////// --------------------> FINALIZA EL REGISTRO DE FUNCIONES GLOBALES

const app = new Vue({
    el: '#app',
});
