<template>
    <div>
        <v-select :multiple="false" :options="optionsActividad" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedActividad" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "selectActividad",
    props:{
        placeholderm:String,
        sinactividad:{
            default: 0, required: true, type: Number
        }
    },
    data(){
        return{
            selectedActividad:{
                "value":0, "label":"TODAS"
            },
            optionsActividad:[],
        }
    },
    mounted() {
        this.getAreas();
    },
    methods:{
        getAreas(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/actividades',{dato:this.sinactividad} )
                .then(function (r){
                    $this.optionsActividad = r.data.Data;
                });
        },
        setCambio(){
            this.$emit('cambioActividad');
        }

    }
}
</script>

<style scoped>

</style>
