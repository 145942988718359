<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-md-3">
                <label>Fecha de inicio:</label><br>
                <date-picker v-model="filters.timeInit" valueType="'YYYY-MM-DD'"></date-picker>
                <br><label>Fecha de termino:</label><br>
                <date-picker v-model="filters.timeEnd" valueType="'YYYY-MM-DD'"></date-picker><br>
                <label><b>Rol usuario:</b></label>
                <select-roles placeholderm="Rol usuario" v-on:cambioRol="" ref="selectRol"></select-roles>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-center">
                        <button class="btn ripple btn-primary" @click="getReport">Generar Reporte</button>
                    </div>
                    <div class="col-md-4" v-if="showResult">
                        <div v-if="loadfile">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando archivo, espere...</p>
                        </div>
                        <button v-if="!loadfile"  class="btn btn-success" type="button" @click="ExportFile"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12">
                        <div v-if="loadingGetData" class="text-center">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando información, espere...</p>
                        </div>
                    </div>
                    <div class="col-md-12" v-show="showResult">
                        <v-client-table :data="tableDataLog" :columns="columnsFaltantes" :options="optionsLog" class="table-responsive" ref="tableLogActividad">
                        </v-client-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "logAactividad",
    data(){
        return{
            loadfile:false,
            showResult:false,
            loadingGetData: false,
            showData: false,
            filters:{
                timeInit: null,
                timeEnd: null,
                roles:[],
            },
            columnsFaltantes: ['Fecha','FechaActividad','Area','Semana','Agencia','Rol','Usuario','Nombre','Actividad'],
            tableDataLog: [],
            optionsLog: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Actividad','Agencia','Nombre','Area'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                columnsDropdown:false,
                columnsClasses: {
                    Fecha: 'text-center font-bold text-danger',
                    Actividad: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    methods:{
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        changeUsuarios(){

        },
        async getReport(){
            this.filters.roles = this.$refs.selectRol.selectedRoles;
            if (this.filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (this.filters.timeEnd === null) {
                this.$toastMessage('error', "Fecha Termino Es Obligatoria", 4000, 'center');
            }
            else if (this.filters.roles.length === 0){
                    this.$toastMessage('error',"Selecciona un rol, para generar el reporte",4000,'center');
            }
            else if (Date.parse(this.filters.timeEnd) < Date.parse(this.filters.timeInit)){
                this.$toastMessage('error',"Seleccione una fecha fin mayor a la fecha inicial!",4000,'center');
            }
            else{
                this.loadingGetData = true;
                this.showData = false;
                this.showResult = false;
                this.tableDataLog = [];
                let r = await this.$peticionesApi('/api/Seguimiento/TablaLog',this.filters);
                if (r != null){
                    this.loadingGetData = false;
                    this.showData = true;
                    this.tableDataLog = r.Data;
                    this.showResult = true;
                }
            }
        },
        async ExportFile(){
            this.loadfile = true;
            await this.$peticionesApiFilesDownload('/api/Seguimiento/exportLayoutToExcelLog','LogActividadesAtenea.xlsx',this.tableDataLog);
            this.loadfile = false;
        },
    }
}
</script>

<style scoped>

</style>
