<template>
    <div>
        <vue-tabs active-tab-color="#c3a900"
                  active-text-color="white"
                  type="pills"
                  :start-index="1">

            <v-tab title="PRODUCTOS ATENEA MÉXICO">
                <br>
                <tableptosadmin></tableptosadmin>
            </v-tab>

            <v-tab title="CATALOGOS PRODUCTOS">
                <br>
                <catalogospto-component></catalogospto-component>
            </v-tab>
            <v-tab title="IMPORTAR PRODUCTOS">
                <br>
                <importproductos-component></importproductos-component>
            </v-tab>

        </vue-tabs>

    </div>
</template>

<script>
import tableptos from './tableptos.vue'
import catalogosptos from './catalogos.vue'
import importptos from './importLayout.vue'
export default {
    name: "productos",
    components:{
        'tableptosadmin': tableptos,
        'catalogospto-component': catalogosptos,
        'importproductos-component': importptos,
    }

}
</script>

<style scoped>

</style>
