<template>
    <div>
        <div class="row" v-show="!NoEdit">
            <div class="col-md-4" v-show="!showTable">
                <div class="text-center">
                    <button class="btn btn-primary" @click="formattedDate(false)"> BUSCAR CAMPAÑAS <i class="fa fa-search"></i></button>
                </div>
                <label><b>Nombre:</b></label>
                <v-select :multiple="true" :options="optionsTrades" :placeholder="'Selecciona Trade'" v-model="selectedTrades"></v-select>
                <p><b>Selecciona el rango de fechas para realizar la busqueda</b><br><b style="color: darkred">Filtro por Fecha termino </b></p>
                <date-picker v-model="range" is-range mode="date" :attributes='attrs' color="indigo" @input="formattedDate(true)">
                </date-picker>
            </div>
            <div class="col-md-12" v-show="showTable">
                <div class="text-left"><h2><i class="fa fa-arrow-circle-o-left" @click="showFiltros()" style="cursor:pointer"> Mostrar Filtros</i> </h2></div>
                <v-client-table :data="tableData" :columns="columns" :options="options">
                    <div slot="Estatus" slot-scope="props" >
                        <span v-if="props.row.Estatus === 1" class="badge badge-danger">Vencida</span>
                        <span v-if="props.row.Estatus === 2" class="badge badge-success">Vigente</span>
                    </div>
                    <div slot="Imagen" slot-scope="props">
                        <i class="fa fa-file-image-o" style="color: #0a6aa1;cursor:pointer" v-if="props.row.Imagen!==''" @click="showModalImg(props.row.Imagen)"> Ver</i>
                        <i class="fa fa-times" style="color: darkred" v-else></i>
                    </div>
                    <div slot="Areas" slot-scope="props">
                        <span v-html="props.row.Areas"></span>
                    </div>
                    <div slot="Id" slot-scope="props" class="text-center">
                        <b style="color: darkred;">{{props.row.Id}}</b><br>
                        <h4><i class="fa fa-edit" style="color: darkblue;cursor:pointer" @click="editar(props.row.Id)"></i></h4>
                    </div>
                    <div slot="Fchini" slot-scope="props">
                        <b>{{props.row.Fchini}}</b><br>
                        <i class="fa fa-edit" style="color: #0303ea;cursor:pointer" v-show="!props.row.FchiniEdit" @click="editarFechaIni(props.row.Id)"></i>
                        <i class="fa fa-save" style="color: darkgreen;cursor:pointer" v-show="props.row.FchiniEdit" @click="saveEdit(props.row.Id,1)"></i>
                        <i class="fa fa-times" style="color: darkred;cursor:pointer" v-show="props.row.FchiniEdit" @click="cancelarEdit(props.row.Id)"></i>
                        <input type="date" class="form-control" v-model="props.row.Fchini" v-show="props.row.FchiniEdit" :ref="'fini'+props.row.Id"/>
                    </div>
                    <div slot="Fchter" slot-scope="props">
                        <b>{{props.row.Fchter}}</b><br>
                        <i class="fa fa-edit" style="color: #0303ea;cursor:pointer" v-show="!props.row.FchterEdit" @click="editarFechaTer(props.row.Id)"></i>
                        <i class="fa fa-save" style="color: darkgreen;cursor:pointer" v-show="props.row.FchterEdit" @click="saveEdit(props.row.Id,2)"></i>
                        <i class="fa fa-times" style="color: darkred;cursor:pointer" v-show="props.row.FchterEdit" @click="cancelarEdit(props.row.Id)"></i>
                        <input type="date" class="form-control" v-model="props.row.Fchter" v-show="props.row.FchterEdit" :ref="'fter'+props.row.Id"/>
                    </div>
                    <div slot="Agencias" slot-scope="props">
                        <span v-html="props.row.Agencias"></span>
                    </div>
                    <div slot="Nomentprd" slot-scope="props">
                        <b>{{props.row.Nomentprd}}</b><br>
                        <div class="text-center">
                            <i class="fa fa-edit" style="color: #0303ea;cursor:pointer" v-show="!props.row.NomentprdEdit" @click="editarCampana(props.row.Id)"></i>
                            <i class="fa fa-save" style="color: darkgreen;cursor:pointer" v-show="props.row.NomentprdEdit" @click="saveEdit(props.row.Id,3)"></i>
                            <i class="fa fa-times" style="color: darkred;cursor:pointer" v-show="props.row.NomentprdEdit" @click="cancelarEdit(props.row.Id)"></i>
                            <textarea rows="3" class="form-control" v-model="props.row.Nomentprd" :ref="'campana'+props.row.Id" v-show="props.row.NomentprdEdit"></textarea>
                        </div>
                    </div>
                    <div slot="Estados" slot-scope="props">
                        <span v-html="props.row.Estados"></span>
                    </div>
                    <div slot="Canales" slot-scope="props">
                        <span v-html="props.row.Canales"></span>
                    </div>
                    <div slot="Cadenas" slot-scope="props">
                        <span v-html="props.row.Cadenas"></span>
                    </div>
                    <div slot="Formatos" slot-scope="props">
                        <span v-html="props.row.Formatos"></span>
                    </div>
                    <div slot="Marcas" slot-scope="props">
                        <span v-html="props.row.Marcas"></span>
                    </div>
                    <div slot="Departamento" slot-scope="props">
                        <span v-html="props.row.Departamento"></span>
                    </div>
                    <div slot="Categoria" slot-scope="props">
                        <span v-html="props.row.Categoria"></span>
                    </div>
                    <div slot="Type" slot-scope="props" >
                        <span v-if="props.row.Type===0" class="badge badge-success">KCM</span>
                        <span v-else class="badge badge-primary">Competencia</span>
                    </div>
                </v-client-table>
            </div>
        </div>
        <div class="row" v-show="NoEdit">
            <div class="col-md-12">
                <h2 class="text-info">Datos De Campaña <i class="fa fa-arrow-circle-o-left" style="color: darkred;cursor:pointer" @click="regresar()"> Cancelar</i></h2>

                <div class="row">
                    <div class="col-md-2 text-center">
                        <br><br><br>
                        <p-radio class="p-icon p-round" name="icon_solid" color="primary" style="font-size: 20px;" :value="0" v-model="dataForm.type">
                            <i slot="extra" class="icon fa fa-check"></i>
                            KCM
                        </p-radio>
                        <br>
                        <p-radio class="p-icon p-round" name="icon_solid" color="success" style="font-size: 20px;" :value="1" v-model="dataForm.type">
                            <i slot="extra" class="icon fa fa-check"></i>
                            COMPETENCIA
                        </p-radio>

                    </div>
                    <div class="col-md-4">
                        <label>Fecha Inicio:</label>
                        <date-picker v-model='fechaInicio' color="orange" :attributes='attrs' @input="formattedDate(true)">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    class="bg-white border px-2 py-1 rounded form-control"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                        <label>Fecha Termino:</label>
                        <date-picker v-model='fechaFin' color="orange" :attributes='attrs' @input="formattedDate(false)">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    class="bg-white border px-2 py-1 rounded form-control"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                    </div>
                    <div class="col-md-4">
                        <h2 v-if="edicion===1"><b class="text-danger">Te encuentras editando una prioridad</b></h2>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-4">
                        <label>Áreas: <b>{{optionsAreas.length-1}}/{{dataForm.areas.length}}</b></label>
                        <v-select :multiple="true" :options="optionsAreas" :placeholder="'Selecciona Área'" v-model="dataForm.areas" @input="changeAarea"></v-select>
                    </div>
                    <div class="col-md-4">
                        <label>Canales: <b>{{optionsCanales.length-1}}/{{dataForm.canales.length}}</b></label>
                        <v-select :multiple="true" :options="optionsCanales" :placeholder="'Selecciona Canal'" v-model="dataForm.canales" @input="changeCanal"></v-select>
                    </div>
                    <div class="col-md-4">
                        <label>Categorias Participantes: <b>{{optionsCategoriasCampana.length-1}}/{{dataForm.respuestaCategorias.length}}</b></label>
                        <v-select :multiple="true" :options="optionsCategoriasCampana" :placeholder="'Selecciona Categoria'" v-model="dataForm.respuestaCategorias"></v-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>Agencia: <b>{{optionsAgencia.length-1}}/{{dataForm.agencia.length}}</b></label>
                        <v-select :multiple="true" :options="optionsAgencia" :placeholder="'Selecciona Agencia'" v-model="dataForm.agencia"></v-select>
                    </div>
                    <div class="col-md-4">
                        <label>Cadenas: <b>{{optionsCadenas.length-1}}/{{dataForm.cadenas.length}}</b></label>
                        <v-select :multiple="true" :options="optionsCadenas" :placeholder="'Selecciona Cadena'" v-model="dataForm.cadenas" @input="changeCadena"></v-select>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>Estados: <b>{{optionsEstados.length-1}}/{{dataForm.estados.length}}</b></label>
                        <v-select :multiple="true" :options="optionsEstados" :placeholder="'Selecciona Estados'" v-model="dataForm.estados" @input="changeEstados"></v-select>
                    </div>
                    <div class="col-md-4">
                        <label>Formatos: <b>{{optionsFormatos.length-1}}/{{dataForm.formatos.length}}</b></label>
                        <v-select :multiple="true" :options="optionsFormatos" :placeholder="'Selecciona Formato'" v-model="dataForm.formatos" @input="changeFormato"></v-select>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>Ciudades: <b>{{optionsCiudades.length-1}}/{{dataForm.ciudades.length}}</b></label>
                        <v-select :multiple="true" :options="optionsCiudades" :placeholder="'Selecciona Ciudad'" v-model="dataForm.ciudades" @input="changeCiudades"></v-select>
                    </div>
                    <div class="col-md-4">
                        <label>Tiendas: <b>{{optionsTiendas.length-1}}/{{dataForm.tiendas.length}}</b></label>
                        <v-select :multiple="true" :options="optionsTiendas" :placeholder="'Selecciona Tienda'" v-model="dataForm.tiendas"></v-select>
                    </div>

                </div><br>
                <div class="row">
                    <div class="col-md-4">
                        <label>Tipo Respuesta:</label>
                        <v-select :multiple="false" :options="optionsRespuestas" :placeholder="'Selecciona Respuesta'" v-model="dataForm.respuesta"></v-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>Pregunta:</label>
                        <textarea class="form-control" rows="4" maxlength="200" v-model="dataForm.pregunta"></textarea>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-4">
                        <label>Imagen:</label>
                        <input type="file" name="myImage" accept="image/*"  class="form-control" @change="handleImage"/>
                    </div>
                    <i class="fa fa-eye" style="color: #0e9aef;cursor:pointer" @click="imgvisible = !imgvisible" v-show="!imgvisible"> Mostrar Imagen</i>
                    <div class="col-md-4" v-show="dataForm.imagen!='' & imgvisible">
                        <i class="fa fa-eye" style="color: darkred;cursor:pointer" @click="imgvisible = !imgvisible"> Ocultar imagen</i>
                        <img :src="dataForm.imagen" class="img img-responsive" alt="ImagenPrioridad" />
                    </div>
                </div><br>
                <div class="row" v-if="!enviarInformacion">
                    <div class="col-md-4">
                        <button class="btn btn-primary btn-block" type="button" @click="saveCampana"><b>Actualizar Campaña</b></button>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-md-4 text-center">
                        <div class="spiner-example" >
                            <div class="sk-spinner sk-spinner-wave">
                                <div class="sk-rect1"></div>
                                <div class="sk-rect2"></div>
                                <div class="sk-rect3"></div>
                                <div class="sk-rect4"></div>
                                <div class="sk-rect5"></div>
                            </div>
                            <p><b>Por favor espere. Estamos trabajando su solicitud!</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal inmodal" :class="{'show':showModal}" id="myModal4" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content animated fadeIn">
                    <div class="modal-body">
                        <img :src="imgSrc" alt="Image" class="img img-responsive">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "tabla",
    data() {
        return{
            imgSrc:'',
            showModal:false,
            range: {
                start:null,
                end: null
            },
            attrs: [
                {
                    key: 'today',
                    dot: true,
                    dates: new Date(),
                },
            ],
            columns: ['Id','Type','Trade', 'Fchini','Fchter','Nomentprd','Tpo','Imagen','Estatus','CreatedAt','Areas','Agencias','Estados','Canales','Cadenas','Formatos','Categoria'],
            tableData: [],
            options: {
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:true },
                perPage:15,
                perPageValues: [15,25,45,50],
                filterable: ['Type','Nomentprd','Trade','Fchini','Fchter','Tpo','Estatus','Canales','Cadenas','Formatos','Categoria'],
                sortable: [],
                listColumns: {
                    Estatus: [{
                        id: 1,
                        text: 'Vencida'
                    }, {
                        id: 2,
                        text: 'Vigente'
                    }
                    ],
                    Type: [{
                        id: 0,
                        text: 'KCM'
                    }, {
                        id: 1,
                        text: 'COMPETENCIA'
                    }],
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Nomentprd: 'Campaña',
                    Fchini: 'Fecha Inicio',
                    Fchter: 'Fecha Termino',
                    Tpo: 'Tipo',
                    Type: 'Tipo',
                    CreatedAt: 'Creada el',
                },
                columnsClasses: {
                    Id: 'text-center font-weight-bold',
                    Respuesta: 'text-justify',
                    Fchini: 'text-center',
                    Fchter: 'text-center',
                    Nomentprd: 'text-justify',
                    Tpo: 'text-center',
                    Type: 'text-center',
                    Imagen: 'text-center',
                    Estatus: 'text-center',
                    CreatedAt: 'text-center',
                    Areas: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            NoEdit:false,
            fechaInicio: null,
            fechaFin: null,
            edicion:1,
            enviarInformacion:false,
            imgvisible:true,
            optionsAreas: [],
            optionsCategoriasCampana: [],
            optionsAgencia: [],
            optionsEstados: [],
            optionsCiudades: [],
            optionsCanales: [],
            optionsCadenas: [],
            optionsFormatos: [],
            optionsTiendas: [],
            optionsDepartamentos: [],
            optionsCategorias: [],
            optionsMarcas: [],
            optionsProductos: [],
            optionsPromociones: [],
            optionsRespuestas: [{value:1,label:"Si / No"},{value:2,label:"Número"},{value:3,label:"Comparativo"}],
            dataForm:{
                respuestaCategorias: [],
                fechaInicio:null,
                fechaFin:null,
                areas: [],
                agencia:[],
                estados:[],
                ciudades:[],
                canales:[],
                cadenas:[],
                formatos:[],
                tiendas:[],
                departamentos:[],
                categorias:[],
                marcas:[],
                productos:[],
                promocion:[],
                respuesta:[],
                pregunta:"",
                comentario:"",
                imagen:'',
                type:0
            },
            showTable:false,
            optionsTrades:[],
            selectedTrades:null
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        showFiltros(){
            this.showTable = false;
        },
        getData(){ let $this = this;
            this.optionsAreas=[];
            this.optionsAgencia=[];
            this.optionsEstados=[];
            this.optionsCiudades=[];
            this.optionsCanales=[];
            this.optionsCadenas=[];
            this.optionsFormatos=[];
            this.optionsTiendas=[];
            this.optionsDepartamentos=[];
            this.optionsCategorias=[];
            this.optionsMarcas=[];
            this.optionsProductos=[];
            this.optionsPromociones=[];
            this.dataForm.fechaInicio=null;
            this.dataForm.fechaFin=null;
            this.dataForm.areas= [];
            this.dataForm.agencia=[];
            this.dataForm.estados=[];
            this.dataForm.ciudades=[];
            this.dataForm.canales=[];
            this.dataForm.cadenas=[];
            this.dataForm.formatos=[];
            this.dataForm.tiendas=[];
            this.dataForm.type=0;
            this.dataForm.departamentos=[];
            this.dataForm.categorias=[];
            this.dataForm.marcas=[];
            this.dataForm.productos=[];
            this.dataForm.promocion=[];
            this.dataForm.respuesta=[];
            this.dataForm.pregunta="";
            this.dataForm.comentario="";
            this.dataForm.imagen='';
            this.fechaInicio = null;
            this.fechaFin = null;
            this.getAreas();
            this.getAgencia();
            this.getCanales();
            axios
                .post('/api/Campanas/Peticiones',{combo:4})
                .then(function (r){
                    $this.optionsTrades = r.data;
                });
        },
        getCanales(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:5})
                .then(response => (this.optionsCanales = response.data));
        },
        getAreas(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:1})
                .then(response => (this.optionsAreas = response.data));
        },
        getAgencia(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:2})
                .then(response => (this.optionsAgencia = response.data));
        },
        formattedDate(i) { let $this = this;
            var mm = this.range.getMonth() + 1; // getMonth() is zero-based
            var dd = this.range.getDate();
            var fechai = [this.range.getFullYear(),
                (mm>9 ? '' : '0') + mm,
                (dd>9 ? '' : '0') + dd
            ].join('-');
            var mm = this.range.getMonth() + 1; // getMonth() is zero-based
            var dd = this.range.getDate();
            var fechaf = [this.range.getFullYear(),
                (mm>9 ? '' : '0') + mm,
                (dd>9 ? '' : '0') + dd
            ].join('-');
            this.tableData=[];
            axios
                .post('/api/Campanas/Peticiones',{combo:1,fini:fechai,ffin:fechaf,trades:this.selectedTrades})
                .then(function (r){
                    $this.tableData= r.data.Data;
                    $this.showTable = !$this.showTable;
                });

        },
        showModalImg(src){
            this.showModal = true;
            this.imgSrc = '';
            this.imgSrc = '/atenea'+src;
        },
        closeModal(){
            this.showModal = false;
        },
        editarFechaIni(clave){
            var idx = this.getId(clave);
            this.tableData[idx].FchiniEdit = true;
        },
        editarFechaTer(clave){
            var idx = this.getId(clave);
            this.tableData[idx].FchterEdit = true;
        },
        cancelarEdit(clave){
            var idx = this.getId(clave);
            this.tableData[idx].FchterEdit = false;
            this.tableData[idx].FchiniEdit = false;
            this.tableData[idx].NomentprdEdit = false;
        },
        getId(clave){let $this = this;
            var idn = 0;
            var a = 0;
            for(a;a<$this.tableData.length;a++){
                if ($this.tableData[a].Id === clave){
                    idn = a;
                }
            }
            return idn;
        },
        saveEdit(clave,type){ let $this = this;
            var idx = this.getId(clave);
            if (type===1){
                axios
                    .post('/atenea/json/accionescampanas/',{combo:2,value:this.$refs['fini'+clave].value,type:1,id:clave})
                    .then(function (r){
                        alertaSuccess("Exito!",r.data.Mensaje);
                        $this.tableData[idx].Fchini = $this.$refs['fini'+clave].value;
                        $this.tableData[idx].FchiniEdit = false;
                    });
            }else if (type===2){
                axios
                    .post('/atenea/json/accionescampanas/',{combo:2,value:this.$refs['fter'+clave].value,type:2,id:clave})
                    .then(function (r){
                        alertaSuccess("Exito!",r.data.Mensaje);
                        $this.tableData[idx].Fchter = $this.$refs['fter'+clave].value;
                        $this.tableData[idx].FchterEdit = false;
                    });
            }else if (type===3){
                axios
                    .post('/atenea/json/accionescampanas/',{combo:2,value:this.$refs['campana'+clave].value,type:3,id:clave})
                    .then(function (r){
                        alertaSuccess("Exito!",r.data.Mensaje);
                        $this.tableData[idx].Nomentprd = $this.$refs['campana'+clave].value;
                        $this.tableData[idx].NomentprdEdit = false;
                    });
            }
        },
        editarCampana(clave){
            var idx = this.getId(clave);
            this.tableData[idx].NomentprdEdit = true;
        },
        changeAarea(){
            this.dataForm.estados = [];
            this.dataForm.ciudades = [];
            this.optionsEstados = [];
            this.optionsCiudades = [];
            axios
                .post('/atenea/json/comosForms/',{combo:3,areas:this.dataForm.areas})
                .then(response => (this.optionsEstados = response.data));
        },
        changeEstados(){
            this.dataForm.ciudades = [];
            this.optionsCiudades = [];
            axios
                .post('/atenea/json/comosForms/',{combo:4,estados:this.dataForm.estados})
                .then(response => (this.optionsCiudades = response.data));
        },
        changeCanal(){
            this.dataForm.cadenas = [];
            this.dataForm.formatos = [];
            this.dataForm.tiendas = [];
            this.optionsCadenas = [];
            this.optionsFormatos = [];
            this.optionsTiendas = [];
            axios
                .post('/atenea/json/comosForms/',{combo:6,canales:this.dataForm.canales})
                .then(response => (this.optionsCadenas = response.data));

        },
        changeCadena(){
            this.dataForm.formatos = [];
            this.dataForm.tiendas = [];
            this.optionsFormatos = [];
            this.optionsTiendas = [];
            axios
                .post('/atenea/json/comosForms/',{combo:7,cadenas:this.dataForm.cadenas})
                .then(response => (this.optionsFormatos = response.data))
        },
        changeCiudades(){
            this.dataForm.formatos = [];
            this.dataForm.tiendas = [];
            this.optionsTiendas = [];
        },
        changeFormato(){
            if (this.dataForm.formatos.length>0){
                if (this.dataForm.ciudades.length<1){
                    this.dataForm.formatos = [];
                    alertaError("Error!","Por favor seleccione por lo menos una ciudad!, para traer las tiendas correspondientes a los formatos seleccionados!")
                }else{
                    this.dataForm.tiendas = [];
                    this.optionsTiendas = [];
                    axios
                        .post('/atenea/json/comosForms/',{combo:8,formatos:this.dataForm.formatos,ciudades:this.dataForm.ciudades})
                        .then(response => (this.optionsTiendas = response.data))
                }
            }else{
                this.optionsTiendas = [];
                this.dataForm.ciudades = [];
            }
        },
        handleImage(e){let $this = this;
            const selectedImage = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function(event) {
                $this.dataForm.imagen = event.target.result;
            };
            reader.onerror = function(error) {
                alert(error);
            };
            reader.readAsDataURL(selectedImage);
        },
        saveCampana() {
            if (this.dataForm.fechaInicio === null) {
                alertaError("Error!", "Por favor seleccione la fecha de inicio");
            }
            else if (this.dataForm.fechaFin === null) {
                alertaError("Error!", "Por favor seleccione la fecha de fin");
            }
            else if (this.dataForm.areas.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos un área");
            }
            else if (this.dataForm.agencia.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una agencia");
            }
            else if (this.dataForm.estados.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos un estado");
            }
            else if (this.dataForm.ciudades.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una ciudad");
            }
            else if (this.dataForm.canales.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos un canal");
            }
            else if (this.dataForm.cadenas.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una cadena");
            }
            else if (this.dataForm.formatos.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos un formato");
            }
            else if (this.dataForm.tiendas.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una tienda");
            }
            else if (this.dataForm.respuestaCategorias.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una categoria");
            }
            else if (this.dataForm.respuesta.length === 0) {
                alertaError("Error!", "Por favor seleccione el tipo de respuesta");
            }
            else if (this.dataForm.pregunta.length === 0 || this.dataForm.pregunta.length <= 10) {
                alertaError("Error!", "Por favor espeficique con más detalle su pregunta, para la campaña");
            }
            else{
                if (this.edicion===0){
                    this.guardarData();
                }else{
                    this.guardarDataEdit();
                }

            }
        },
        editar(clave){let $this = this;this.claveEdit=clave;this.edicion=1;this.NoEdit = true;
            axios.post('/atenea/json/accionescampanas/',{combo:3,clave:clave})
                .then(function (r){
                    alertaSuccess("Exito!",r.data.Mensaje);
                    //console.log(r.data);
                    $this.dataForm.pregunta = r.data.Campana.Nomentcam;
                    $this.dataForm.respuesta = {'label':r.data.Campana.Tpo===1?'Si / No':r.data.Campana.Tpo===2?'Número':'Comparativo', 'value':r.data.Campana.Tpo};
                    $this.dataForm.imagen = '/atenea'+r.data.Campana.Pathimg;
                    $this.dataForm.fechaInicio = r.data.Campana.Fchini;
                    $this.fechaInicio = new Date(r.data.Campana.Fchini);
                    $this.dataForm.fechaFin = r.data.Campana.Fchter;
                    $this.fechaFin = new Date(r.data.Campana.Fchter);
                    $this.dataForm.type= parseInt(r.data.Campana.Type);
                    var a = JSON.parse(r.data.Areas);
                    setTimeout(function(){
                        if (a.length === ($this.optionsAreas.length-1)){
                            $this.dataForm.areas = [$this.optionsAreas[0]];
                            $this.changeAarea();
                        }else{
                            $this.dataForm.areas = a;
                            $this.changeAarea();
                        }
                    }, 500);
                    var ag = JSON.parse(r.data.Agencias);
                    setTimeout(function(){
                        if (ag.length === ($this.optionsAgencia.length-1)){
                            $this.dataForm.agencia = [$this.optionsAgencia[0]];
                        }else{
                            $this.dataForm.agencia = ag;
                        }
                    }, 600);
                    var e = JSON.parse(r.data.Estados);
                    setTimeout(function(){
                        if (e.length === ($this.optionsEstados.length-1)){
                            $this.dataForm.estados = [$this.optionsEstados[0]];
                            $this.changeEstados();
                        }else{
                            $this.dataForm.estados = e;
                            $this.changeEstados();
                        }
                    }, 1000);
                    var can = JSON.parse(r.data.Canales);
                    setTimeout(function(){
                        if (can.length === ($this.optionsCanales.length-1)){
                            $this.dataForm.canales = [$this.optionsCanales[0]];
                            $this.changeCanal();
                        }else{
                            $this.dataForm.canales = can;
                            $this.changeCanal();
                        }
                    }, 1300);
                    var cad = JSON.parse(r.data.Cadenas);
                    setTimeout(function(){
                        if (cad.length === ($this.optionsCadenas.length-1)){
                            $this.dataForm.cadenas = [$this.optionsCadenas[0]];
                            $this.changeCadena();
                        }else{
                            $this.dataForm.cadenas = cad;
                            $this.changeCadena();
                        }
                    }, 1400);

                    var c = JSON.parse(r.data.Ciudades);
                    setTimeout(function(){
                        if (c.length === ($this.optionsCiudades.length-1)){
                            $this.dataForm.ciudades = [$this.optionsCiudades[0]];
                        }else{
                            $this.dataForm.ciudades = c;

                        }
                    }, 1500);
                    var f = JSON.parse(r.data.Formatos);
                    setTimeout(function(){
                        if (f.length === ($this.optionsFormatos.length-1)){
                            $this.dataForm.formatos = [$this.optionsFormatos[0]];
                            $this.changeFormato();
                        }else{
                            $this.dataForm.formatos = f;
                            $this.changeFormato();
                        }
                    }, 2000);
                    var tda = JSON.parse(r.data.Tiendas);
                    setTimeout(function(){
                        if (tda.length === ($this.optionsTiendas.length-1)){
                            $this.dataForm.tiendas = [$this.optionsTiendas[0]];
                        }else{
                            $this.dataForm.tiendas = tda;
                        }
                    }, 3000);



                });
        },
        regresar(){
            this.NoEdit = false;
        }
    }


}
</script>

<style scoped>

</style>
