<template>
    <div>
        <v-select :multiple="true" :options="optionsRoles" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedRoles" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "rolesSelect",
    props:{
        placeholderm: String,
    },
    data(){
        return{
            selectedRoles:[],
            optionsRoles:[],
            agencia:[]
        }
    },
    mounted() {
        this.getRoles();
    },
    methods:{
        getRoles(){ let $this = this; this.selectedRoles = [];
            axios
                .post('/api/AdminCatalogos/rolesSelect', {agencia: this.agencia})
                .then(function (r){
                    $this.optionsRoles = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioRol');
        }

    }
}
</script>

<style scoped>

</style>
