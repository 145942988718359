<template>
    <div>
        <v-select :multiple="false" :options="optionsAnios" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedAnios" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "anios",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedAnios:{label:"2024",value:2024},
            optionsAnios:[{label:"2021",value:2021},{label:"2022",value:2022},{label:"2023",value:2023},{label:"2024",value:2024}]
        }
    },
    mounted() {

    },
    methods:{
        setCambio(){
            this.$emit('cambioAnio');
        }

    }
}
</script>

<style scoped>

</style>
