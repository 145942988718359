<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <button  type="button" class="btn ripple btn-outline-danger"  @click="getDataMaliciosas()"><i class="fas fa-bug"></i> Eliminar Log </button>
            </div>
            <div class="col-md-3">
                <button type="button" class="modal-effect btn ripple btn-outline-info"  data-effect="effect-super-scaled" data-toggle="modal" href="#modalnewUser"><i class="fe fe-user-plus"></i> Nuevo Usuario</button>
                <button style="display:none" type="button" class="modal-effect btn ripple btn-outline-info"  data-effect="effect-super-scaled" data-toggle="modal" href="#modalEditUser"><i class="fe fe-user-x" ref="buttonModalEdit"></i> Editar Usuario</button>
            </div>
            <div class="col-md-3">
                <button type="button" class="btn ripple btn-outline-primary" @click="DeleteAllSeriales"><i class="fe fe-trash-2"></i> Eliminar Seriales</button>
            </div>
            <div class="col-md-3">
                <button type="button" class="btn ripple btn-outline-success" @click="ExportFile"><i class="far fa-file-excel"></i> Exportar</button>
                <div class="row" v-if="showLoadDownload">
                    <div class="col-md-12 text-center"><br><br>
                        <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                        <p><b>Preparando información!</b></p>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <v-server-table :columns="columns" :options="options" ref="UsersTable" v-show="!showLoad">
            <div slot="Stt" slot-scope="props">
                <span v-if="props.row.Stt === 'Activo'" class="badge badge-success ripple" @click="ChangeStatus(props.row.Cveentusr,0)">Activo</span>
                <span v-else class="badge badge-danger ripple" @click="ChangeStatus(props.row.Cveentusr,1)">Inactivo</span>
            </div>
            <div slot="Accion" slot-scope="props">
                <i class="fa fa-lock ripple" style="cursor:pointer;color: #fad208" data-placement="top" data-toggle="tooltip-primary" title="Cambiar Contraseña" data-original-title="Cambiar Contraseña" @click="changePass(props.row.Cveentusr)"></i>
                <i class="fa fa-eraser ripple" style="cursor:pointer;color: #ef0a0a" data-placement="top" data-toggle="tooltip-primary" title="Eliminar Dispositivo" data-original-title="Eliminar Dispositivo" @click="DeleteSerial(props.row.Cveentusr,props.row.Serial)"></i>
                <i class="fa fa-user-edit ripple" style="cursor:pointer;color: #0264f8" data-placement="top" data-toggle="tooltip-primary" title="Editar Usuario" data-original-title="Editar Usuario" @click="editUser(props.row.Cveentusr)"></i>
            </div>
            <div slot="Serial" slot-scope="props">
                <small>{{props.row.Serial}}</small>
            </div>
            <div slot="Fchcrt" slot-scope="props">
                <small>{{props.row.Fchcrt}}</small>
            </div>
            <div slot="FU" slot-scope="props">
                <small>{{props.row.FU}}</small>
            </div>
            <div slot="Tipo" slot-scope="props">
                <small>{{props.row.Tipo}}</small><br>
                <small><i class="fa fa-edit ripple text-info" v-if="!props.row.Edit" @click="startEditTipo(props.row.Cveentusr)"></i></small>
                <small><i class="fa fa-save ripple text-success" v-if="props.row.Edit" @click="saveTipo(props.row.Cveentusr)"></i>&nbsp;&nbsp;&nbsp;</small>
                <small><i class="fa fa-times ripple text-danger" v-if="props.row.Edit" @click="cancelEditTipo(props.row.Cveentusr)"></i></small><br>
                <select :ref="'opcionTipo'+props.row.Cveentusr" v-if="props.row.Edit" class="form-control">
                    <option v-for="items in optionsTipo" :value="items.id">{{items.title}}</option>
                </select>
            </div>
            <div slot="Ciudad" slot-scope="props">
                <small>{{props.row.Ciudad}}</small>
            </div>
            <div slot="Agencia" slot-scope="props">
                <small>{{props.row.Agencia}}</small>
            </div>
            <div slot="Usrentusr" slot-scope="props">
                <small>{{props.row.Usrentusr}}<br>{{props.row.Telefono}}</small>
            </div>
            <div slot="Nombre" slot-scope="props">
                <small>{{props.row.Nombre}}</small>
            </div>
            <div slot="Verapp" slot-scope="props">
                <small>{{props.row.Verapp}} <br/> {{ props.row.Parche }}</small>
            </div>
            <div slot="Permisos" slot-scope="props">
                <ul>
                    <li v-for="items in props.row.Permisos">
                        <small>{{permisos[items]}}</small>
                    </li>
                </ul>
                <div class="text-center" v-if="props.row.Tipo === 'Supervisor'">
                    <small>
                        <i class="fas fa-edit text-info" style="cursor: pointer"
                           @click="changePermisos(props.row.Cveentusr)"
                           data-effect="effect-super-scaled" data-toggle="modal" href="#modalAddPermisos"></i>
                    </small>
                </div>
            </div>
            <div slot="Departamento" slot-scope="props">
                <ul>
                    <li v-for="items in props.row.Departamento"><small>{{items}}</small></li>
                </ul>
            </div>
            <div slot="Appmal" slot-scope="props">
                <span v-if="props.row.Appmal === 1" class="modal-effect badge badge-danger" data-effect="effect-just-me"
                      data-toggle="modal" href="#modaldemo81" style="cursor: pointer" @click="showApps(props.row.Cveentusr)">
                    Instala
                </span>
                <span v-else class="badge badge-success">Libre</span>
            </div>
            <div slot="Rol" slot-scope="props">
                <small>{{props.row.Rol}}</small><br>
                <small><i class="fa fa-edit ripple text-info" v-if="!props.row.EditRol && props.row.Tipo === 'Supervisor'" @click="startEditRol(props.row.Cveentusr)"></i></small>
                <small><i class="fa fa-save ripple text-success" v-if="props.row.EditRol && props.row.Tipo === 'Supervisor'" @click="saveRol(props.row.Cveentusr)"></i>&nbsp;&nbsp;&nbsp;</small>
                <small><i class="fa fa-times ripple text-danger" v-if="props.row.EditRol && props.row.Tipo === 'Supervisor'" @click="cancelEditRol(props.row.Cveentusr)"></i></small><br>
                <select :ref="'opcionRol'+props.row.Cveentusr" v-show="props.row.EditRol && props.row.Tipo === 'Supervisor'" class="form-control">
                    <option v-for="items in optionsRol" :value="items.id">{{items.text}}</option>
                </select>
            </div>
        </v-server-table>
        <div class="row" v-if="showLoad">
            <div class="col-md-12 text-center"><br><br>
                <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                <p><b>Buscando información por favor espere!</b></p>
            </div>
        </div>
        <addpermisos-component ref="addPermisos"></addpermisos-component>
        <modalnewuser-component ref="newUserModal"></modalnewuser-component>
        <modaleditser-component ref="editUserModal" v-on:editFinish="endEdit()"></modaleditser-component>
        <!-- Modal apps malisiosas -->
        <div class="modal" id="modaldemo81">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Aplicaciones</h6><button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <span>{{apss}}</span>
                    </div>
                    <div class="modal-footer">

                        <button class="btn ripple btn-secondary" data-dismiss="modal" type="button">Salir</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
name: "tableUsers",
    data(){
        return{
            apss:'',
            permisos:["","Encuestas","Faltantes","Promociones","Anaquel","Exhibiciones","Precios","Gráfico","Comentarios","Captura Rápido"],
            tipoUserEdit: {},
            rolUserEdit: {},
            typeUsers:1,
            msg:"asdf",
            showLoad:true,
            showLoadDownload:false,
            columns: ['Idemp','Usrentusr', 'Nombre', 'Emailcontact','Agencia','Stt','Ciudad','Tipo','Rol','Permisos','Fchcrt','Serial','Verapp','FU','Accion','Appmal'],
            tableData: [],
            optionsTipo: [],
            optionsRol: [],
            options: {
                async requestFunction(data) {
                    //console.log(data)
                    //data.typeUsers = 1
                    //console.log(data)
                    return await axios.post('/api/Users/getUsuarios', {
                            params: data,
                        })
                        .then(function (data) {
                            //console.log(data)
                            return data
                        })
                        .catch(function (e) {
                            console.log(e)
                        })
                },
                // see the options API
                footerHeadings:false,
                filterByColumn:true,
                perPage:10,
                perPageValues: [10,15,25,45,50],
                //filterable: [,,'Idemp','Appmal','Serial'],
                filterable: ['Nombre', 'Usrentusr','Stt','Serial','Verapp','Agencia','Ciudad','Tipo','Rol','Idemp'],
                sortable: ['Nombre','Usrentusr','Agencia','Ciudad','FU','Fchcrt','Idemp','Appmal','Serial','Verapp'],
                listColumns: { Agencia: [], Tipo: [], Rol: [], Stt: [{id:0,text:'Inactivo'},{id:1,text:'Activo'}] },
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                headings: {
                    Idemp: '#',
                    Nombre: 'Nombre Empleado',
                    Agencia: 'Agencia',
                    Stt: 'Estatus',
                    Verapp: 'Ver App',
                    FU: 'Fecha Update App',
                    Fchcrt: 'Fecha Alta',
                    Rol: 'Rol',
                    Accion: 'Acciones',
                    Usrentusr: 'Usuario',
                    Appmal: 'Apps Maliciosas',
                },
                columnsClasses: {
                    Idemp: 'text-justify',
                    Stt: 'text-center',
                    Tipo: 'text-center',
                    Ciudad: 'text-center',
                    Agencia: 'text-center',
                    Verapp: 'text-center',
                    FU: 'text-center',
                    Fchcrt: 'text-center',
                    Rol: 'text-center',
                    Accion: 'text-center',
                    Usrentusr: 'text-justify',
                    Serial: 'text-justify',
                    Appmal: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Recopilando información, por favor espere...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods:{
        async getDataMaliciosas(){
            this.showLoadDownload = true;
            var resp = await this.$peticionesApi('/api/Users/clearLogApps',{});
            if (resp != null){
                this.$toastMessage('success',resp.Mensaje,5000,'center')
            }
            this.showLoadDownload = false;
        },
        showApps(cve){
           var a = this.getIdx(cve);
           this.apss = this.$refs.UsersTable.data[a].Apps;
        },
        saveTipo(clave){let $this = this;
            var indexSelected = this.$refs['opcionTipo'+clave].selectedIndex;
            var id = this.getIdx(clave);
            axios.post('/api/Users/changeTipo',{cveuser: clave, tipo: this.$refs['opcionTipo'+clave].value })
                .then(r => {
                        if (r.data.Success){
                            $this.$refs.UsersTable.data[id].Tipo = this.$refs['opcionTipo'+clave].options[indexSelected].text;
                            $this.$refs.UsersTable.data[id].Edit = false;
                            $this.$toastMessage('success',r.data.Mensaje,6000,'top-right')
                        }else{
                            $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        changePermisos(clave){
            this.$refs.addPermisos.idEmpleado = clave;
            this.$refs.addPermisos.getPermisos();
        },
        startEditTipo(clave){
            var id = this.getIdx(clave);
            this.optionsTipo=[];
            this.optionsTipo = this.$refs.newUserModal.optionsTipo;
            this.$refs.UsersTable.data[id].Edit = !this.$refs.UsersTable.data[id].Edit;
        },
        cancelEditTipo(clave){
            var id = this.getIdx(clave);
            this.tipoUserEdit={};
            this.$refs.UsersTable.data[id].Edit = false;
        },
        saveRol(clave){let $this = this;
            var indexSelected = this.$refs['opcionRol'+clave].selectedIndex;
            var id = this.getIdx(clave);
            axios.post('/api/Users/changeRol',{cveuser: clave, rol: this.$refs['opcionRol'+clave].value })
                .then(r => {
                        if (r.data.Success){
                            $this.$refs.UsersTable.data[id].Rol = this.$refs['opcionRol'+clave].options[indexSelected].text;
                            $this.$refs.UsersTable.data[id].EditRol = false;
                            $this.$toastMessage('success',r.data.Mensaje,6000,'top-right')
                        }else{
                            $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        startEditRol(clave){
            var id = this.getIdx(clave);
            this.$refs.UsersTable.data[id].EditRol = !this.$refs.UsersTable.data[id].EditRol;
        },
        cancelEditRol(clave){
            var id = this.getIdx(clave);
            this.rolUserEdit={};
            this.$refs.UsersTable.data[id].EditRol = false;
        },
        getData(){let $this = this;
            this.showLoad=true;
             axios.post('/api/Users/getFiltersTable')
                 .then(r => {
                     if (r.data.Success){
                         $this.showLoad=false;
                         $this.options.listColumns.Agencia = r.data.Agencias;
                         $this.options.listColumns.Tipo = r.data.Tipos;
                         $this.options.listColumns.Rol = r.data.Roles;
                         $this.optionsRol = r.data.Roles;
                     }
                 },
                 error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
             );
        },
        DeleteSerial(cveUser,Serial){let $this = this;
            if (Serial === ''){
                this.$toastMessage('error','No tiene serial que eliminar!',5000,'center');
            }else{
                var id = this.getIdx(cveUser);
                this.$swal.fire({
                    title: '¿Eliminar Serial?',
                    text: Serial,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, eliminar!',
                    cancelButtonText: 'No, cancelar!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('/api/Users/deleteSerial',{clave: cveUser})
                            .then(r => {
                                    if (r.data.Success){
                                        $this.$refs.UsersTable.data[id]['Serial'] = '';
                                        $this.$swal.fire(
                                            'Eliminado!',
                                            'Ahora puede ingresar con otro dispositivo.',
                                            'success'
                                        );
                                    }else{
                                        $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                    }
                                },
                                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                            );

                    }
                });
            }
        },
        getIdx(clave){let $this = this;
             var idx = 0;
             for (var i = 0; i< this.$refs.UsersTable.data.length; i++){
                 if (clave === $this.$refs.UsersTable.data[i]['Cveentusr']){
                     idx = i;
                 }
             }
             return idx;
        },
        DeleteAllSeriales(){let $this = this;
            this.$swal.fire({
                title: '¿Eliminar Todos Los Seriales?',
                text: "Esto solo se realiza en una actualización",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'No, cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Users/deleteAllSeriales')
                        .then(r => {
                                if (r.data.Success){
                                    $this.$swal.fire(
                                        'Eliminados!',
                                        'Ahora pueden ingresar con otro dispositivo.',
                                        'success'
                                    );
                                    $this.$refs.UsersTable.getData();
                                }else{
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                }
                            },
                            error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                        );
                }
            });
        },
        changePass(clave){let $this = this;
             var newPass = '';
            this.$swal.fire({
                title: 'Cambio de contraseña',
                icon: 'warning',
                input: 'text',
                inputLabel: 'Nueva Contraseña',
                inputValue: newPass,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'La contraseña no puede ser en blanco!'
                    }else if(value.length <=7){
                        return 'La contraseña no puede ser en menor a 7 caracteres!'
                    }else{
                        axios.post('/api/Users/changePassword',{clave: clave, pass: value})
                            .then(r => {
                                    if (r.data.Success){
                                        $this.$swal.fire(
                                            'Exito!',
                                            'Contraseña Actualizada.',
                                            'success'
                                        );
                                    }else{
                                        $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                    }
                                },
                                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                            );
                    }
                }
            });
        },
        ChangeStatus(clave,type){let $this = this;
            var id = this.getIdx(clave);
            this.$swal.fire({
                title: type === 0 ? '¿Desactivar Usuario?' : '¿Activar Usuario?',
                text: type === 0 ? 'Al aceptar, se le negará el acceso al usuario' : 'Al aceptar, se le permitirá el acceso al usuario',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: type === 0 ? 'Desactivar' : 'Activar',
                cancelButtonText: 'Cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Users/changeStatus',{clave: clave, stt: type})
                        .then(r => {
                                if (r.data.Success){
                                    $this.$swal.fire(
                                        'Exito!',
                                        'Acción realizada satisfactoriamente.',
                                        'success'
                                    );
                                    $this.$refs.UsersTable.data[id]['Stt'] = type === 0 ? 'Inactivo' : 'Activo';
                                }else{
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                }
                            },
                            error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                        );
                }
            });
        },
        ExportFile(){let $this = this;
            this.showLoadDownload = true;
            //console.log({params: this.$refs.UsersTable.getRequestParams()});
            axios({
                method: 'post',
                url: '/api/Users/exportUsersToExcel',
                responseType: 'arraybuffer',
                data: {params: this.$refs.UsersTable.getRequestParams()}}).then(r => {
                        let blob = new Blob([r.data], { type: 'application/xlsx' })
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = 'UsuariosAtenea.xlsx'
                        link.click()
                        $this.showLoadDownload=false;
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        editUser(claveUser){
             var id = this.getIdx(claveUser);
             //console.log(this.$refs.UsersTable.data)
             this.$refs.editUserModal.setData(this.$refs.UsersTable.data[id]);
             this.$refs.buttonModalEdit.click();
        },
        endEdit(){
             //console.log(this.$refs.editUserModal.dataEditUser);
             var id = this.getIdx(this.$refs.editUserModal.dataEditUser.cveusr);
            this.$refs.UsersTable.data[id].Nomentusr = this.$refs.editUserModal.dataEditUser.nombres;
            this.$refs.UsersTable.data[id].Apepat = this.$refs.editUserModal.dataEditUser.paterno;
            this.$refs.UsersTable.data[id].Apemat = this.$refs.editUserModal.dataEditUser.materno;
            this.$refs.UsersTable.data[id].Telefono = this.$refs.editUserModal.dataEditUser.tel;
            this.$refs.UsersTable.data[id].Usrentusr = this.$refs.editUserModal.dataEditUser.username;
            this.$refs.UsersTable.data[id].Cveentusr = this.$refs.editUserModal.dataEditUser.cveemp;
            this.$refs.UsersTable.data[id].Agencia = this.$refs.editUserModal.dataEditUser.agencia.title;
            this.$refs.UsersTable.data[id].CveCveAgencia = this.$refs.editUserModal.dataEditUser.agencia.value;
            this.$refs.UsersTable.data[id].Ciudad = this.$refs.editUserModal.dataEditUser.ciudad.title;
            this.$refs.UsersTable.data[id].CveCiudad = this.$refs.editUserModal.dataEditUser.ciudad.value;
            this.$refs.UsersTable.data[id].Nombre = this.$refs.editUserModal.dataEditUser.nombres + ' ' + this.$refs.editUserModal.dataEditUser.paterno + ' ' + this.$refs.editUserModal.dataEditUser.materno;

        }

    }
}
</script>

<style scoped>
tbody {
    display:block;
    height:400px;
    overflow:auto;
}

thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
}

thead {
    width: calc( 100% )/* scrollbar is average 1em/16px width, remove it from thead width */
}
</style>
