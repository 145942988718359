<template>
    <div>
        <div v-show="!load">
            <v-select :multiple="true" :options="optionsEncuestas" :placeholder="'Selecciona '+placeholderm"
                      v-model="selectedEncuesta" @input="setCambio"></v-select>
        </div>
        <div v-show="load">
            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="20">
            <p>Preparando encuestas, espere...</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "encuestasselect",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedEncuesta:[],
            optionsEncuestas:[],
            agencia:[],
            load:false
        }
    },
    mounted() {
        this.getAreas();
    },
    methods:{
        async getAreas() {
            let $this = this;
            this.selectedEncuesta = []; this.load = true;
            await axios
                .post('/api/AdminCatalogos/getEncuestasSelect', {
                    tipo: false,
                    tipoEncuesta: 'TODAS',
                    canal: 0,
                    actividad: 0
                })
                .then(function (r) {
                    $this.optionsEncuestas = r.data.encuestas;$this.load = false;
                });
        },
        setCambio(){
            this.$emit('cambioEncuesta');
        }

    }
}
</script>

<style scoped>

</style>
