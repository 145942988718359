<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-md-3">
                <filters-general ref="filtersReport"></filters-general>
            </div>
            <div class="col-md-9" >
                <div class="row">
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-center">
                        <button class="btn ripple btn-primary" @click="getReportEncuestas">Generar Reporte </button><br>
                        <div v-if="loadData"><br>
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando información, espere...</p>
                        </div>
                    </div>
                </div><br><br>
                <!-- ENCUESTAS -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4 text-center"></div>
                            <div class="col-md-4 text-center" v-show="showResultEncuestas">
                                <h4><b class="text-info">Tabla Encuestas: {{tableDataEncuestas.length}} registros</b></h4>
                            </div>
                            <div class="col-md-4" v-if="showResultEncuestas">
                                <div v-if="loadfileEncuestas">
                                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                    <p>Preparando archivo, espere...</p>
                                </div>
                                <button v-if="!loadfileEncuestas"  class="btn btn-success" type="button" @click="ExportFileEncuestas"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-12" v-show="showResultEncuestas">
                                <div class="text-center">
                                    <h4><i class="fas fa-eye" style="cursor:pointer;color: blue" @click="showData(1)"> Mostrar Datos</i></h4>
                                </div>
                                <v-client-table :data="tableDataEncuestasShow" :columns="columnsEncuestas" :options="optionsEncuestas" class="table-responsive" ref="tablePromociones">
                                </v-client-table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- FALTANTES -->
                <div class="row">
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-center" v-show="showResultFaltantes">
                        <h4><b class="text-info">Tabla Faltantes: {{tableDataFaltantes.length}} registros</b></h4>
                    </div>
                    <div class="col-md-4" v-if="showResultFaltantes">
                        <div v-if="loadfileFaltantes">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando archivo, espere...</p>
                        </div>
                        <button v-if="!loadfileFaltantes"  class="btn btn-success" type="button" @click="ExportFileFaltantes"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                    </div>
                </div>
                <div class="row" v-show="showResultFaltantes">
                    <div class="col-md-12" v-show="showResultFaltantes">
                        <div class="text-center">
                            <h4><i class="fas fa-eye" style="cursor:pointer;color: blue" @click="showData(2)"> Mostrar Datos</i></h4>
                        </div>
                        <v-client-table :data="tableDataFaltantesShow" :columns="columnsFaltantes" :options="optionsFaltantes" class="table-responsive" ref="tableFaltantes">
                        </v-client-table>
                    </div>
                </div>
                <!-- ANAQUEL -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4 text-center"></div>
                            <div class="col-md-4 text-center" v-show="showResultAnaquel">
                                <h4><b class="text-info">Tabla Anaquel: {{tableDataAnaquel.length}} registros</b></h4>
                            </div>
                            <div class="col-md-4" v-if="showResultAnaquel">
                                <div v-if="loadfileAnaquel">
                                    <img :src="'/theme/img/loads/loadfileAnaquel.gif'" alt="carga archivo" height="40">
                                    <p>Preparando archivo, espere...</p>
                                </div>
                                <button v-if="!loadfileAnaquel"  class="btn btn-success" type="button" @click="ExportFileAnaquel"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-12" v-show="showResultAnaquel">
                                <div class="text-center">
                                    <h4><i class="fas fa-eye" style="cursor:pointer;color: blue" @click="showData(3)"> Mostrar Datos</i></h4>
                                </div>
                                <v-client-table :data="tableDataAnaquelShow" :columns="columnsAnaquel" :options="optionsAnaquel" class="table-responsive" ref="tablePromociones">
                                </v-client-table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- EXHIBICIONES -->
                <div class="row">
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-center" v-show="showResultExhibiciones">
                        <h4><b class="text-info">Tabla Exhibiciones: {{tableDataExhibiciones.length}} registros</b></h4>
                    </div>
                    <div class="col-md-4" v-if="showResultExhibiciones">
                        <div v-if="loadfileExhibiciones">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando archivo, espere...</p>
                        </div>
                        <button v-if="!loadfile"  class="btn btn-success" type="button" @click="ExportFileExhibiciones"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" v-show="showResultExhibiciones">
                        <div class="text-center">
                            <h4><i class="fas fa-eye" style="cursor:pointer;color: blue" @click="showData(4)"> Mostrar Datos</i></h4>
                        </div>
                        <v-client-table :data="tableDataExhibicionesShow" :columns="columnsExhibiciones" :options="optionsExhibiciones" class="table-responsive" ref="tablePromociones">
                        </v-client-table>
                    </div>
                </div>
                <!-- PROMOCIONES -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4 text-center"></div>
                            <div class="col-md-4 text-center" v-show="showResultPromociones">
                                <h4><b class="text-info">Tabla Promociones: {{tableDataPromociones.length}} registros</b></h4>
                            </div>
                            <div class="col-md-4" v-if="showResultPromociones">
                                <div v-if="loadfilePromociones">
                                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                    <p>Preparando archivo, espere...</p>
                                </div>
                                <button v-if="!loadfilePromociones"  class="btn btn-success" type="button" @click="ExportFilePromociones"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-12" v-show="showResultPromociones">
                                <div class="text-center">
                                    <h4><i class="fas fa-eye" style="cursor:pointer;color: blue" @click="showData(5)"> Mostrar Datos</i></h4>
                                </div>
                                <v-client-table :data="tableDataPromocionesShow" :columns="columnsPromociones" :options="optionsPromociones" class="table-responsive" ref="tablePromociones">
                                </v-client-table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- PRECIOS -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4 text-center"></div>
                            <div class="col-md-4 text-center" v-show="showResultPrecios">
                                <h4><b class="text-info">Tabla Precios: {{tableDataPrecios.length}} registros</b></h4>
                            </div>
                            <div class="col-md-4" v-if="showResultPrecios">
                                <div v-if="loadfilePrecios">
                                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                    <p>Preparando archivo, espere...</p>
                                </div>
                                <button v-if="!loadfilePrecios"  class="btn btn-success" type="button" @click="ExportFilePrecios"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-12" v-show="showResultPrecios">
                                <div class="text-center">
                                    <h4><i class="fas fa-eye" style="cursor:pointer;color: blue" @click="showData(6)"> Mostrar Datos</i></h4>
                                </div>
                                <v-client-table :data="tableDataPreciosShow" :columns="columnsPrecios" :options="optionsPrecios" class="table-responsive" ref="tablePromociones">
                                </v-client-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
window.onbeforeunload = function(){
    //alert("asfasdf saf");
}
export default {
    name: "detalleInformacion",
    data(){
        return{
            loadfileEncuestas:false,
            loadfile:false,
            loadData:false,
            showResultEncuestas:false,
            loadingGetDataEncuestas: false,
            showDataEncuestas: false,
            columnsEncuestas: ['Fecha','Semana','Area','Agencia','Estado','Ciudad','Rol','Usuario','Distrito','Canal','Cadena','Formato','Tienda','Kcmid','Trade','Pregunta','Respuesta','Comentario'],
            tableDataEncuestas: [],
            tableDataEncuestasShow: [],
            optionsEncuestas: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Tienda','Area','Formato','Canal','Cadena','Distrito'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "Click en mostrar datos",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            loadfileFaltantes:false,
            showResultFaltantes:false,
            loadingGetDataFaltantes: false,
            showDataFaltantes: false,
            columnsFaltantes: ['Fecha','Semana','Area','Agencia','Rol','Usuario','Distrito','Estado','Ciudad','Canal','Cadena','Formato','Tienda','Kcmid','Fabricante','Departamento','Categoria','Marca','Codigo','Producto'],
            tableDataFaltantes: [],
            tableDataFaltantesShow: [],
            optionsFaltantes: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Tienda','Area','Formato','Canal','Cadena','Producto','Distrito'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                    InventarioF: [
                        {
                            class:'bg-danger',
                            condition: row => row.InventarioF <= 0
                        }
                    ]
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                    Cantidad: 'Inventario Inicial Piezas',
                    Resurtido: 'Resurtido Piezas',
                    Ventas: 'Ventas Piezas',
                    InventarioF: 'Inventario Final Piezas',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Cantidad: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "Click en mostrar datos",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            loadfileAnaquel:false,
            showResultAnaquel:false,
            loadingGetDataAnaquel: false,
            showDataAnaquel: false,
            columnsAnaquel: ['Fecha','Semana','Area','Agencia','Estado','Ciudad','Rol','Usuario','Distrito','Canal','Cadena','Formato','Tienda','Kcmid','Fabricante','Departamento','Categoria','Participacion'],
            tableDataAnaquel: [],
            tableDataAnaquelShow: [],
            optionsAnaquel: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Tienda','Area','Formato','Canal','Cadena','Distrito'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "Click en mostrar datos",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            loadfileExhibiciones:false,
            showResultExhibiciones:false,
            loadingGetDataExhibiciones: false,
            showDataExhibiciones: false,
            columnsExhibiciones: ['Fecha','Semana','Area','Agencia','Rol','Usuario','Distrito','Estado','Ciudad','Canal','Cadena','Formato','Tienda','Kcmid','Fabricante','Departamento','Categoria','Marca','Codigo','Producto','Exhibicion','Cantidad','Comentario','Charolas','Cnt','Valor'],
            tableDataExhibiciones: [],
            tableDataExhibicionesShow: [],
            optionsExhibiciones: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Tienda','Area','Formato','Canal','Cadena','Codigo','Producto','Exhibicion','Distrito'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                    Exhibicion: 'Tipo Exhibición',
                    Cnt: 'Cantidad',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Cnt: 'text-center',
                    Charolas: 'text-center',
                    Valor: 'text-center',
                    Cantidad: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "Click en mostrar datos",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            loadfilePromociones:false,
            showResultPromociones:false,
            loadingGetDataPromociones: false,
            showDataPromociones: false,
            columnsPromociones: ['Fecha','Semana','Area','Agencia','Rol','Usuario','Distrito','Estado','Ciudad','Canal','Cadena','Formato','Tienda','Kcmid','Fabricante','Departamento','Categoria','Marca','Codigo','Producto','Promocion','Comentario'],
            tableDataPromociones: [],
            tableDataPromocionesShow: [],
            optionsPromociones: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Tienda','Area','Formato','Canal','Cadena','Producto','Distrito'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                    InventarioF: [
                        {
                            class:'bg-danger',
                            condition: row => row.InventarioF <= 0
                        }
                    ]
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                    Cantidad: 'Inventario Inicial Piezas',
                    Resurtido: 'Resurtido Piezas',
                    Ventas: 'Ventas Piezas',
                    InventarioF: 'Inventario Final Piezas',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Cantidad: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "Click en mostrar datos",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            loadfilePrecios:false,
            showResultPrecios:false,
            loadingGetDataPrecios: false,
            showDataPrecios: false,
            columnsPrecios: ['Fecha','Semana','Area','Agencia','Rol','Usuario','Distrito','Estado','Ciudad','Canal','Cadena','Formato','Tienda','Kcmid','Fabricante','Departamento','Categoria','Marca','Codigo','Producto','Prc','Comentario'],
            tableDataPrecios: [],
            tableDataPreciosShow: [],
            optionsPrecios: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Tienda','Area','Formato','Canal','Cadena','Codigo','Producto','Prc','Distrito'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                    Prc: 'Precio',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Prc: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "Click en mostrar datos",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    mounted() {
        this.$refs.filtersReport.shows.respuestas = false;
        this.limpiar();
    },
    unmounted(){
        this.limpiar();
    },
    beforeUnmount(){
        this.limpiar();
    },

    methods:{
        limpiar(){
            this.tableDataEncuestas=[];
            this.tableDataEncuestasShow=[];
            this.tableDataFaltantes=[];
            this.tableDataFaltantesShow=[];
            this.tableDataExhibiciones=[];
            this.tableDataExhibicionesShow=[];
            this.tableDataPromociones=[];
            this.tableDataPromocionesShow=[];
            this.tableDataAnaquel=[];
            this.tableDataAnaquelShow=[];
            this.tableDataPrecios=[];
            this.tableDataPreciosShow=[];
        },
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReportEncuestas();
            }
        },
        showData(type){
            if (type === 1){
                this.tableDataEncuestasShow = this.tableDataEncuestas;
            }
            if (type === 2){
                this.tableDataFaltantesShow = this.tableDataFaltantes;
            }
            if (type === 3){
                this.tableDataAnaquelShow = this.tableDataAnaquel;
            }
            if (type === 4){
                this.tableDataExhibicionesShow = this.tableDataExhibiciones;
            }
            if (type === 5){
                this.tableDataPromocionesShow = this.tableDataPromociones;
            }
            if (type === 5){
                this.tableDataPreciosShow= this.tableDataPrecios;
            }
        },
        getReportEncuestas(){ let $this = this;
            this.loadData = true;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else if (Date.parse(filters.timeEnd) < Date.parse(filters.timeInit)){
                this.$toastMessage('error',"Seleccione una fecha fin mayor a la fecha inicial!",4000,'center');
            }else{
                this.loadingGetDataEncuestas = true;
                this.showDataEncuestas = false;
                this.showResultEncuestas = false;
                this.tableDataInventario = [];
                axios.post('/api/Encuestas/Tabla',{data:filters})
                    .then(r => {
                            $this.loadingGetDataEncuestas = false;
                            $this.showDataEncuestas = true;
                            $this.tableDataEncuestas = r.data.Data;
                            $this.showResultEncuestas = true;
                            $this.getReportFaltantes();
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        }
                    );
            }
        },
        getReportFaltantes(){ let $this = this;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else{
                this.loadingGetDataFaltantes = true;
                this.showDataFaltantes = false;
                this.showResultFaltantes = false;
                this.tableDataFaltantes = [];
                axios.post('/api/Faltantes/Tabla',{data:filters})
                    .then(r => {
                            $this.loadingGetData = false;
                            $this.tableDataFaltantes = r.data.Data;
                            $this.showResultFaltantes = true;
                            $this.getReportAnaquel();
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        }
                    );
            }
        },
        getReportAnaquel(){ let $this = this;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else{
                this.loadingGetDataAnaquel = true;
                this.showDataAnaquel = false;
                this.showResultAnaquel = false;
                this.tableDataInventario = [];
                axios.post('/api/Anaquel/Tabla',{data:filters})
                    .then(r => {
                            $this.loadingGetDataAnaquel = false;
                            $this.showDataAnaquel = true;
                            $this.tableDataAnaquel = r.data.Data;
                            $this.showResultAnaquel = true;
                            $this.getReportExhibiciones();
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        }
                    );
            }
        },
        getReportExhibiciones(){ let $this = this;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else{
                this.loadingGetDataExhibiciones = true;
                this.showDataExhibiciones  = false;
                this.showResultExhibiciones  = false;
                this.tableDataExhibiciones = [];
                axios.post('/api/Exhibiciones/Tabla',{data:filters})
                    .then(r => {
                            $this.loadingGetData = false;
                            $this.tableDataExhibiciones = r.data.Data;
                            $this.showResultExhibiciones = true;
                            $this.getReportPromociones();
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        }
                    );
            }
        },
        getReportPromociones(){ let $this = this;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else{
                this.loadingGetDataPromociones = true;
                this.showDataPromociones = false;
                this.showResultPromociones = false;
                this.tableDataInventario = [];
                axios.post('/api/Promociones/Tabla',{data:filters})
                    .then(r => {
                            $this.loadingGetDataPromociones = false;
                            $this.showDataPromociones = true;
                            $this.tableDataPromociones = r.data.Data;
                            $this.showResultPromociones = true;
                            $this.getReportPrecios()
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        }
                    );
            }
        },
        getReportPrecios(){ let $this = this;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else{
                this.loadingGetDataPrecios = true;
                this.showDataPrecios = false;
                this.showResultPrecios = false;
                this.tableDataInventario = [];
                axios.post('/api/Precios/Tabla',{data:filters})
                    .then(r => {
                            $this.loadingGetDataPrecios = false;
                            $this.showDataPrecios = true;
                            $this.tableDataPrecios = r.data.Data;
                            $this.showResultPrecios = true;
                            $this.loadData = false;
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                            $this.loadData = false;
                        }
                    );
            }
        },
        ExportFileEncuestas(){let $this = this;
            this.loadfileEncuestas = true;
            axios({
                method: 'post',
                url: '/api/Encuestas/exportLayoutToExcel',
                responseType: 'arraybuffer',
                data: {data: this.tableDataEncuestas}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'EncuestasAtenea.xlsx'
                    link.click()
                    $this.loadfileEncuestas=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfileEncuestas=false;
                }
            );
        },
        ExportFileFaltantes(){let $this = this;
            this.loadfile = true;
            axios({
                method: 'post',
                url: '/api/Faltantes/exportLayoutToExcel',
                responseType: 'arraybuffer',
                data: {data: this.tableDataFaltantes}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'FaltantesAtenea.xlsx'
                    link.click()
                    $this.loadfile=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfile=false;
                }
            );
        },
        ExportFileAnaquel(){let $this = this;
            this.loadfileAnaquel = true;
            axios({
                method: 'post',
                url: '/api/Anaquel/exportLayoutToExcel',
                responseType: 'arraybuffer',
                data: {data: this.tableDataAnaquel}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'AnaquelAtenea.xlsx'
                    link.click()
                    $this.loadfileAnaquel=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfileAnaquel=false;
                }
            );
        },
        ExportFileExhibiciones(){let $this = this;
            this.loadfile = true;
            axios({
                method: 'post',
                url: '/api/Exhibiciones/exportLayoutToExcel',
                responseType: 'arraybuffer',
                data: {data: this.tableDataExhibiciones}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'ExhibicionesAtenea.xlsx'
                    link.click()
                    $this.loadfile=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfile=false;
                }
            );
        },
        ExportFilePromociones(){let $this = this;
            this.loadfilePromociones = true;
            axios({
                method: 'post',
                url: '/api/Promociones/exportLayoutToExcel',
                responseType: 'arraybuffer',
                data: {data: this.tableDataPromociones}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'PromocionesAtenea.xlsx'
                    link.click()
                    $this.loadfilePromociones=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfilePromociones=false;
                }
            );
        },
        ExportFilePrecios(){let $this = this;
            this.loadfilePrecios = true;
            axios({
                method: 'post',
                url: '/api/Precios/exportLayoutToExcel',
                responseType: 'arraybuffer',
                data: {data: this.tableDataPrecios}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'PreciosAtenea.xlsx'
                    link.click()
                    $this.loadfilePrecios=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfilePrecios=false;
                }
            );
        },
    },
    beforeDestroy(){
        console.log("--------------<");
        console.log("--------------<SI");
    },
}

</script>

<style scoped>

</style>
