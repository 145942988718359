<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-md-3">
                <filters-general ref="filtersReport"></filters-general>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-center">
                        <button class="btn ripple btn-primary" @click="getReport">Generar Reporte </button>
                    </div>
                    <div class="col-md-4" v-if="showResult">
                        <div v-if="loadfile">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando archivo, espere...</p>
                        </div>
                        <button v-if="!loadfile"  class="btn btn-success" type="button" @click="ExportFile"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-md-12">
                            <div v-if="loadingGetData" class="text-center">
                                <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                <p>Preparando información, espere...</p>
                            </div>
                        </div>
                        <v-client-table v-show="showData" :data="tableDataExhibiciones" :columns="columnsExhibiciones" :options="optionsExhibiciones" class="table-responsive" ref="tablePromociones">
                        </v-client-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "tablaReporteComentarios",
    data(){
        return{
            loadfile:false,
            showResult:false,
            loadingGetData: false,
            showData: false,
            columnsExhibiciones: ['Fecha','Semana','Area','Agencia','Rol','Usuario','Estado','Ciudad','Canal','Cadena','Formato','Tienda','Kcmid','Comentario'],
            tableDataExhibiciones: [],
            optionsExhibiciones: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Tienda','Area','Formato','Canal','Cadena','Kcmid'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                    Exhibicion: 'Tipo Exhibición',
                    Cnt: 'Cantidad',
                    Kcmid: 'KCMID',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Cnt: 'text-center',
                    Charolas: 'text-center',
                    Valor: 'text-center',
                    Cantidad: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    mounted() {
    },
    methods:{
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        async getReport(){
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else if (Date.parse(filters.timeEnd) < Date.parse(filters.timeInit)){
                this.$toastMessage('error',"Seleccione una fecha fin mayor a la fecha inicial!",4000,'center');
            }else{
                this.loadingGetData = true;
                this.showData = false;
                this.showResult = false;
                const response = await this.$peticionesApi('/api/Comentarios/Tabla', filters);
                if (response!= null){
                    this.loadingGetData = false;
                    this.showData = true;
                    this.tableDataExhibiciones = response.Data;
                    this.showResult = true;
                }
            }
        },
        async ExportFile(){let $this = this;
            this.loadfile = true;
            await this.$peticionesApiFilesDownload('/api/Comentarios/exportLayoutToExcel','ComentariosAtenea.xlsx',this.tableDataExhibiciones);
            this.loadfile = false;
        },
    }
}
</script>

<style scoped>

</style>
