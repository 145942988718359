<template>
    <div>
        <label>Fecha de inicio:</label><br>
        <date-picker v-model="filters.timeInit" valueType="'YYYY-MM-DD'"></date-picker>
        <br><label>Fecha de termino:</label><br>
        <date-picker v-model="filters.timeEnd" valueType="'YYYY-MM-DD'"></date-picker><br>
        <div v-show="shows.trades">
        <label><b>Trades:</b></label>
        <select-tradesN placeholderm="Trade" v-on:cambioTrade="changeTrade" ref="selectTrade"></select-tradesN>
        </div>
        <div v-show="shows.tpromocion">
            <label><b>Tipo Promocion:</b></label>
            <select-promocionN placeholderm="Tipo Promoción" v-on:cambioPromocion="changeTipoPromocion" ref="selectTipoPromocion"></select-promocionN>
        </div>
        <div v-show="shows.texhibicion">
            <label><b>Tipo Exhibición:</b></label>
            <select-texhibicionN placeholderm="Tipo Exhibición" v-on:cambioExhibiciones="changeTipoExhibicion" ref="selectTipoExhibicion"></select-texhibicionN>
        </div>
        <label><b>Agencias:</b></label>
        <select-agenciasN placeholderm="Agencia" v-on:cambioAgencia="changeAgencia" ref="selectAgencia"></select-agenciasN>
        <label><b>Áreas:</b></label>
        <select-areasN placeholderm="Área" v-on:cambioArea="changeArea" ref="selectAreas"></select-areasN>
        <div v-show="shows.estados">
        <label><b>Estados:</b></label>
        <select-estadosN placeholderm="Estado" v-on:cambioEstado="changeEstado" ref="selectEstados"></select-estadosN>
        </div>
        <div v-show="shows.ciudades">
        <label><b>Ciudades:</b></label>
        <select-ciudadesN placeholderm="Ciudad" v-on:cambioCiudad="changeCiudad" ref="selectCiudad"></select-ciudadesN>
        </div>
        <div v-show="shows.distritos">
            <label><b>Distrito:</b></label>
            <select-distritosN placeholderm="Distritos" v-on:cambioDistrito="changeDistrito" ref="selectDistrito"></select-distritosN>
        </div>
        <div v-show="shows.canales">
        <label><b>Canales:</b></label>
        <select-canalesNM placeholderm="Canal" v-on:cambioCanal="changeCanal" ref="selectCanal"></select-canalesNM>
        </div>
        <div v-show="shows.cadenas">
        <label><b>Cadenas:</b></label>
        <select-cadenasN placeholderm="Cadena" v-on:cambioCadena="changeCadena" ref="selectCadena"></select-cadenasN>
        </div>
        <div v-show="shows.formatos">
        <label><b>Formatos:</b></label>
        <select-formatosN placeholderm="Formato" v-on:cambioFormato="changeFormato" ref="selectFormato"></select-formatosN>
        </div>
        <div v-show="shows.tiendas">
        <label><b>Tiendas:</b></label>
        <select-tiendasN placeholderm="Tienda" v-on:cambioTienda="changeTienda" ref="selectTienda"></select-tiendasN>
        </div>
        <div v-show="shows.departamentos">
        <label><b>Departamentos:</b></label>
        <select-departamentosN placeholderm="Departamento" v-on:cambioDepartamento="changeDepartamento" ref="selectDepartamento"></select-departamentosN>
        </div>
        <div v-show="shows.categorias">
        <label><b>Categorias:</b></label>
        <select-categoriasN placeholderm="Categoria" v-on:cambioCategoria="changeCategoria" ref="selectCategoria"></select-categoriasN>
        </div>
        <label><b>Promotores:</b></label>
        <select-promotoresN placeholderm="Promotor" v-on:cambioPromotor="changePromotor" ref="selectPromotor"></select-promotoresN>
        <div v-show="shows.fabricantes">
        <label><b>Fabricantes:</b></label>
        <select-fabricantesN placeholderm="Fabricante" v-on:cambioFabricante="changeFabricante" ref="selectFabricante"></select-fabricantesN>
        </div>
        <div v-show="shows.marcas">
        <label><b>Marcas:</b></label>
        <select-marcasN placeholderm="Marca" v-on:cambioMarca="changeMarca" ref="selectMarca"></select-marcasN>
        </div>
        <div v-show="shows.productos">
        <label><b>Productos:</b></label>
        <select-productosN placeholderm="Producto" v-on:cambioProducto="changeProducto" ref="selectProducto"></select-productosN>
        </div>
        <br><br><br><br><br><br><br><br>
    </div>
</template>

<script>
export default {
    name: "filtrosGeneral",
    data(){
        return{
            shows:{
                departamentos: true,
                categorias: true,
                fabricantes: true,
                marcas: true,
                productos: true,
                trades: true,
                texhibicion: false,
                tpromocion: false,
                estados: true,
                ciudades: true,
                canales: true,
                cadenas: true,
                formatos: true,
                tiendas: true,
                distritos: true,
            },
            filters:{
                timeInit: null,
                timeEnd: null,
                agenciasReal:null,
                areasReal:null,
                estadosReal:null,
                ciudadesReal:null,
                canalesReal:null,
                cadenasReal:null,
                formatosReal:null,
                tiendasReal:null,
                gestores:null,
                trades:null,
                departamentosReal:null,
                categoriasReal:null,
                fabricantesReal:null,
                marcasReal:null,
                productosReal:null,
                estatusTienda:null,
                exhibicionesReal:null,
                promocionesReal:null,
                distritosReal:null,
            }
        }
    },
    mounted() {
    },
    methods:{
        changeAgencia(){
            var agencia = this.$refs.selectAgencia.selectedAgencias;
            var selectTodas = false;
            if (agencia.length>0){
                agencia.forEach(function (value,key){
                    if (agencia[key].value === 0){
                        selectTodas = true;
                    }
                });
                var area = this.$refs.selectAreas;
                if (selectTodas){
                    area.agencia = this.$refs.selectAgencia.optionsAgencias;
                    this.filters.agenciasReal = this.$refs.selectAgencia.optionsAgencias;
                    area.getAreas();
                }
                else{
                    area.agencia = agencia;
                    this.filters.agenciasReal = agencia;
                    area.getAreas();
                }
            }
            else{
                this.filters.agenciasReal = null;
            }
        },
        changeArea(){
            var area = this.$refs.selectAreas.selectedAreas;
            var selectTodas = false;
            if (area.length>0){
                area.forEach(function (value,key){
                    if (area[key].value === 0){
                        selectTodas = true;
                    }
                });
                var estado = this.$refs.selectEstados;
                if (selectTodas){
                    estado.areas = this.$refs.selectAreas.optionsAreas;
                    this.filters.areasReal = this.$refs.selectAreas.optionsAreas;
                    estado.getEstados();
                }
                else{
                    estado.areas = area;
                    this.filters.areasReal = area;
                    estado.getEstados();
                }
            }
            else{
                this.filters.areasReal = null;
            }
        },
        changeEstado(){
            var estados = this.$refs.selectEstados.selectedEstados;
            var selectTodas = false;
            if (estados.length>0){
                estados.forEach(function (value,key){
                    if (estados[key].value === 0){
                        selectTodas = true;
                    }
                });
                var ciudades = this.$refs.selectCiudad;
                if (selectTodas){
                    ciudades.estados = this.$refs.selectEstados.optionsEstados;
                    this.filters.estadosReal = this.$refs.selectEstados.optionsEstados;
                    ciudades.getCiudades();
                }
                else{
                    ciudades.estados = estados;
                    this.filters.estadosReal = estados;
                    ciudades.getCiudades();
                }
            }
            else{
                this.filters.estadosReal = null;
            }
        },
        changeCiudad(){
            var ciudades = this.$refs.selectCiudad.selectedCiudad;
            var selectTodas = false;
            if (ciudades.length>0){
                ciudades.forEach(function (value,key){
                    if (ciudades[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.ciudadesReal = this.$refs.selectCiudad.optionsCiudades;
                }
                else{
                    this.filters.ciudadesReal = ciudades;
                }
            }
            else{
                this.filters.ciudadesReal = null;
            }
        },
        changeDistrito(){
            var distritos = this.$refs.selectDistrito.selectedCiudad;
            var selectTodas = false;
            if (distritos.length>0){
                distritos.forEach(function (value,key){
                    if (distritos[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.distritosReal = this.$refs.selectDistrito.optionsCiudades;
                }
                else{
                    this.filters.distritosReal = distritos;
                }
            }
            else{
                this.filters.ciudadesReal = null;
            }
        },
        changeCanal(){
            var canal = this.$refs.selectCanal.selectedCanales;
            var selectTodas = false;
            if (canal.length>0){
                canal.forEach(function (value,key){
                    if (canal[key].value === 0){
                        selectTodas = true;
                    }
                });
                var cadena = this.$refs.selectCadena;
                if (selectTodas){
                    cadena.canal = this.$refs.selectCanal.optionsCanales;
                    this.filters.canalesReal = this.$refs.selectCanal.optionsCanales;
                    cadena.getCadenas();
                }
                else{
                    cadena.canal = canal;
                    this.filters.canalesReal = canal;
                    cadena.getCadenas();
                }
            }
            else{
                this.filters.canalesReal = null;
            }
        },
        changeCadena(){
            var cadenas = this.$refs.selectCadena.selectedCadenas;
            var selectTodas = false;
            if (cadenas.length>0){
                cadenas.forEach(function (value,key){
                    if (cadenas[key].value === 0){
                        selectTodas = true;
                    }
                });
                var formato = this.$refs.selectFormato;
                if (selectTodas){
                    formato.cadenas = this.$refs.selectCadena.optionsCadenas;
                    this.filters.cadenasReal = this.$refs.selectCadena.optionsCadenas;
                    formato.getDataFormatos();
                }
                else{
                    formato.cadenas = cadenas;
                    this.filters.cadenasReal = cadenas;
                    formato.getDataFormatos();
                }
            }
            else{
                this.filters.cadenasReal = null;
            }
        },
        changeFormato(){
            var formatos = this.$refs.selectFormato.selectedFormatos;
            var selectTodas = false;
            if (formatos.length>0){
                formatos.forEach(function (value,key){
                    if (formatos[key].value === 0){
                        selectTodas = true;
                    }
                });
                var tienda = this.$refs.selectTienda;
                if (selectTodas){
                    tienda.formatos = this.$refs.selectFormato.optionsFormatos;
                    this.filters.formatosReal = this.$refs.selectFormato.optionsFormatos;
                    tienda.getTiendas();
                }
                else{
                    tienda.formatos = formatos;
                    this.filters.formatosReal = formatos;
                    tienda.getTiendas();
                }
            }
            else{
                this.filters.formatosReal = null;
            }
        },
        changeTienda(){
            var tiendas = this.$refs.selectTienda.selectedTiendas;
            var selectTodas = false;
            if (tiendas.length>0){
                tiendas.forEach(function (value,key){
                    if (tiendas[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.tiendasReal = this.$refs.selectTienda.optionsTiendas;
                }
                else{
                    this.filters.tiendasReal = tiendas;
                }
            }
            else{
                this.filters.tiendasReal = null;
            }
        },
        changeTrade(){
            var trades = this.$refs.selectTrade.selectedTrades;
            var selectTodas = false;
            if (trades.length>0){
                trades.forEach(function (value,key){
                    if (trades[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.trades = this.$refs.selectTrade.optionsTrades;
                }
                else{
                    this.filters.trades = trades;
                }
            }
            else{
                this.filters.trades = null;
            }
        },
        changeTipoExhibicion(){
            var texhibicion = this.$refs.selectTipoExhibicion.selectedExhibicioness;
            var selectTodas = false;
            if (texhibicion.length>0){
                texhibicion.forEach(function (value,key){
                    if (texhibicion[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.exhibicionesReal = this.$refs.selectTipoExhibicion.optionsExhibiciones;
                }
                else{
                    this.filters.exhibicionesReal = texhibicion;
                }
            }
            else{
                this.filters.exhibicionesReal = null;
            }
        },
        changeTipoPromocion(){
            var tpromocion = this.$refs.selectTipoPromocion.selectedPromocion;
            var selectTodas = false;
            if (tpromocion.length>0){
                tpromocion.forEach(function (value,key){
                    if (tpromocion[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.promocionesReal = this.$refs.selectTipoPromocion.optionsPromocion;
                }
                else{
                    this.filters.promocionesReal = tpromocion;
                }
            }
            else{
                this.filters.promocionesReal = null;
            }
        },
        changeDepartamento(){
            var departamentos = this.$refs.selectDepartamento.selectedDepartamentos;
            var selectTodas = false;
            if (departamentos.length>0){
                departamentos.forEach(function (value,key){
                    if (departamentos[key].value === 0){
                        selectTodas = true;
                    }
                });
                var categorias = this.$refs.selectCategoria;
                if (selectTodas){
                    categorias.departamentos = this.$refs.selectDepartamento.optionsDepartamentos;
                    this.filters.departamentosReal = this.$refs.selectDepartamento.optionsDepartamentos;
                    categorias.getCategorias();
                }
                else{
                    categorias.departamentos = departamentos;
                    this.filters.departamentosReal = departamentos;
                    categorias.getCategorias();
                }
            }
            else{
                this.filters.departamentosReal = null;
            }
        },
        changeCategoria(){
            var categoria = this.$refs.selectCategoria.selectedCategorias;
            var selectTodas = false;
            if (categoria.length>0){
                categoria.forEach(function (value,key){
                    if (categoria[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.categoriasReal = this.$refs.selectCategoria.optionsCategorias;
                }
                else{
                    this.filters.categoriasReal = categoria;
                }
            }
            else{
                this.filters.categoriasReal = null;
            }
        },
        changePromotor(){
            var promotor = this.$refs.selectPromotor.selectedGestores;
            var selectTodas = false;
            if (promotor.length>0){
                promotor.forEach(function (value,key){
                    if (promotor[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.gestores = this.$refs.selectPromotor.optionsGestores;
                }
                else{
                    this.filters.gestores = promotor;
                }
            }
            else{
                this.filters.gestores = null;
            }
        },
        changeFabricante(){
            var fabricante = this.$refs.selectFabricante.selectedFabricantes;
            var selectTodas = false;
            if (fabricante.length>0){
                fabricante.forEach(function (value,key){
                    if (fabricante[key].value === 0){
                        selectTodas = true;
                    }
                });
                var marcas = this.$refs.selectMarca;
                if (selectTodas){
                    marcas.fabricantes = this.$refs.selectFabricante.optionsFabricantes;
                    this.filters.fabricantesReal = this.$refs.selectFabricante.optionsFabricantes;
                    marcas.getMarcas();
                }
                else{
                    marcas.fabricantes = fabricante;
                    this.filters.fabricantesReal = fabricante;
                    marcas.getMarcas();
                }
            }
            else{
                this.filters.fabricantesReal = null;
            }
        },
        changeMarca(){
            var marcas = this.$refs.selectMarca.selectedMarcas;
            var selectTodas = false;
            if (marcas.length>0){
                marcas.forEach(function (value,key){
                    if (marcas[key].value === 0){
                        selectTodas = true;
                    }
                });
                var productos = this.$refs.selectProducto;
                if (selectTodas){
                    productos.marcas = this.$refs.selectMarca.optionsMarcas;
                    this.filters.marcasReal = this.$refs.selectMarca.optionsMarcas;
                    productos.getProductos();
                }
                else{
                    productos.marcas = marcas;
                    this.filters.marcasReal = marcas;
                    productos.getProductos();
                }
            }
            else{
                this.filters.marcasReal = null;
            }
        },
        changeProducto(){
            var productos = this.$refs.selectProducto.selectedProductos;
            var selectTodas = false;
            if (productos.length>0){
                productos.forEach(function (value,key){
                    if (productos[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.productosReal = this.$refs.selectProducto.optionsProductos;
                }
                else{
                    this.filters.productosReal = productos;
                }
            }
            else{
                this.filters.productosReal = null;
            }
        },
    }
}
</script>

<style scoped>
.mx-datepicker{
    width: 120px!important;
}
</style>
