import { render, staticRenderFns } from "./evaluacion.vue?vue&type=template&id=17367012&scoped=true&"
import script from "./evaluacion.vue?vue&type=script&lang=js&"
export * from "./evaluacion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17367012",
  null
  
)

export default component.exports