<template>
    <div>
        <vue-tabs active-tab-color="#c3a900"
                  active-text-color="white"
                  type="pills"
                  :start-index="1">

            <v-tab title="TIENDAS">
                <br>
                <tiendas-component></tiendas-component>
            </v-tab>

            <v-tab title="FORMATOS - CADENAS">
                <br>
                <cancadfmt-component></cancadfmt-component>
            </v-tab>

            <v-tab title="ESTADOS - CIUDADES">
                <br>
                <ciudades-component></ciudades-component>
            </v-tab>
            <v-tab title="IMPORTAR LAYOUT HERMES">
                <br>
                <layouthermes-component></layouthermes-component>
            </v-tab>
            <v-tab title="IMPORTAR SEGMENTACIÓN - SO">
                <br>
                <layoutsegso-component></layoutsegso-component>
            </v-tab>
        </vue-tabs>
    </div>
</template>

<script>
export default {
    name: "admtiendas"
}
</script>

<style scoped>

</style>
