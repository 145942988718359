<template>
    <div class="container mt-4">
        <h1 class="text-center mb-4">Descargar Ficheros</h1>

        <!-- Para cada año -->
        <div v-for="anioData in listaAniosMeses" :key="anioData.anio" class="mb-5">
            <!-- Título del Año -->
            <h2 class="text-center mb-4">Año: {{ anioData.anio }}</h2>

            <!-- Mostrar los meses como tarjetas -->
            <div class="row">
                <div
                    v-for="mesData in anioData.meses"
                    :key="mesData.mes"
                    class="col-md-4 mb-4 d-flex align-items-stretch"
                >
                    <!-- Card para cada mes -->
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h5 class="card-title text-capitalize">{{ mesData.mes }}</h5>
                            <p class="card-text">
                                Archivo: <strong>{{ mesData.fichero || "No disponible" }}</strong>
                            </p>
                            <!-- Botón de descarga (solo aparece si fichero está definido) -->
                            <a
                                v-if="mesData.fichero"
                                :href="'#'"
                                class="btn btn-primary btn-sm"
                                @click.prevent="descargar(mesData.fichero)"
                            >
                                Descargar {{ mesData.fichero }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "descargarFicheros",
    data() {
        return {
            // Array de archivos definidos manualmente
            archivosPersonalizados: [
                { anio: 2024, mes: "agosto", fichero: "agosto2024.csv" },
                { anio: 2024, mes: "diciembre", fichero: "diciembre2024.csv" },
                { anio: 2024, mes: "noviembre", fichero: "noviembre2024.csv" },
                { anio: 2024, mes: "octubre", fichero: "octubre2024.csv" },
                { anio: 2024, mes: "septiembre", fichero: "septiembre2024.csv" },
                // Puedes agregar más archivos personalizados aquí
            ],
            listaAniosMeses: [] // Lista de años con meses que se generará dinámicamente
        };
    },
    mounted() {
        // Generar la lista al cargar el componente
        this.generarListaAniosMeses();
    },
    methods: {
        // Verificar si hay un archivo personalizado para un mes y año específicos
        buscarArchivoPersonalizado(anio, mes) {
            const archivo = this.archivosPersonalizados.find(
                (item) => item.anio === anio && item.mes.toLowerCase() === mes.toLowerCase()
            );
            return archivo ? archivo.fichero : null;
        },

        // Generar la lista dinámica de años, meses y archivos
        generarListaAniosMeses() {
            const meses = [
                "enero",
                "febrero",
                "marzo",
                "abril",
                "mayo",
                "junio",
                "julio",
                "agosto",
                "septiembre",
                "octubre",
                "noviembre",
                "diciembre"
            ];

            const aniosFinales = [];
            const anioActual = new Date().getFullYear(); // Año actual dinámico

            for (let anio = anioActual; anio >= 2018; anio--) {
                // Lista de meses para un año
                const listaMeses = meses.map((mes) => {
                    // Buscar archivo personalizado o generar uno automáticamente
                    const archivoPersonalizado = this.buscarArchivoPersonalizado(anio, mes);
                    const fichero = archivoPersonalizado || null; // Si no hay archivo, establecer como null

                    return { mes, fichero };
                });

                aniosFinales.push({
                    anio,
                    meses: listaMeses
                });
            }

            this.listaAniosMeses = aniosFinales;
        },

        // Simular la descarga del archivo
        descargar(fichero) {
            //alert(`Descargando fichero ${fichero}`);
            this.$peticionesApiFilesDownload('/api/Respaldos/DescargaFicheros', `${fichero}`, fichero)
        }
    }
};
</script>

<style scoped>
.container {
    max-width: 1200px;
}
.card {
    border-radius: 8px;
}
</style>
