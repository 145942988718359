<template>
    <div class="row">
        <div class="col-12">
            <h5>{{title}}</h5>
            <div class="text-center">
                <div v-if="loadData">
                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                    <p>Preparando reporte, espere...</p>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">

                    <v-select :multiple="false" :options="optionsSemanas" placeholder="Selecciona la semana"
                              v-model="selectedSemana" @input="getDataEnct"></v-select>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-6" v-if="title === 'REPORTE GENERAL' || title === 'REPORTE GENERAL FILTRO'">
                    <div class="card custom-card">
                        <div class="card-body dash1">
                            <div class="d-flex">
                                <p class="mb-1 tx-inverse">ENCUESTAS ACTIVAS</p>
                                <div class="ml-auto">
                                    <i class="fas fa-book-reader fs-20 text-success"></i>
                                </div>
                            </div>
                            <div>
                                <h3 class="dash-25">{{ enct }}</h3>
                            </div>
                            <div class="progress mb-1">
                                <div aria-valuemax="100" aria-valuemin="0" :aria-valuenow="Math.ceil(avance/5)*5" class="progress-bar progress-bar-xs bg-success progress-bar-animated" :class="'wd-'+Math.ceil(avance/5)*5+'p'" role="progressbar"></div>
                            </div>
                            <div class="expansion-label d-flex">
                                <span class="text-muted">Avance Respuestas</span>
                                <span class="ml-auto"><i class="fas fa-caret-up mr-1 text-success"></i>{{ avance }}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6" v-else></div>
                <div class="col-6">
                    <div class="card custom-card">
                        <div class="card-body dash1">
                            <div class="d-flex">
                                <p class="mb-1 tx-inverse">UNIVERSO</p>
                                <div class="ml-auto">
                                    <i class="fas fa-store-alt fs-20 text-primary"></i>
                                </div>
                            </div>
                            <div>
                                <h3 class="dash-25">{{ tiendass }}</h3>
                            </div>
                            <div class="progress mb-1">
                                <div aria-valuemax="100" aria-valuemin="0" :aria-valuenow="Math.ceil(avanceT/5)*5" class="progress-bar progress-bar-xs" :class="'wd-'+Math.ceil(avanceT/5)*5+'p'" role="progressbar"></div>
                            </div>
                            <div class="expansion-label d-flex">
                                <span class="text-muted">NIVEL EJECUCIÓN (SI)</span>
                                <span class="ml-auto"><i class="fas fa-caret-up mr-1 text-success"></i>{{ avanceT }}%</span><br>

                            </div>
                            <div class="expansion-label d-flex" v-if="title !== 'REPORTE GENERAL'">
                                <span class="ml-auto">{{Programadas}}/<span class="tx-bold tx-success">{{tiendass}}</span></span>
                            </div>
                            <div class="expansion-label d-flex" v-if="title !== 'REPORTE GENERAL'">
                                <span class="ml-auto">{{Semanas}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <!--<label>ACTIVIDAD:</label>
                    <select-actividad :placeholderm="'Actividad'" v-on:cambioActividad="getDataEnctGeneral"
                                      ref="actividadSelected" :sinactividad="1"></select-actividad>-->
                    <label>MEDICIÓN:</label>
                    <select-seguimiento :placeholderm="'TIPO MEDICIÓN'" :todaOpciones="false" :disablE="false"
                        ref="tipoSeguimientoSelect" v-on:cambioTipoEncuestaSeguimiento="getDataEnctGeneral"></select-seguimiento>
                    <label>ENCUESTA:</label>
                    <select-encuesta :showExtras="true" :placeholderm="'Encuesta'" v-on:cambioEncuesta="getDataEnct"
                                     ref="enctSelected" :disabledr="dsSelect" v-on:cambioEncuestaFiltros="getDataEnctTipo"
                                     v-on:cambioEncuestaFiltrosInit="initLoading" :actividadlabel="actividadLabel"></select-encuesta>
                    <br><br>
                    <div class="row" v-if="title === 'REPORTE ENCUESTA'">
                        <div class="col-md-8">
                            <div class="card custom-card">
                                <div class="card-body text-center">
                                    <div class="user-lock text-center">
                                        <img alt="SIN IMAGEN DE PERFIL" class="rounded-circle" :src="ImagenUser" v-if="ImagenUser !== ''">
                                        <img alt="SIN IMAGEN DE PERFIL" class="rounded-circle" src="/theme/img/pngs/1.png" v-else>
                                    </div>
                                    <h5 class="mb-1 mt-3 ">{{ Creador }}</h5>
                                    <p class="mb-1"><i class="fe fe-airplay mr-2"></i> {{Programadas}}/<span class="tx-bold tx-success">{{tiendass}}</span> </p>
                                    <p class="mb-1"><i class="fe fe-book-open mr-2"></i> Tipo Pregunta: <b>{{TipoPregunta}}</b> </p>
                                    <p class="mb-1"><i class="fe fe-codepen mr-2"></i> Categoria: <b>{{Categoria}}</b> </p>
                                    <div class="d-lg-flex mt-2 align-items-center justify-content-center text-center">
                                        <p class="mb-2 mr-3"><i class="fe fe-calendar mr-2"></i>{{ Inicio }}</p>
                                        <p class="mb-2"><i class="fe fe-calendar mr-2"></i>{{Fin}}</p>
                                    </div>
                                    <div class="justify-content-center text-center mt-3 d-flex">
                                        <a href="#" class="btn ripple btn-info btn-icon mr-3" @click="CambiarPagina()">
                                            <i class="fas fa-cogs"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <img alt="SIN IMAGEN DE ENCUESTA" class="img-fluid img-thumbnail" :src="Imagen" v-if="Imagen !== ''">
                            <img alt="SIN IMAGEN DE ENCUESTA" class="img-fluid img-thumbnail" src="/theme/img/pngs/1.png" v-else>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card custom-card">
                <div class="card-body dash1">
                    <div class="d-flex">
                        <p class="mb-1 tx-inverse">CAUSAS DE NO EJECUCIÓN</p>
                        <div class="ml-auto">
                            <i class="fas fa-store-alt fs-20 text-primary"></i>
                        </div>
                    </div>
                    <highcharts :options="chartOptions"></highcharts>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from "highcharts";
import Helper from "../../../../../public/theme/plugins/jquery-ui/demos/resizable/helper.html";
import selectActividad from "../../../components/filters/actividad"
import selectSeguimiento from "../../../components/filters/tipoEncuestaSeguimiento"
function createChartRespuestas(vm){
    return{
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{point.nombre}: <b>{point.y:.2f}</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.2f} % <br><b>{point.y}</b>',
                    style: {
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                    }
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        series: [{
            name: 'CAUSAS DE NO EJECUCIÓN',
            data: []
        }]
    }
}
export default {
    name: "general",
    components: {
        Helper,
        "select-actividad": selectActividad,
        'select-seguimiento': selectSeguimiento,
    },
    data(){
        return{
            title:'REPORTE GENERAL',
            actividadLabel:'',
            chartOptions: createChartRespuestas(this),
            tiendass:0,
            enct:0,
            avance:0,
            avanceT:0,
            loadData:false,
            Imagen:'',
            ImagenUser:'',
            Creador:'',
            Semanas:'',
            Programadas:1,
            TipoPregunta:'',
            Categoria:'',
            Inicio:1,
            Fin:1,
            cve: 0,
            dsSelect:false,
            Pregunta:'',
            selectedSemana:{label:"GENERALES",value:null,ini:null,selected:false},
            optionsSemanas:[],
            EncuestasActividades:[],
        }
    },
    mounted() {
        //this.getDataEnct();
        this.getWeeksEight();
    },
    methods:{
        CambiarPagina(){
            this.cve = this.$refs.enctSelected.selectedEncuestas;
            this.$emit('cambioPagina');
        },
        async getWeeks(cve){ let $this = this;
            await axios.post('/api/AdminCatalogos/dataEncuestasRegionGetWeek',{cve:cve,semanaFin:this.selectedSemana})
                .then(r => {
                        $this.loadingGetData = false;
                        if (r.data.Success){
                            $this.optionsSemanas = r.data.Semanas;
                            $this.chartOptions.series[0].data = r.data.Negativas;
                            $this.optionsSemanas.forEach((value,key)=>{
                                if (key === 3){
                                    $this.selectedSemana={label:value.label,value:value.value,ini:value.ini,selected:true}
                                }
                            });
                        }
                    },
                    error => {
                        $this.$toastMessage('error', 'Error: ' + error + ' Por favor envia este error al área de soporte!', 9000, 'center');
                        $this.loadingGetData = false;
                    }
                );
        },
        async getWeeksEight(){ let $this = this;
            await axios.post('/api/AdminCatalogos/dataEncuestasRegionGetWeekEight')
                .then(r => {
                        $this.loadingGetData = false; //console.log(r.data)
                        if (r.data.Success){
                            $this.optionsSemanas = r.data.Semanas;
                            $this.chartOptions.series[0].data = r.data.Negativas;
                            $this.optionsSemanas.forEach((value,key)=>{
                                //console.log(key)
                                //console.log(value)
                                if (key === 3){
                                    $this.selectedSemana={label:value.label,value:value.value,ini:value.ini,selected:true}
                                    console.log($this.selectedSemana)
                                }
                            });
                            $this.optionsSemanas.shift();
                            $this.getDataEnct();
                        }
                    },
                    error => {
                        $this.$toastMessage('error', 'Error: ' + error + ' Por favor envia este error al área de soporte!', 9000, 'center');
                        $this.loadingGetData = false;
                    }
                );
        },
        async getDataEnct(){let $this = this;
            this.loadData = true;
            var enct = this.$refs.enctSelected.selectedEncuestas;
            let seguimiento = this.$refs.tipoSeguimientoSelect.selectedTipoSeguimiento;
            if (enct!==null){
                await this.getWeeks(enct);
            }
            this.data = [];
            this.dsSelect = true;
            $this.chartOptions.series[0].data = [];
            if (enct !== null){
                this. title = 'REPORTE ENCUESTA';
                axios.post('/api/AdminCatalogos/dataEncuestas',{cve: enct,semanaFin:this.selectedSemana})
                    .then(r => {
                            $this.loadingGetData = false;
                            if (r.data.Success){
                                $this.data = r.data.Data;
                                $this.loadData = false;
                                $this.tiendass = r.data.Data[0].Tiendas;
                                $this.avanceT = r.data.Data[0].Avance;
                                $this.Creador = r.data.Data[0].Creador;
                                $this.Inicio = r.data.Data[0].Inicio;
                                $this.Fin = r.data.Data[0].Fin;
                                $this.Imagen = r.data.Data[0].Imagen;
                                $this.ImagenUser = r.data.Data[0].ImgUser;
                                $this.TipoPregunta = r.data.Data[0].Tipo;
                                $this.Categoria = r.data.Data[0].Categoria;
                                $this.Programadas = r.data.Data[0].Ejecucion;
                                $this.Pregunta = r.data.Data[0].Pregunta;
                                $this.Semanas = r.data.Data[0].Semanas;
                                $this.enct = 1;
                                $this.chartOptions.series[0].data = r.data.Data[0].Negativas;
                                $this.dsSelect = false;
                            }
                        },
                        error => {
                            $this.$toastMessage('error', 'Error: ' + error + ' Por favor envia este error al área de soporte!', 9000, 'center');
                            $this.loadData = false;
                        }
                    );
            }
            else{
                this. title = 'REPORTE GENERAL';
                axios.post('/api/AdminCatalogos/dataEncuestasGeneral',{
                    encuestas: [],seguimiento:seguimiento.value, fechas: $this.selectedSemana
                })
                    .then(r => {
                            $this.loadData = false;
                            if (r.data.Success){
                                $this.tiendass = r.data.Tiendas;
                                $this.enct = r.data.Encuestas;
                                $this.avance = r.data.Avance;
                                $this.avanceT = r.data.AvanceTiendas;
                                $this.chartOptions.series[0].data = r.data.Negativas;
                                $this.dsSelect = false;
                            }
                        },
                        error => {
                            $this.$toastMessage('error', 'Error: ' + error + ' Por favor envia este error al área de soporte!', 9000, 'center');
                            $this.loadData = false;
                        }
                    );
            }
        },
        async getDataEnctGeneral(){let $this = this;
            this.loadData = true;
            this.actividadLabel = this.$refs.tipoSeguimientoSelect.selectedTipoSeguimiento.label;
            this.$refs.enctSelected.selectedEncuestas = null;
            //var actividad = this.$refs.actividadSelected.selectedActividad;
            let seguimiento = this.$refs.tipoSeguimientoSelect.selectedTipoSeguimiento;
            //let EncuestasActividades = this.$refs.actividadSelected.optionsActividad;
            this.data = [];
            this.dsSelect = true;
            $this.chartOptions.series[0].data = [];
            //this.$refs.enctSelected.actividad = actividad.value;
            this.$refs.enctSelected.seguimiento = seguimiento.value;
            await this.$refs.enctSelected.getEncuestas();
            this. title = 'REPORTE GENERAL';
            axios.post('/api/AdminCatalogos/dataEncuestasGeneral',{
                encuestas: [],seguimiento:seguimiento.value,fechas: $this.selectedSemana
            })
                .then(r => {
                        $this.loadData = false;
                        if (r.data.Success){
                            $this.tiendass = r.data.Tiendas;
                            $this.enct = r.data.Encuestas;
                            $this.avance = r.data.Avance;
                            $this.chartOptions.series[0].data = r.data.Negativas;
                            $this.avanceT = r.data.AvanceTiendas;
                            $this.dsSelect = false;
                        }
                    },
                    error => {
                        $this.$toastMessage('error', 'Error: ' + error + ' Por favor envia este error al área de soporte!', 9000, 'center');
                        $this.loadData = false;
                    }
                );

        },
        initLoading(){
            this.loadData = true;
            this. title = 'REPORTE GENERAL FILTRO';
            this.data = [];
            this.dsSelect = true;
        },
        getDataEnctTipo(){let $this = this;
            this.loadData = true;
            this. title = 'REPORTE GENERAL FILTRO';
            this.data = [];
            this.dsSelect = true;
            $this.chartOptions.series[0].data = [];
            axios.post('/api/AdminCatalogos/dataEncuestasGeneral',{
                encuestas: this.$refs.enctSelected.dataResult,fechas: $this.selectedSemana
            })
                .then(r => {
                        $this.loadData = false;
                        if (r.data.Success){
                            $this.tiendass = r.data.Tiendas;
                            $this.enct = r.data.Encuestas;
                            $this.avance = r.data.Avance;
                            $this.avanceT = r.data.AvanceTiendas;
                            $this.chartOptions.series[0].data = r.data.Negativas;
                            $this.dsSelect = false;
                        }
                    },
                    error => {
                        $this.$toastMessage('error', 'Error: ' + error + ' Por favor envia este error al área de soporte!', 9000, 'center');
                        $this.loadData = false;
                    }
                );
        }
    }
}
</script>

<style scoped>

</style>
